import React from 'react';
import { useSearchStore } from '../hooks/useSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import { zfill } from '../common/catalogUtils';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

/**
 * Returns the hit list for a specific result (generally text) in the search results
 *
 * @param bibid
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function HitList({ bibid }) {
    const search = useSearchStore();
    // TODO: figure out full list of hl_fields that work with query (can't do *)
    const hl_fields = [
        'pg_*',
        'title',
        '*_t',
        '*_txt',
        'chaptitle',
        'agent',
        'colophon',
        'desc',
        'content',
    ];
    const qobj = {
        q: search?.solrQuery?.q,
        fq: `bibid_s:${bibid}`,
        hl: true,
        'hl.fl': hl_fields,
        rows: 30,
    };
    if (search?.hasFilters()) {
        // console.log("has filters");
        const newfq = [`bibid_s:${bibid}`];
        for (const [key, value] of Object.entries(search.filters)) {
            if (value.length > 0) {
                newfq.push(key + ':(' + value.join(' OR ') + ')');
            }
        }
        qobj.fq = newfq;
    }

    const {
        isLoading: isHitsLoading,
        data: hitsearch,
        isError: isHitsError,
        error: hitsError,
    } = useCatSolr(qobj);

    if (isHitsError || isHitsLoading) {
        return null;
    }

    // console.log('hit search results',  hitsearch);

    const hits = hitsearch?.highlighting;

    return (
        <ol className="hitlist">
            {Object.keys(hits).map((okey, oki) => {
                const hit = hits[okey];

                let pg = hitsearch?.docs?.filter((d, di) => {
                    return d?.id === okey;
                });
                pg = pg?.length > 0 ? pg[0] : false;
                const tnum = zfill(pg?.text_num, 4);
                let hitlabel = okey;
                let loc = '';
                let navlink = '';
                // hitlabel includes bib for texts with pages (I think?) TODO: need to check this. Use pg.type instead?
                if (hitlabel?.includes('-bib-')) {
                    let hitpts = okey.split('-bib-');
                    if (hitpts.length > 1) {
                        // console.log("hitlabel", hitlabel);
                        hitlabel = hitpts[1].replace(
                            'title-line',
                            'title line'
                        );
                        hitpts = hitlabel.split('-');
                        hitlabel = hitpts.join('\u2192');
                    }
                }
                let mtch = /v(\d+)p(\d+[ab]?)/.exec(hitlabel);
                if (mtch) {
                    if (hitlabel.includes('-text-v')) {
                        hitlabel = `vol ${mtch[1]}, pg ${mtch[2]}`;
                    }
                    loc = `${mtch[1]}/${mtch[2]}`;
                    navlink = `${pg?.coll}/${pg?.edsig}/${tnum}/pages/${loc}`;
                } else if (pg?.type === 'textsection') {
                    hitlabel = pg.section_title_s;
                    loc = pg?.section_num_s;
                    navlink = `${pg?.coll}/${pg?.edsig}/${tnum}/text/section/${loc}`;
                } else {
                    // const position = okey.replace(`${bibid}-`, '');  // not necessary
                    navlink = `${pg?.coll}/${pg?.edsig}/${tnum}`;
                }

                // console.log("Page: " , loc);

                return (
                    <li key={`${bibid}-hit-${oki}`} data-solrid={okey}>
                        {getHit(hit, pg, search?.query)} &nbsp; (
                        <NavLink to={navlink}>{hitlabel}</NavLink>)
                    </li>
                );
            })}
        </ol>
    );
}

/**
 * Gets the highlighted hit for a search result:
 *
 * // TODO: Generalize for other languages (see const lang = "bo" below)
 * // TODO: get SOLR highlighting to work properly for Tibetan / Unicode (see "else" block below)
 *
 * @param hit
 * @param pg
 * @param qry
 * @returns {JSX.Element}
 */
function getHit(hit, pg, qry) {
    // console.log("hit in get hit", hit);
    const lang = 'bo';
    const pgfield = `pg_${lang}`;
    let hit_text = '';
    qry = qry.replace(/"/g, '');
    if (Object.keys(hit).includes(pgfield)) {
        hit_text = hit[pgfield][0];
    } else if (Array.isArray(hit['content']) && hit['content'].length > 0) {
        hit_text = hit['content'][0];
    } else if (hit?.title?.length > 0) {
        hit_text = hit.title[0];
    } else {
        /**
         * This block is called when the SOLR highlighting field in the results is empty for a page,
         * Because SOLR highlighting does not "find" the query string. Seems to happen randomly, not sure why.
         * The following code uses the "pg_bo" field and manually highlights the search string and trims the
         * return results. Works but kludgy.
         */
        hit_text = pg[pgfield]?.length > 0 ? pg[pgfield][0] : '';
        if (hit_text?.includes(qry)) {
            let hitpts = hit_text.split(qry);
            if (hitpts && hitpts?.length > 0) {
                let stind = hitpts[0].length - 50;
                stind = hitpts[0].lastIndexOf('\u0F0B', stind);
                hitpts[0] = hitpts[0].substring(stind);
                hitpts[1] = hitpts[1].substring(0, 50);
                hit_text =
                    hitpts[0] + `<span class="hl">${qry}</span>` + hitpts[1];
            }
        }
    }
    return <>{ReactHtmlParser(hit_text)}</>; // Parse the HTML to get the highlight tag to render
}
