import { HtmlCustom } from './common/HtmlCustom';
import { Link, useRouteMatch } from 'react-router-dom';
import { useCatSolr } from './hooks/useCatSolr';
import THLSkeleton from './common/THLSkeleton';
import React from 'react';

export function CollInfo({
    doc,
    eds = [],
    showEds = false,
    showHomeLink = false,
}) {
    let collEds = eds;
    let desc = Array.isArray(doc?.description_t)
        ? doc?.description_t[0]
        : doc?.description_t;
    if (typeof desc === 'string') {
        desc = desc.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    }
    const cname = showEds ? 'editions' : 'list';

    if (!doc) {
        console.log('no doc in coll state');
        return null;
    }
    return (
        <div className={`collection ${cname}`}>
            <h2>
                {doc?.title_en} Collection
                {showHomeLink && <HomeLink />}
            </h2>
            <HtmlCustom markup={desc} />
            <CollStats doc={doc} eds={collEds} showEds={showEds} />
            {showEds && collEds && Object.keys(collEds)?.length > 0 && (
                <CollEds eds={collEds} coll={doc} />
            )}
        </div>
    );
}

export function HomeLink() {
    let { path } = useRouteMatch();
    if (path === '/catalog/') {
        return null;
    }
    return (
        <span className="cat-back-home">
            <Link to="/catalog">View All Collections</Link>
        </span>
    );
}

/**
 * Displays the total number of editions, volumes, and texts in a collection
 *
 * @param doc
 * @param eds
 * @returns {JSX.Element|null}
 * @constructor
 */
function CollStats({ doc, eds }) {
    const query = {
        q: `type:tibbibl AND coll:${doc.sigla_s}`,
        fl: '*',
        rows: '1',
        facet: 'on',
        'facet.field': 'subtype',
        'facet.mincount': 1,
    };
    const {
        isLoading: isCollStatsLoading,
        data: collStats,
        isError: isCollStatsError,
        error: collStatsError,
    } = useCatSolr(query);

    if (isCollStatsLoading) {
        return <THLSkeleton />;
    }
    const subtypes = collStats?.facets?.subtype;
    if (!subtypes) {
        return null;
    }
    // Calculate volume range from among all editions
    const edvolcounts = eds
        ?.map((ed, ei) => {
            let totalvols = '-1';
            if (ed?.count_volumes_ss) {
                totalvols = ed.count_volumes_ss.filter((ec, eci) => {
                    return ec?.includes('total|');
                });
                totalvols = totalvols[0].replace('total|', '');
            }
            return totalvols * 1;
        })
        .filter((tv, tvi) => {
            return tv > 0;
        });
    let volctst = 'n/a';
    if (edvolcounts?.length === 1) {
        volctst = edvolcounts[0];
    } else if (edvolcounts?.length > 1) {
        volctst = Math.min(...edvolcounts) + ' to ' + Math.max(...edvolcounts);
    }

    // Calculate text range from among all editions
    const edtxtcounts = eds
        ?.map((ed, ei) => {
            let totaltxts = '-1';
            if (ed?.count_texts_ss) {
                // console.log("text counts", ed?.count_texts_ss);
                totaltxts = ed.count_texts_ss.filter((ec, eci) => {
                    return ec?.includes('total|');
                });
                totaltxts = totaltxts[0]?.replace('total|', '');
            }
            if (totaltxts === '') {
                totaltxts = '-1';
            }
            return totaltxts * 1;
        })
        .filter((tv, tvi) => {
            return tv > 0;
        });
    let txtctst = 'n/a';
    if (edtxtcounts?.length === 1) {
        txtctst = edtxtcounts[0];
    } else if (edtxtcounts?.length > 1) {
        const min_tnum = Math.min(...edtxtcounts);
        const max_tnum = Math.max(...edtxtcounts);
        txtctst = min_tnum;
        if (min_tnum < max_tnum) {
            txtctst += ` to ${max_tnum}`;
        }
    }

    return (
        <>
            <ul className="mt-2">
                {eds && eds?.length > 0 && (
                    <li>
                        <strong>Editions: </strong> {subtypes.edition}{' '}
                        <EdList eds={eds} />{' '}
                        <span className="edsummary">
                            —{' '}
                            <Link to={`/catalog/${eds[0].coll}/eds`}>
                                Summary
                            </Link>
                        </span>
                    </li>
                )}
                <li>
                    <strong>Volumes: </strong> {volctst}
                </li>
                <li>
                    <strong>Texts: </strong> {txtctst}
                </li>
            </ul>
        </>
    );
}

/**
 * Lists the individual editions in a collection with its stats on number of vols and texts
 * @param eds
 * @param coll
 * @returns {JSX.Element}
 * @constructor
 */
function CollEds({ eds, coll }) {
    const query = {
        q: `*:*`,
        fq: ['type:tibbibl', `coll:${coll?.coll}`],
        fl: '*',
        rows: '1',
        facet: 'on',
        'json.facet':
            '{"eds": {"type":"terms", "field":"edsig","limit":-1,"facet":{' +
            '"types":{"type":"terms","field":"subtype","mincount":1,"limit":-1},' +
            '}}}',
    };
    const {
        isLoading: isEdStatsLoading,
        data: edStats,
        isError: isEdStatsError,
        error: edStatsError,
    } = useCatSolr(query);
    if (isEdStatsLoading) {
        return <THLSkeleton />;
    }
    // console.log('ed', ed);
    return (
        <div className="l-coll-edlist">
            {eds.map((eddoc, edindex) => {
                const divid = `${coll.id}-${eddoc.id}-summary`;
                // TODO: Normalize description so it doesn need the field name: discussion_history-of-the-edition_t
                const desc = eddoc['discussion_history-of-the-edition_t'];
                const eddata = edStats?.facetsjson?.eds[eddoc?.edsig];
                return (
                    <div key={divid} id={divid} className="l-coll-ed">
                        <h2>{eddoc.ed} Edition</h2>
                        {desc && <HtmlCustom markup={desc} />}
                        <ul className="ed stats">
                            <li>
                                <label>Volumes</label> {eddata?.volume || 'n/a'}
                            </li>
                            <li>
                                <label>Texts</label> {eddata?.text || '0'}
                            </li>
                        </ul>
                        <p>
                            <Link to={`/catalog/${eddoc?.coll}/${eddoc.edsig}`}>
                                View the {eddoc.ed} edition
                            </Link>
                        </p>
                    </div>
                );
            })}
        </div>
    );
}

function EdList({ eds }) {
    const edlinks = eds.map((ed, edind) => {
        const coll = ed?.coll;
        const edsig = ed?.edsig || 'main';
        const shortTitle = ed?.ed;
        return (
            <li key={`${coll}-${edsig}-edlink-${edind}`} className="edlink">
                <Link to={`/catalog/${coll}/${edsig}`}>{shortTitle}</Link>
            </li>
        );
    });
    return <ul className="edlist">{edlinks}</ul>;
}
