/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { ImStack } from 'react-icons/im';

const CatalogNote = ({ title, content, ...props }) => {
    const target = useRef(null);
    const [show, setShow] = useState(false);
    const placement = props?.placement ? props.placement : 'right';
    const showPop = (event) => {
        setShow(true);
    };
    //content = !content?.startsWith("<p>") ? `<p>${content}</p>` : content;
    const icon = props?.icon ? props.icon : <ImStack />;

    return (
        <>
            {' '}
            <span onMouseEnter={showPop} onMouseLeave={() => setShow(false)}>
                <span ref={target} className="popover-link">
                    {icon}
                </span>
                <Overlay
                    target={target.current}
                    placement={placement}
                    show={show}
                >
                    <Popover className="generic-popover processed">
                        <Popover.Title className="text-capitalize" as="h6">
                            {title}
                        </Popover.Title>
                        <Popover.Content>
                            {content}
                            {/*
              <HtmlCustom markup={content} />
              */}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </span>
        </>
    );
};

export default CatalogNote;
