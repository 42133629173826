import { useBiblStore } from '../../hooks/useBiblStore';
import React, { useEffect, useState } from 'react';
import {
    buildSigla,
    getBiblId,
    makeRanges,
    zfill,
} from '../../common/catalogUtils';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/all';
import { Link } from 'react-router-dom';
import { DoxTocText } from './DoxTocText';
import { useCatSolr } from '../../hooks/useCatSolr';

/**
 * Copied from DoxTocLink, this component groups texts together in an Unclassified subcategory when
 * there are other subcategories so that all subcategories can be seen at once.
 *
 * @param texts
 * @constructor
 */
export function DoxTocTextGroup({ doc, texts }) {
    const bibl = useBiblStore((state) => state.doc);
    const [isOpen, setOpen] = useState(false);
    const bibid = buildSigla(bibl?.coll, bibl?.edsig, '-');
    const tids = texts.map((tnum, ti) => {
        return `${bibid}${tnum}-bib`;
    });

    // console.log("tids", tids);
    const sstr = 'id:(' + tids.join(' ') + ')';

    let docTocChildQuery = {
        q: sstr,
        rows: 300,
        fl: '*, [child limit=1000]',
        sort: 'id asc',
        wt: 'json',
    };
    const {
        isLoading: isDoxTextsLoading,
        data: docTexts,
        isError: isDoxCatError,
        error: doxCatError,
    } = useCatSolr(docTocChildQuery);

    // Update state based on dox doc and current bibl (dox or text) showing
    useEffect(() => {
        //let newState = false; // Keep closed by default. TODO: will have to add code to sniff out when selected text is in list
        // setOpen(newState);
        // console.log("bibl change!", isOpen, doc, bibl);
        const tnum = zfill(bibl.text_num, 4);
        setOpen(texts?.includes(tnum));
    }, [bibl]);

    const toggleme = () => {
        setOpen(!isOpen);
    };

    let icon = isOpen ? (
        <GoTriangleDown className="toggle-icon down" />
    ) : (
        <GoTriangleRight className="toggle-icon right" />
    );
    // console.log('doc in text group', doc);
    const grouplvl =
        typeof doc?.level_i === 'number' ? doc?.level_i * 1 + 1 : 0;

    const numlist = texts.map((tstr, i) => {
        return tstr * 1;
    });
    const numranges = makeRanges(numlist);
    const sigpref = buildSigla(doc?.coll, doc?.edsig);

    return (
        <div id={`${doc?.id}-texts`} className={`c-toc-doxcat lvl-${grouplvl}`}>
            <span className="toc-toggle" onClick={toggleme}>
                {icon}
            </span>
            <Link
                to={`/catalog/${doc.coll}/${doc.edsig}/dox/${doc.id}`}
                className={`toggle-link`}
            >
                <span title="Texts in this group have no subgenre classification">
                    Unassigned ({sigpref}
                    {numranges.join(', ')})
                </span>
            </Link>
            )
            {isOpen && (
                <ul className="textlist ml-0">
                    {docTexts?.docs?.map((tdoc, tn) => {
                        /* need to refigure this
                        if (tnum.match(/[\d-]+:\S+-dox/)) {
                            const [tnums, doxid] = tnum.split(':');
                            const mid = `${doc?.coll}-${doc?.ed}-${tnums}`;
                            const label = tnums.includes('-')
                                ? 'texts'
                                : 'text';
                            const doxlabel = doxid
                                .split('-')
                                .slice(1, -1)
                                .join('.');
                            return (
                                <li id={mid} key={mid}>
                                    <i>
                                        For {label} {tnums}, see section{' '}
                                        {doxlabel} below
                                    </i>
                                </li>
                            );
                        }

                         */
                        return (
                            <DoxTocText
                                tdoc={tdoc}
                                key={`${doc.id}-text-${tn}`}
                            />
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
