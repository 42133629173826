import create from 'zustand';
import axios from 'axios';
import jsonpAdapter from '../common/axios-jsonp';
import {
    getResourceVal,
    inList,
    parseResourceRange,
} from '../common/catalogUtils';

/**
 * Zustand Store that stores the currently loaded bibl or dox record. Loaded in CatalogFrame
 *
 * @type {UseStore<{
 *         removeEdBibl: function(): *,
 *         setDoc: function(*): *,
 *         setEdBibl: function(*): *,
 *         setToc: function(*): *,
 *         edbibl: null,
 *         doc: null,
 *         toc: string,
 *         reset: function(): *,
 *         removeDoc: function(): *
 *       }>}
 */
export const useBiblStore = create((set, get) => ({
    collinfo: null, // summary info of all indexed collections and their editions
    coll: '',
    ed: '',
    text: '',
    doc: null, // the bibl solr doc
    docToc: null,
    dynamicSections: null,
    edBibl: null, // the edition's bibl solr doc
    toc: 'dox', // the type of TOC showing (dox or vol)
    hasDoxToc: true,
    collname: '', // Collection name
    collToc: {},
    selectPath: [],
    getTocDox: (did) => {
        const ctoc = get().collToc;
        if (Object.keys(ctoc).includes(did)) {
            return ctoc[did];
        }
        return false;
    },
    getTocLevel: (lvl) => {
        const ctoc = get().collToc;
        const filtered = Object.keys(ctoc).reduce((filteredToc, key) => {
            if (ctoc[key].level_i === lvl) {
                filteredToc[key] = ctoc[key];
            }
            return filteredToc;
        }, {});
        return filtered;
    },
    getTocItem: (id) => {
        const ctoc = get().collToc;
        if (Object.keys(ctoc).includes(id)) {
            return ctoc[id];
        }
        return false;
    },
    getTocChildren: (id, filter = 'all') => {
        const item = get().getTocItem(id);
        if (item) {
            switch (filter) {
                case 'dox':
                    return item?.children_ss;
                case 'texts':
                    return item?.texts_ss;
                default:
                    return {
                        children: item?.children_ss,
                        texts: item?.texts_ss,
                    };
            }
        }
    },
    setColl: async (csig) => {
        if (get().coll !== csig) {
            const collnm = await getInfo('coll', csig);
            set({ coll: csig, hasDoxToc: true, collname: collnm });
        }
    },
    setCollInfo: (data) => {
        // See CatalogFrame.js where useEffect calls async request for this info from SOLR: getCatalogInfo()
        // console.log('data in set coll info', data);
        if (data?.docs?.length > 0) {
            const newinfo = {
                colls: {},
                collsigs: [],
            };
            // ToDo: Should we calculate titles for colls and eds here?
            data.docs.forEach((bib, bind) => {
                const coll = bib?.coll;
                const ed = bib?.edsig;
                if (coll) {
                    if (!newinfo.collsigs.includes(coll)) {
                        newinfo.collsigs.push(coll);
                        newinfo.colls[coll] = {
                            id: coll,
                            bibl: null,
                            eds: {},
                            edsig: [],
                        };
                    }
                    if (ed) {
                        if (!newinfo.colls[coll].edsig.includes(ed)) {
                            newinfo.colls[coll].edsig.push(ed);
                            newinfo.colls[coll].eds[ed] = {
                                id: ed,
                                bibl: bib,
                            };
                        }
                    } else {
                        // If no ed sigla, it's a collection bibl
                        newinfo.colls[coll].bibl = bib;
                    }
                }
            });
            if (newinfo?.collsigs?.length > 0) {
                // console.log('new coll info', newinfo);
                set({ collinfo: newinfo });
            }
        }
    },
    setCollToc: (data) => {
        // console.log('data', data);
        if (Array.isArray(data)) {
            const tocDict = {};
            data.forEach((rec) => {
                tocDict[rec?.id] = rec;
                tocDict[rec?.id].textbibls = false;
            });

            set({ collToc: tocDict });
        }
    },
    setNoDoxToc: (nodoxtoc) => {
        if (nodoxtoc) {
            set({ toc: 'vol', hasDoxToc: false });
        } else {
            set({ toc: 'dox', hasDoxToc: true });
        }
    },
    addTextsToCollToc: (id, texts) => {
        if (id && Array.isArray(texts)) {
            const ctoc = get().collToc;
            if (Object.keys(ctoc).includes(id)) {
                ctoc[id].textbibls = texts;
            }
            set({ collToc: ctoc });
        }
    },
    setEdBibl: (newedbib) =>
        set((state) => {
            if (get().coll !== newedbib?.coll) {
                set({ coll: newedbib?.coll });
            }
            if (get().ed !== newedbib?.edsig) {
                set({ ed: newedbib?.edsig });
            }
            newedbib.textrange = parseResourceRange(
                newedbib?.resource_digtexts_s
            );
            // console.log("Text range", bibl.textrange);
            newedbib.styledtexts = parseResourceRange(
                newedbib?.resource_digtexts_styled_s
            );
            newedbib.scanrange = parseResourceRange(newedbib?.resource_scans_s);
            newedbib.has_sides = getResourceVal(newedbib, 'has_sides'); // Extract value of has_sides into boolean
            newedbib.pagination_type = getResourceVal(newedbib, 'pagination');
            newedbib.no_vols = newedbib?.pagination_type === 'digpage';
            set({ edBibl: newedbib });
        }),
    removeEdBibl: () => set({ edBibl: null }),
    setDoc: (newdoc) => {
        const edBibl = get().edBibl;
        if (newdoc?.sections?.length > 0) {
            // Add a toc property to the bibl object that has only first level sections in order
            newdoc.toc = newdoc.sections.filter((s) => {
                return s?.level_i === 1;
            });
            newdoc.toc = newdoc.toc.map((s) => {
                return s?.chapid_s;
            });
        }
        if (get().coll !== newdoc?.coll) {
            set({ coll: newdoc?.coll });
        }
        if (get().ed !== newdoc?.edsig) {
            set({ ed: newdoc?.edsig });
        }
        if (newdoc) {
            newdoc.text_num_str = newdoc?.text_num?.toString().padStart(4, '0');
            const tnumstr = newdoc?.text_num_str
                ? `-${newdoc?.text_num_str}`
                : '';
            newdoc.toc_id = `dox-toc-${newdoc?.coll}-${newdoc?.edsig}${tnumstr}-bib`;
            newdoc.hastext = edBibl?.textrange
                ? edBibl?.textrange?.includes(newdoc?.text_num)
                : false;
            if (newdoc.hastext) {
                newdoc.texttype = 'pages';
                if (
                    edBibl?.styledtexts &&
                    (edBibl?.styledtexts === 'all' ||
                        edBibl?.styledtexts?.includes(newdoc?.text_num))
                ) {
                    newdoc.texttype = 'styled';
                }
            }
            newdoc.hasscans = inList(newdoc?.text_num, edBibl?.scanrange);
            set((state) => ({ doc: newdoc }));
        }
    },
    removeDoc: () => set({ doc: null }),
    setDynamicSections: (ds) => {
        let textToc = ds.map((s) => s?.section_title_s);
        // Add a children array
        const newds = ds.map((s) => {
            const thislvl = s.section_level_i * 1;
            const thissid = s.section_num_s;
            const children = ds
                .filter((it) => {
                    return (
                        it.section_num_s.includes(thissid) &&
                        it.section_level_i === thislvl + 1
                    );
                })
                .map((chld) => chld.section_num_s);
            s.children = children;
            return s;
        });
        set({ dynamicSections: newds, docToc: textToc });
    },
    setSelectPath: (paths) => set({ selectPath: paths.map((it) => it?.id) }),
    reset: () => set({ doc: null, edBibl: null, toc: 'dox' }),
    setToc: (newtoc) => set((state) => ({ toc: newtoc })),
}));

/**
 * An async function to query solr for different information
 *
 * @param csig
 * @returns {Promise<void>}
 */
async function getInfo(itype, bibid) {
    let request = {
        url: process.env.REACT_APP_SOLR_URL,
        adapter: jsonpAdapter,
        dataType: 'jsonp',
        callbackParamName: 'json.wrf',
        headers: {
            'Content-Type': null,
        },
    };
    switch (itype) {
        case 'coll':
            request['params'] = {
                q: `coll:${bibid}`,
                fq: ['type:tibbibl', 'subtype:collection'],
                fl: 'title_en',
            };
            break;
        case 'sections':
            request['params'] = {
                q: `bibid_s:${bibid}`,
                fq: ['type:textsection'],
                fl: 'id, section_num_s, section_title_s',
                rows: 1000,
                sort: 'section_num_s asc',
            };
            break;
        case 'chapters':
            request['params'] = {
                q: `bibid_s:${bibid}`,
                fq: ['type:chapter'],
                fl: '*',
                rows: 1000,
                sort: 'chapid_s asc',
            };
            break;
        case 'dynamic':
            request['params'] = {
                q: `bibid_s:${bibid}`,
                fq: ['type:textsection'],
                fl: 'id, section_level_i',
                sort: 'section_num_s asc',
                rows: 1000,
            };
    }
    const { data } = await axios.request(request).catch(function (e) {
        console.error('error in call collection name', e);
    });
    // console.log("data returned", data);
    try {
        switch (itype) {
            case 'coll':
                return data?.response?.docs[0]?.title_en[0];
            case 'sections':
                return data?.response?.docs;
        }
    } catch (e) {
        console.log(`error in call: ${itype} : ${bibid}`);
    }
    return false;
}
