import React from 'react';
import { WpPageSearch } from './WpPageSearch';

export function WpPageSearchTest(props) {
    return (
        <div className="sicon-ref-page">
            <h1>Testing WP Search Form</h1>
            <div className="sicon-ref-list">
                <p>Test the WP Search form:</p>
            </div>
            <WpPageSearch />
        </div>
    );
}
