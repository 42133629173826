import { useBiblStore } from '../hooks/useBiblStore';
import { useSearchStore } from '../hooks/useSearchStore';
import React, { useEffect, useState } from 'react';
import { DynamicTextToc } from '../reader/DynamicTextToc';
import TextToc from '../reader/TextToc';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/all';
import { CatToc } from './CatToc';
import { Tab, Tabs } from 'react-bootstrap';
import { NavSearch } from '../search/NavSearch';
import { NavBasicSearch } from '../search/NavBasicSearch';
import CatalogSettings from "../common/CatalogSettings";

export default function TablesOfContents(props) {
    const docToc = useBiblStore((state) => state.docToc);
    const [tocTab, setTocTab] = useState('cattoc'); // either cattoc or catsearch if developing that.

    return (
        <Tabs
            id="cattoc-tab"
            className="mb-3"
            defaultActiveKey={tocTab}
            onSelect={(k) => setTocTab(k)}
            transition={false}
        >
            <Tab eventKey="cattoc" title="Catalog">
                <CatToc />
            </Tab>
            {docToc && (
                <Tab eventKey="texttoc" title="Text">
                    <TextToc />
                </Tab>
            )}
            <Tab eventKey="catsearch" title="Search">
                <div className="search-form">
                    <NavSearch />
                </div>
            </Tab>
            <Tab eventKey="catsettings" title="Settings">
                <div className="catsettings">
                    <CatalogSettings />
                </div>
            </Tab>
        </Tabs>
    );
}
