import React, { useEffect, useState } from 'react';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import '../css/catalog.scss';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/all';
import { useCatalog } from '../hooks/useCatalog';
import { Link, Redirect } from 'react-router-dom';
import { zfill, langcode } from '../common/catalogUtils';
import $ from 'jquery';
import { useBiblStore } from '../hooks/useBiblStore';

export default function VolToc({ openFirst }) {
    const bibl = useBiblStore((state) => state.doc); // Bibl State
    const coll = bibl?.coll;
    const edsig = bibl?.edsig;

    const vol_toc_params = {
        q: `type:tibbibl AND subtype:volume AND coll:${coll} AND edsig:${edsig}`,
        rows: 500,
        fl: '*,[child limit=1000]',
        wt: 'json',
    };

    const {
        isLoading: isVolLoading,
        data: volres,
        isError: isVolTocError,
        error: volTocError,
    } = useCatSolr(vol_toc_params);

    // UseEffect to scroll first selected element into view in Vol TOC
    useEffect(() => {
        const selel = $('.c-toc.vol .selected');
        if (selel.length > 0) {
            setTimeout(() => {
                selel.get(0).scrollIntoView();
            }, 1000);
        }
    }, [bibl]);

    // If just switching from dox toc to volume toc and there is a remnant path, redirect to edition root
    if (bibl && window.location.pathname.includes('/dox/')) {
        const edroot = `${process.env.REACT_APP_BASE_PATH}${bibl?.coll}/${bibl?.edsig}`;
        return <Redirect to={edroot} />;
    }

    if (isVolLoading) {
        return <THLSkeleton />;
    }

    if (isVolTocError) {
        console.log('Solr Loading Error:', volTocError);
        return <p>There was a problem.</p>;
    }

    function compare(a, b) {
        const anum = a.vol_num * 1;
        const bnum = b.vol_num * 1;
        if (anum > bnum) {
            return 1;
        }
        if (bnum > anum) {
            return -1;
        }
        return 0;
    }

    let docs = volres && 'docs' in volres ? volres['docs'] : false;
    if (!docs) {
        return <p>No docs found</p>;
    }
    docs.sort(compare);

    return (
        <ul>
            {docs &&
                docs.map((v, n) => {
                    let openMe = openFirst && n === 1 ? true : false;
                    return (
                        <VolTocLink key={`vtl-${n}`} doc={v} isOpen={openMe} />
                    );
                })}
        </ul>
    );
}

function VolTocLink({ doc }) {
    const bibl = useBiblStore((state) => state.doc);
    const biblang = bibl && bibl?.lang ? langcode(bibl.lang[0]) : 'en';
    const volnum = doc.vol_num * 1;
    let currtext = bibl?.text_num;
    if (!Array.isArray(doc.texts)) {
        doc.texts = [doc.texts];
    }

    const [isOpen, setOpen] = useState(false);

    // console.log("doc", doc);

    useEffect(() => {
        currtext = bibl?.text_num;
        setOpen(isOpen || bibl.vol_num * 1 === volnum);
    }, [bibl]);
    if (volnum === 1) {
        // console.log("doc in vol toc link:", doc);
    }
    let sttxt = false;
    let endtxt = false;
    let cnt = '1 Text';
    if (doc?.texts?.length > 0) {
        sttxt = doc.texts[0];
        if (doc.texts.length > 1) {
            endtxt = ' - ' + doc.texts[doc.texts.length - 1]?.display_id;
            cnt = doc.texts.length + ' Texts';
        }
    }
    const icon = isOpen ? <GoTriangleDown /> : <GoTriangleRight />;

    const toggleme = () => {
        setOpen(!isOpen);
    };

    const myid = `vol-${volnum}-div`;
    const clsnm = bibl.vol_num * 1 === volnum ? ' selected' : '';

    if (volnum === 1) {
        // console.log("Bibl in vol toc link", bibl);
    }
    return (
        <li key={`cattoc-vol-${volnum}`} className={`volume${clsnm}`}>
            <div className="vol-label">
                <span className="toc-toggle" onClick={toggleme}>
                    {icon}
                </span>
                <Link
                    to={`/catalog/${bibl.coll}/${bibl.edsig}/v${zfill(
                        volnum,
                        3
                    )}`}
                    id={myid}
                    className="c-toc-item"
                >
                    <span className="vol-title">Volume {doc.vol_num}</span>{' '}
                    {doc.vol_num_category_s && (
                        <>
                            {', '}
                            <span className="bo">
                                {doc.vol_num_category_s}
                            </span>{' '}
                        </>
                    )}
                    <span>
                        ({cnt}, {sttxt?.display_id}
                        {endtxt})
                    </span>
                </Link>
            </div>
            {isOpen && (
                <ul className="textlist">
                    {' '}
                    {doc?.texts?.length > 0 &&
                        doc.texts.map((t, n) => {
                            let selected =
                                t?.text_num === currtext ? ' selected' : '';
                            // console.log(t, currtext);
                            return (
                                <li
                                    key={`vol-toc-link-${n}`}
                                    className={`toc-text${selected}`}
                                >
                                    <VolTextTocLink text={t} lang={biblang} />
                                </li>
                            );
                        })}
                </ul>
            )}
        </li>
    );
}

export function VolTextTocLink({ text, lang }) {
    let mytitle =
        text && Array.isArray(text?.title) ? text.title[0] : text.title;
    if (!mytitle && text?.titles) {
        mytitle = Array.isArray(text.titles)
            ? text.titles[0].title
            : text.titles.title;
    }
    // console.log("text in text toc link", text);
    const displayId = text.display_id;
    const textnum = text.text_num;
    return (
        <Link to={`/catalog/${text.coll}/${text.edsig}/${textnum}`}>
            {displayId}: <span className={lang}>{mytitle}</span>{' '}
        </Link>
    );
}
