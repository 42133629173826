import React, { useEffect, useState } from 'react';
import { useKmap } from '../../hooks/useKmap';
import {
    getUniqueKeys,
    getUniquePropIds,
    markTibetan,
    queryID,
} from '../common/utils';
import MandalaSkeleton from '../common/MandalaSkeleton';
import ReactHtmlParser from 'react-html-parser';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import { HtmlCustom } from '../common/MandalaMarkup';

export default function TranslationEquivalent({ lookup, dictmeta = null }) {
    const [isOpen, setOpen] = useState(true);
    const [hidden, setHidden] = useState(false);
    const [selDef, setSelDef] = useState(''); // Defintion selected by user
    const setSelectedDef = (val) => {
        let newval = val;
        if (val === selDef) {
            newval = '';
            setOpen(true);
        }
        setSelDef(newval);
    };
    let [wd, id] = lookup?.split(':');
    // console.log('id split', wd, id);
    wd = wd + '་'; // add tsek to end of word
    const term_id = queryID('terms', id);
    const {
        isLoading: isTermLoading,
        data: termdata,
        isError: isTermError,
        error: termError,
    } = useKmap(term_id, 'info', false, false);

    useEffect(() => {
        if (selDef?.length > 0) {
            setOpen(false);
        }
    }, [selDef]);

    if (isTermLoading) {
        return <MandalaSkeleton />;
    }

    const defchild = termdata?._childDocuments_?.filter((cd, i) => {
        return cd.block_child_type === 'related_definitions';
    });

    const deflist = {};
    const dictlist = {};
    if (Array.isArray(defchild)) {
        defchild.forEach((c, n) => {
            if (c?.related_definitions_source_code_s) {
                deflist[c?.related_definitions_source_code_s] = c;
            } else {
                let did = c?.id;
                if (did.includes('definitions')) {
                    const dpts = did.split('definitions');
                    did = 'mandala' + dpts[1];
                }
                deflist[did] = c;
                dictlist[did] = { code: did };
            }
        });
    }

    if (Array.isArray(dictmeta)) {
        dictmeta.forEach((dm, dmi) => {
            dictlist[dm.code] = dm;
        });
    }

    const icon = isOpen ? (
        <span className="icon shanticon-minus"></span>
    ) : (
        <span className="icon shanticon-plus"></span>
    );

    let mainclass = isOpen ? 'open' : 'closed';
    if (hidden) {
        mainclass += ' hidden';
    }
    const existing_keys = Object.keys(dictlist).filter((ky, kyi) => {
        return Object.keys(deflist).includes(ky);
    });
    let mainkey = selDef?.length > 0 ? selDef : existing_keys[0];

    const hideMe = () => {
        setHidden(true);
    };

    let has_higgins = false;

    return (
        <div className={`tt-equiv ${mainclass}`}>
            <h3 className="entry-head">
                <a className="toggle" onClick={() => setOpen(!isOpen)}>
                    {icon}
                </a>
                <Link
                    className="tib"
                    to={`/terms/${id}`}
                    target="_blank"
                    title="View term’s full entry"
                >
                    {wd}
                </Link>{' '}
                <span className="term-id">(terms-{id})</span>
            </h3>
            {termdata?.response?.numFound === 0 && (
                <div className={`not-found`}>Not Found</div>
            )}
            <div className={`main-def`}>
                <Translation
                    key={['translation', 'main']}
                    dict={dictlist[mainkey]}
                    data={deflist[mainkey]}
                    setSelect={setSelectedDef}
                    selected={true}
                    style="main"
                />
            </div>
            <div className="hide-link">
                <a onClick={hideMe} title="Remove this word from result list">
                    <span className="icon shanticon-cancel-circle"></span>
                </a>
            </div>
            <Collapse in={isOpen}>
                <table>
                    <tbody>
                        {Object.keys(dictlist)?.map((did, di) => {
                            if (deflist[did]) {
                                if (
                                    deflist[did]
                                        .related_definitions_in_house_source_code_s ===
                                    'higgins'
                                ) {
                                    has_higgins = true;
                                }
                                const tn = has_higgins ? di : di + 1;
                                return (
                                    <Translation
                                        key={['transaltion', di]}
                                        n={tn}
                                        dict={dictlist[did]}
                                        data={deflist[did]}
                                        setSelect={setSelectedDef}
                                        selected={did === selDef}
                                    />
                                );
                            }
                        })}
                    </tbody>
                </table>
            </Collapse>
        </div>
    );
}

function Translation({ n, dict, data, setSelect, selected, style = 'normal' }) {
    const [content, setContent] = useState('');
    const [tibclass, setTibClass] = useState('');

    useEffect(() => {
        let newcontent = data?.related_definitions_content_tibtu;
        if (!newcontent) {
            newcontent = data?.related_definitions_content_latinu;
        }
        if (newcontent) {
            newcontent = markTibetan(newcontent);
            setContent(newcontent);
        }
    }, [data]);

    let content_react = ReactHtmlParser(content, { decodeEntities: true });

    let author = data?.related_definitions_author_s ? (
        <span className="defauth">({data.related_definitions_author_s})</span>
    ) : (
        ''
    );

    const selectMe = () => setSelect(dict?.code);

    if (style === 'main') {
        const src = dict?.title || dict?.code.replace('mandala-', 'Defintion ');
        return (
            <div className="inline-def">
                <span className="main-src">
                    <a onClick={selectMe} title="Unselect this definition">
                        {src}
                    </a>
                </span>{' '}
                {content_react} {author}
            </div>
        );
    }
    const myclass = selected ? ' selected' : '';
    let dicttitle = dict?.title;
    if (!dicttitle) {
        dicttitle = `Definition ${n}`;
    }
    if (data?.related_definitions_author_s === 'David Higgins') {
        dicttitle = 'David Higgins';
        content_react = <TranslationPassages data={data} />;
        author = null;
    }
    //     console.log('react content', content_react);
    return (
        <tr className={`translation${myclass}`}>
            <td className="dict-label">
                <a onClick={selectMe} title="Select this definition">
                    {dicttitle}
                </a>
            </td>
            <td className={`definition${tibclass}`}>
                {content_react}
                {author}
            </td>
        </tr>
    );
}

function TranslationPassages({ data }) {
    const [isOpen, setOpen] = useState(false);
    const toggleMe = () => {
        setOpen(!isOpen);
    };
    const passage_keys = getUniqueKeys(
        data,
        /related_definitions_passage_\d+_content_t/
    );

    const list_content = passage_keys.map((pk, pkn) => {
        return (
            <li key={[data?.id, 'passage', pkn]}>
                <HtmlCustom markup={data[pk]} />
            </li>
        );
    });
    const verb = isOpen ? 'Hide' : 'Show';
    return (
        <div className="tt-passages">
            <h4>
                Example Passages{' '}
                <a className="toggle" onClick={toggleMe}>
                    {verb}
                </a>
            </h4>
            <Collapse in={isOpen}>
                <ol>{list_content}</ol>
            </Collapse>
        </div>
    );
    // return <p>Hello there. Passages belong here! {passage_keys.join(', ')}</p>
}
