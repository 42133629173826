import { KmapsBreadcrumbs } from '../KmapsBreadcrumbs';
import { AssetBreadcrumbs } from './AssetBreadcrumbs';
import React from 'react';

/**
 * ContentHeaderBreadcrumbs
 * Returns the breadcrumbs for an asset or kmap.
 * Used by both ContentHeader and AltContentHeader (below)
 *
 * @param itemData
 * @param itemTitle
 * @param itemType
 * @returns {null|*}
 * @constructor
 */
export function ContentHeaderBreadcrumbs({ itemData, itemTitle, itemType }) {
    switch (itemType) {
        case 'places':
        case 'subjects':
        case 'terms':
            return (
                <KmapsBreadcrumbs
                    kmapData={itemData}
                    itemTitle={itemTitle}
                    itemType={itemType}
                />
            );

        default:
            return (
                <AssetBreadcrumbs
                    itemData={itemData}
                    itemTitle={itemTitle}
                    itemType={itemType}
                />
            );
    }
}
