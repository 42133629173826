import { useQuery } from 'react-query';
import axios from 'axios';
import slugify from 'slugify';
import _ from 'lodash';
import jsonpAdapter from '../logic/axios-jsonp';
import { getSolrUrls } from './utils';
import { getProject } from '../views/common/utils';
import { GetSessionID } from '../main/MandalaSession';

const solr_urls = getSolrUrls();

// REGEX pattern that finds 1. the kmaps domain and 2. the search parameters for the name.
export const KM_REL_REGEX =
    /\(kmapid_(places|subjects|terms)_idfacet:([^)]+)\)/;

export function useKmapRelatedIDs(searchText = '') {
    return useQuery(
        ['km-rel-data', searchText],
        () => getKmRelData(searchText),
        { keepPreviousData: true }
    );
}

/**
 * This hook loads the UIDs of related kmaps based on their name.
 * @param searchText
 * @returns {Promise<{kmids: *, tree: *}|*[]>}
 */
async function getKmRelData(searchText) {
    const mtch = searchText.match(KM_REL_REGEX);
    let data = [];
    if (mtch) {
        let params = {
            q: `names_txt: ${mtch[2]}`,
            fq: `asset_type: ${mtch[1]}`,
            fl: 'uid',
            wt: 'json',
            echoParams: 'explicit',
            indent: 'true',
            rows: 100,
        };

        const request = {
            adapter: jsonpAdapter,
            callbackParamName: 'json.wrf',
            url: solr_urls.assets,
            params: params,
        };

        const resp = await axios.request(request);
        data = resp?.data;
    }

    if (data?.response?.docs) {
        return {
            tree: mtch[1],
            kmids: data.response.docs.map((dit) => dit.uid),
        };
    } else {
        return data;
    }
}
