import { openTabStore } from '../../hooks/useCloseStore';
import React from 'react';
import ReactDOM from 'react-dom';

const target = document.getElementById('main-menu-close');

export function CloseMainMenuBtn({ asPortal }) {
    const setOpenTab = openTabStore((state) => state.changeButtonState);
    const closeMenu = (e) => {
        setOpenTab(0);
    };

    const closeBtn = (
        <div id="main-menu-close-btn">
            <button
                className="mainmenu-header__closeButton"
                onClick={closeMenu}
            >
                <span className="icon shanticon-cancel"></span>
            </button>
        </div>
    );

    if (target && asPortal) {
        return ReactDOM.createPortal(closeBtn, target);
    } else {
        return closeBtn;
    }
}
