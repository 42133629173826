import {
    getBiblLang,
    getMainTitle,
    getPagination,
} from '../common/catalogUtils';
import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import { TextReaderNav } from './TextReaderNav';
import { useBiblStore } from '../hooks/useBiblStore';

export function TextReaderHeader({ view }) {
    const bibl = useBiblStore((state) => state.doc);
    const edBibl = useBiblStore((state) => state.edBibl);
    const collnm = useBiblStore((state) => state.collname);
    const lang = getBiblLang(edBibl);

    //console.log("bibl in text reader header", bibl);

    const pagination = bibl ? getPagination(bibl) : '';
    let title = bibl ? getMainTitle(bibl) : '';
    // console.log("title in text reader header", title);
    const tlang = title.lang;
    title = title ? title.idpref + title.title : '';
    // console.log("edbibil", edBibl);
    const edname = edBibl?.edsig !== 'main' ? edBibl?.ed : null;
    return (
        <Container id="c-text-reader_head">
            <Row id="c-text-reader_info">
                <Col md={4} className="collname">
                    <span className="coll title text-wrap">
                        {edname} {collnm}
                    </span>
                </Col>
                <Col md={6} className="texttitle d-flex">
                    <span className="id">{bibl?.display_id}</span>
                    <>: </>
                    <span
                        className={`title ${tlang} inline-block text-nowrap text-truncate`}
                    >
                        {title}
                    </span>
                </Col>
                <Col md={2} className="pagination">
                    <span className="location text-nowrap">{pagination}</span>
                </Col>
            </Row>
            <TextReaderNav view={view} />
        </Container>
    );
}
