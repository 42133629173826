import React from 'react';
import { Link } from 'react-router-dom';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from './THLSkeleton';
import { capitalize } from './catalogUtils';
import { NavSearch } from '../search/NavSearch';
import { NavBasicSearch } from '../search/NavBasicSearch';
import {useCatSettings} from "../hooks/useCatSettings";

export default function CatalogSettings(props) {
    const query = {
        q: 'subtype:(collection OR edition)',
        fq: 'type:tibbibl',
        fl: '*',
        rows: 200,
    };
    let {
        isLoading: isCollInfoLoading,
        data: collInfo,
        isError: isCollInfoError,
        error: collInfoError,
    } = useCatSolr(query);

    if (isCollInfoLoading) {
        return <THLSkeleton />;
    }

    if (isCollInfoError) {
        console.warn(
            'Error loading NavHead! Unable to get collection information',
            collInfoError
        );
        return null;
    }

    const colleds = {};
    if (Array.isArray(collInfo.docs)) {
        collInfo.docs.forEach((doc, i) => {
            if (doc.subtype === 'collection') {
                colleds[doc.coll] = doc;
                colleds[doc.coll]['eds'] = {};
            }
        });
        collInfo.docs.forEach((doc, i) => {
            if (doc.subtype === 'edition') {
                // Only add edition if it has digital texts. Otherwise cannot perform intertextuality
                if (doc?.resource_digtexts_s?.length > 0 && doc.resource_digtexts_s !== '0') {
                    colleds[doc.coll]['eds'][doc.edsig] = doc;
                }
            }
        });
    }
    return (
        <div>
            <h2>Intertextuality Targets</h2>
            <p>These are the target editions against which the current text is compared for intertextuality.</p>
            <form>
                {Object.keys(colleds).map((coll, i) => {
                    // Exclude any collections that do not have an edition with digital texts
                    if (Object.keys(colleds[coll]?.eds).length > 0) {
                        return <CollSettings key={[coll, i]} coll={colleds[coll]}/>;
                    }
                })}
            </form>
        </div>
    );
}

function CollSettings({coll}) {
    const { targets, setTarget, removeTarget } = useCatSettings((state) => state);
    const checkchanged = (e) => {
        const [csig, esig] = e.target.name.split('|');
        if (e.target.checked) {
            setTarget(csig, esig);
        } else {
            removeTarget(csig, esig);
        }
    };
    return (
        <div>
            <h4>{coll.title_en[0]} Editions</h4>
            <ul>
                {Object.keys(coll.eds).map((ed, i) => {
                    const eddoc = coll.eds[ed];
                    let ischecked = false;
                    if (Object.keys(targets).includes(eddoc.coll) && Object.keys(targets[eddoc.coll]).includes(ed)) {
                        ischecked = true;
                    }
                    let label = eddoc['ed'];
                    return (<li key={['target', eddoc.coll, eddoc.edsig]}>
                        <span>{label}</span> <input name={[eddoc.coll, eddoc.edsig].join('|')} type="checkbox" checked={ischecked} onChange={checkchanged}/>
                    </li>)
                })}
            </ul>
        </div>
    )
}
