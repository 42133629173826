import { useBiblStore } from '../../hooks/useBiblStore';
import React, { useEffect, useState } from 'react';
import {
    getDoxRange,
    getDoxTitleSimple,
    zfill,
} from '../../common/catalogUtils';
import {
    GoPrimitiveSquare,
    GoTriangleDown,
    GoTriangleRight,
} from 'react-icons/all';
import { Link } from 'react-router-dom';
import { DoxTocChild } from './DoxTocChild';
import { DoxTocTextGroup } from './DoxTocTextGroup';
import { DoxTocTextList } from './DoxTocTextList';

/**
 * DoxTocLink creates a tree node in the Dox Toc that is not a link to a text
 * This can be a doxographic category or a volume within a doxographic category.
 * For a straight volume TOC that just lists volumes and texts see VolToc.js
 *
 * @param doc
 * @param isOpen
 * @param selId
 * @returns {JSX.Element|null}
 * @constructor
 */
export function DoxTocLink({ doc }) {
    const selectPath = useBiblStore((state) => state.selectPath);
    const [isOpen, setOpen] = useState(selectPath?.includes(doc?.id));
    const myid = doc?.id;
    const isMissing = doc?.status_s === 'missing';

    useEffect(() => {
        if (selectPath?.includes(doc?.id)) {
            setOpen(true);
        }
    }, [selectPath]);
    /*
    // Update state based on dox doc and current bibl (dox or text) showing
    useEffect(() => {
        let newState = isOpen;
        // Reasons to set open to true:
        // If the current bibl id is the dox
        if (myid === bibl?.id) {
            newState = true;
        }
        // if the current dox id is in the list of child dox ids of an ancestory
        if (
            Array.isArray(bibl?.toc_ids_ss) &&
            bibl.toc_ids_ss.includes(doc?.id)
        ) {
            newState = true;
        }
        // If the current text number is between the range of the current dox cat
        if (doc?.st_text_i && doc?.end_text_i && bibl?.text_num) {
            // console.log(doc.st_text_i, doc.end_text_i, bibl.text_num);
            if (
                doc.st_text_i <= bibl.text_num &&
                bibl.text_num <= doc.end_text_i
            ) {
                newState = true;
            }
        }
        // If the text is in the list of current doxcat's texts
        if (
            bibl?.text_num &&
            Array.isArray(doc?.texts_ss) &&
            doc?.texts_ss.includes(zfill(bibl?.text_num, 4))
        ) {
            newState = true;
        }
        setOpen(newState);
        // console.log("bibl change!", isOpen, doc, bibl);
    }, [bibl]);*/

    let icon = isOpen ? (
        <GoTriangleDown className="toggle-icon down" />
    ) : (
        <GoTriangleRight className="toggle-icon right" />
    );

    if (isMissing) {
        icon = <GoPrimitiveSquare />;
    }

    let children = doc?.children_ss?.map((cid, cn) => {
        return (
            <li key={`${cid}-wrap`}>
                <DoxTocChild cid={cid} key={cid} />
            </li>
        );
    });

    if (doc?.children_ss?.length > 0) {
        children = <ul className="doxlist ml-0">{children}</ul>;
    }
    // console.log("doc in doxtoclink", doc);

    let texts = doc?.texts_ss;
    // console.log("doc tox texts", texts);

    // unclassified is true if there are unclassified texts and the class has subclassifications
    const unclassified = texts?.length > 0 && doc?.children_ss?.length > 0;

    const toggleme = () => {
        setOpen(!isOpen);
    };

    if (!doc) {
        return null;
    }
    /*
    if(doc?.id === 'kt-d-k-1-1-2-dox') {
        console.log("doc for kt-d-k-1-1-2", doc);
    }
     */
    let selClass = '';
    let doc_title = getDoxTitleSimple(doc);
    let myrange = getDoxRange(doc);
    if (doc?.texts_num_i) {
        myrange += `; ${doc.texts_num_i} texts`;
    }

    // If volume or a text in it is being shown, set as selected
    const selected = '';
    /*
        myid === bibl.id || doc?.texts_ss?.includes(zfill(bibl.text_num, '4'))
            ? ' selected'
            : '';

     */
    if (isMissing) {
        return null;
    }
    let showTextList = isOpen && !unclassified && doc?.texts_ss?.length > 0;

    return (
        <div
            id={myid}
            className={`c-toc-doxcat lvl-${doc?.level_i}${selected}`}
        >
            <div className="dox-label">
                <span className="toc-toggle" onClick={toggleme}>
                    {icon}
                </span>
                <Link
                    to={`/catalog/${doc.coll}/${doc.edsig}/dox/${doc.id}`}
                    className={`toggle-link${selClass}`}
                >
                    {doc?.path_id_s}: {doc_title}{' '}
                    <span className="toc-dox-range">{myrange}</span>
                </Link>
            </div>
            {showTextList && <DoxTocTextList doc={doc} />}
            {isOpen && children}
            {isOpen && unclassified && (
                <DoxTocTextGroup doc={doc} texts={texts} />
            )}
        </div>
    );
}
