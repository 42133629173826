import React, { useEffect, useState } from 'react';
import { ButtonGroup, Container, ToggleButton } from 'react-bootstrap';
import { getTitleByLang } from '../common/catalogUtils';
import DoxToc from './dox/DoxToc';
import VolToc from './VolToc';
import { useBiblStore } from '../hooks/useBiblStore';
import { NavSearch } from '../search/NavSearch';
import { useParams } from 'react-router-dom';
import { useSearchStore } from '../hooks/useSearchStore';

export function CatToc() {
    const params = useParams();
    const hasDoxToc = useBiblStore((state) => state.hasDoxToc);
    // console.log('params in cat frame', params);
    const { addFilter } = useSearchStore();

    const { noDoxToc, setNoDoxToc } = useState(false);

    useEffect(() => {
        const { coll, ed } = params;
        if (coll?.length > 0 && ed?.length > 0) {
            addFilter(`coll:${coll}`);
            addFilter(`edsig:${ed}`);
        }
    }, [params]);

    /*
    const bibl = useBiblStore((state) => state.doc);
    const edbibl = useBiblStore((state) => state.edBibl);
    const toc = useBiblStore((state) => state.toc);
    const setToc = useBiblStore((state) => state.setToc);
    const [doxDisabled, setDoxDisabled] = useState(false);

    const toctypes = [
        { id: 'tocdox', label: 'Categories', value: 'dox' },
        { id: 'tocvol', label: 'Volumes', value: 'vol' },
    ];

    // const [edTitle, setEdTitle] = useState();
    /*
  console.log("Sel id", selId)
  console.log("bibl", bibl);
  console.log("ed bibl", edbibl);
   */
    /*
    useEffect(() => {
        if (edbibl?.titles && edbibl?.titles?.length > 0) {
            const edlang = edbibl?.langcode ? edbibl.langcode : 'en';
            let newTitle = getTitleByLang(edbibl.titles, edlang);
            // setEdTitle(newTitle);
        }
    }, [edbibl]);
    let isOpen = true;
    const updateToc = (newTocType) => {
        setToc(newTocType);
    };
    const noDoxToc = () => {
        setDoxDisabled(true);
        updateToc('tocvol');
    };*/
    /*
        TODO: need to replace nonecallback
                noneCallback={noDoxToc} with better code. UseEffect maybe?
     */
    const [toc, setToc] = useState('dox');

    const toctypes = [
        { id: 'tocdox', label: 'Categories', value: 'dox' },
        { id: 'tocvol', label: 'Volumes', value: 'vol' },
    ];

    const currentToc = toc === 'dox' && hasDoxToc ? <DoxToc /> : <VolToc />;

    return (
        <Container className={`c-toc ${toc}`}>
            <div className="c-toc-header">
                <ButtonGroup className="c-toc-toggle mb-2">
                    {toctypes.map((ttype, idx) => (
                        <ToggleButton
                            key={idx}
                            id={ttype.id}
                            type="radio"
                            variant="light"
                            name="toctype"
                            value={ttype.value}
                            checked={
                                toc === ttype.value ||
                                toc === ttype.id ||
                                (ttype.id === 'tocvol' && !hasDoxToc)
                            }
                            disabled={ttype.id === 'tocdox' && !hasDoxToc}
                            className="toc-type-btn"
                            onChange={(e) => setToc(e.currentTarget.value)}
                        >
                            {ttype.label}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                {/*
                <div className="search-form">
                    <NavSearch />
                </div>
                */}
            </div>
            {currentToc}
        </Container>
    );
}
