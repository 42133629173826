import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import CatalogSummary from './CatalogSummary';
import { CollectionSummary } from './CollectionSummary';
import './css/catalog.scss';
import CatalogFrame from './CatalogFrame';

export default function Catalog() {
    let match = useRouteMatch('/catalog/:coll');
    // console.log("coll in catalog", match?.params?.coll);
    let coll = match?.params?.coll;
    return (
        <div id="catalog">
            <Switch>
                <Route path={'/catalog/search'}>
                    <CatalogFrame search={true} />
                </Route>
                <Route path={'/catalog/:coll/eds'}>
                    <CollectionSummary />
                </Route>

                <Route
                    path={[
                        '/catalog/:coll/:ed/:txtnum/:view/section/:sid',
                        '/catalog/:coll/:ed/:txtnum/:view/:vol/:page',
                        '/catalog/:coll/:ed/dox/:dox',
                        '/catalog/:coll/:ed/:txtnum/:view',
                        '/catalog/:coll/:ed/v:vol',
                        '/catalog/:coll/:ed/:txtnum',
                        '/catalog/:coll/:ed',
                        '/catalog/search',
                    ]}
                >
                    <CatalogFrame />
                </Route>
                <Route path={'/catalog/:coll'}>
                    <Redirect to={`/catalog/${coll}/eds`} />
                </Route>
                <Route path="*">
                    <CatalogSummary />
                </Route>
            </Switch>
        </div>
    );
}
