import {
    ButtonGroup,
    Col,
    Dropdown,
    DropdownButton,
    Row,
} from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { TextReaderPager } from './TextReaderPager';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
    convertToSectionLabel,
    inRange,
    splitNumberedTitle,
    langcode,
} from '../common/catalogUtils';
import { usePager } from '../hooks/usePager';
import { useBiblStore } from '../hooks/useBiblStore';
import { BsCardList, FiEye, FiEyeOff } from 'react-icons/all';
import { usePagerText } from '../hooks/usePagerText';
import { useCookies } from 'react-cookie';

export function TextReaderNav() {
    const bibl = useBiblStore((state) => state.doc);
    const edBibl = useBiblStore((state) => state.edBibl);
    const hasText = inRange(bibl?.text_num, edBibl?.textrange);
    const hasScans = inRange(bibl?.text_num, edBibl?.scanrange);
    const history = useHistory();
    const { view } = useParams();
    const location = useLocation();

    let sections = bibl?.sections;

    // console.log("Sections in text reader nav", sections);
    const basic_pager = usePager();
    const text_pager = usePagerText();
    const pager = view === 'text' ? text_pager : basic_pager;

    // const sectionListEl = useRef(null);
    const sectionPageEl = useRef(null);

    // Cookies for Settings
    const cookie_names = ['thlcat_showpages', 'thlcat_showlines'];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    // Line number toggle
    const [showPages, setShowPages] = useState(true);
    const [showLines, setShowLines] = useState(true);
    const [noLines, setNoLines] = useState(false);

    useEffect(() => {
        if (cookies?.thlcat_showpages) {
            const sp = cookies.thlcat_showpages === 'true';
            setShowPages(sp);
            if (!sp) {
                document
                    .getElementById('c-text-reader')
                    .classList.add('hidepages');
            } else {
            }
        }
        if (cookies?.thlcat_showlines) {
            const sl = cookies.thlcat_showlines === 'true';
            setShowLines(sl);
            if (!sl) {
                document
                    .getElementById('c-text-reader')
                    .classList.add('hidelines');
            }
        }
    }, []);

    useEffect(() => {
        if (showPages) {
            document
                .getElementById('c-text-reader')
                .classList.remove('hidepages');
        } else {
            document.getElementById('c-text-reader').classList.add('hidepages');
        }
        if (showLines) {
            document
                .getElementById('c-text-reader')
                .classList.remove('hidelines');
        } else {
            document.getElementById('c-text-reader').classList.add('hidelines');
        }
    }, [showLines, showPages]);

    useEffect(() => {
        let pgsect = pager?.section;
        if (pgsect) {
            if (pager?.subsection) {
                pgsect = pager.subsection;
            }
            if (pager?.visible && pager?.lastAction === 'scroll') {
                pgsect =
                    pager.visible?.length > 1
                        ? pager.visible[1]
                        : pager.visible[0];
            }

            let currsect = sections?.filter((si) => {
                return si?.section_num_s === pgsect || si.chapid_s === pgsect;
            });
            currsect = currsect?.length > 0 ? currsect[0] : false;
            let label = '',
                sectnum = '';
            if (currsect?.section_title_s) {
                [sectnum, label] = splitNumberedTitle(
                    currsect?.section_title_s
                );
            } else {
                if (currsect?.title && currsect?.title?.length > 0) {
                    label = currsect?.title[0];
                }
                if (pager?.sectpgn) {
                    label += ` (${pager.sectpgn})`;
                }
            }
            sectionPageEl.current.textContent = label;
            sectionPageEl.current.setAttribute('title', label);
        }
        //console.log("pager in text reader nav", pager);
    }, [pager]);

    const changePageType = (val) => {
        // setView(e);
        if (val !== view) {
            const newpath = location.pathname?.replace(view, val);
            history.push(newpath);
        }
    };

    // console.log(view, location);
    const togglePages = () => {
        const newval = !showPages;
        setShowPages(newval);
        setCookie('thlcat_showpages', newval, { path: '/' });
    };

    const toggleLines = (e) => {
        const newval = !showLines;
        setShowLines(newval);

        setCookie('thlcat_showlines', newval, { path: '/' });
    };

    const bibllink = `/catalog/${bibl?.coll}/${bibl?.edsig}/${bibl?.text_num}`;

    // console.log('history', history, 'biblink', bibllink, 'ref', document.referrer);

    // Return the Text Reader Navigation Row for Grid display
    return (
        <Row id="c-text-reader_nav">
            <Col md={1}>
                <TextReaderSectionList />
            </Col>
            <Col md={3}>
                {/* This div holds the name of the current sections showing */}
                <div className="sectscope" ref={sectionPageEl}>
                    {' '}
                </div>
            </Col>
            <Col md={5}>
                {/* bibl.resource_milestones_s is set only in alternative cases where no milestones or multiple editions */}
                {bibl?.resource_milestones_s ? (
                    <div className="pager-msg font-italic text-left">
                        Pagination not available
                    </div>
                ) : (
                    <TextReaderPager view={view} />
                )}
            </Col>
            <Col md={3}>
                <div className="form-group pgtype">
                    <label className="form-label">View</label>
                    <DropdownButton
                        as={ButtonGroup}
                        variant="link"
                        id={`dropdown-button-pgtype`}
                        size="sm"
                        title={
                            ['text', 'pages'].includes(view) ? 'Text' : 'Scans'
                        }
                        onSelect={changePageType}
                    >
                        <Dropdown.Item
                            key="tr-pgtype-dig"
                            eventKey="text"
                            disabled={!hasText}
                        >
                            Text
                        </Dropdown.Item>
                        <Dropdown.Item
                            key="tr-pgtype-img"
                            eventKey="scans"
                            disabled={!hasScans}
                        >
                            Scans
                        </Dropdown.Item>
                    </DropdownButton>
                    <div className="toggles">
                        {view !== 'scans' && (
                            <>
                                <span
                                    className={`pagenumtoggle  ${
                                        showPages ? 'on' : 'off'
                                    }`}
                                >
                                    <a
                                        onClick={togglePages}
                                        title={
                                            showPages
                                                ? 'Showing page numbers. Click to hide.'
                                                : 'Hiding page numbers. Click to show.'
                                        }
                                    >
                                        <span className="letter">Pages</span>
                                    </a>
                                </span>

                                <span
                                    className={`linenumtoggle ${
                                        showLines ? 'on' : 'off'
                                    }`}
                                >
                                    <a
                                        onClick={toggleLines}
                                        title={
                                            showLines
                                                ? 'Showing line numbers. Click to hide.'
                                                : 'Hiding line numbers. Click to show.'
                                        }
                                        className={
                                            noLines ? 'disabled' : undefined
                                        }
                                    >
                                        <span className="letter">Lines</span>
                                    </a>
                                </span>
                            </>
                        )}

                        <span className="backlink">
                            <Link to={bibllink}>
                                <BsCardList title="Back to Catalog Record" />
                            </Link>
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

// TbBookOff  TbIdOff
// { showLines ?  <FiEye /> : <FiEyeOff /> }

function TextReaderSectionList(props) {
    const biblStore = useBiblStore();
    let bibl = biblStore.doc;
    const pager = usePager();
    const textpager = usePagerText();
    const [current, setCurrent] = useState('');
    const sectionListEl = useRef(null);
    let sections = bibl?.sections || biblStore.dynamicSections;
    let items = sections?.map((sct, si) => {
        return <SectionDropdownOption key={`sect-opt-${si}`} sct={sct} />;
    });
    // console.log("sections/items", sections, items);
    useEffect(() => {
        if (!sections || sections?.length === 0) {
            items = (
                <div className="d-inline-block text-nowrap">No Sections.</div>
            );
        }
    }, [sections]);

    useEffect(() => {
        if (pager?.section && pager.section !== current) {
            setCurrent(pager.section);
        }
    }, [pager]);

    useEffect(() => {
        const tpsect = textpager?.subsection || textpager?.section;
        if (tpsect && tpsect !== current) {
            setCurrent(tpsect);
        }

        if (textpager?.visible && textpager?.visible?.length > 0) {
            const currsec =
                textpager.visible.length > 1
                    ? textpager.visible[1]
                    : textpager.visible[0];
            setCurrent(currsec);
        }
    }, [textpager]);

    let changeSection = (sid) => {
        console.log('change section', sid);
        let sect = sections.filter((sct) => {
            return sct?.id === sid;
        });
        if (sect?.length > 0) {
            sect = sect[0];
            sect.texttype = bibl?.texttype;
            console.log('setting to section', sect);
            pager.setSection(sect);
            textpager.setSection(sect);
            const elid = `s-${sect?.chapid_s}`;
            setTimeout(function () {
                const el = document.getElementById(elid);
                if (el) {
                    el.scrollIntoView();
                }
            }, 300);
        } else {
            pager.setSection('No Section');
            // console.warn("No section data found for: " + sid);
        }
    };

    // console.log(typeof(items), items);
    if (!items || items?.length === 0) {
        return <span className="text-nowrap text-secondary">No sections</span>;
    }

    const dbtitle = convertToSectionLabel(current) || 'Sections';
    return (
        <DropdownButton
            as={ButtonGroup}
            id={`dropdown-button-drop-1`}
            variant="link"
            size="sm"
            title={dbtitle}
            onSelect={changeSection}
            ref={sectionListEl}
            className="section-list"
        >
            {items}
        </DropdownButton>
    );
}

function SectionDropdownOption({ sct }) {
    if (sct?.section_title_s) {
        const [sectid, secttitle] = splitNumberedTitle(sct?.section_title_s);
        return (
            <Dropdown.Item eventKey={sct?.id}>
                <span className="chapid">{sectid}</span>
                <span className={langcode(sct?.lang)}>{secttitle}</span>
            </Dropdown.Item>
        );
    }
    return null;
}
