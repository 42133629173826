import { useBiblStore } from '../../hooks/useBiblStore';
import { useCatSolr } from '../../hooks/useCatSolr';
import React from 'react';
import { getMainTitle } from '../../common/catalogUtils';
import { Link } from 'react-router-dom';

export function DoxTocText({ tdoc }) {
    // console.log("in doxtoctext");
    const bibl = useBiblStore((state) => state.doc);
    if (!tdoc || !tdoc?.id) {
        return null;
    }
    // console.log("displaying text", tdoc);
    const dtocid = `dox-toc-${tdoc.id}`;
    const selected = bibl?.id === tdoc.id ? ' selected' : '';
    const displayId = tdoc?.display_id || tdoc?.id || 'no id found';

    const mytitle = getMainTitle(tdoc);
    const mainTitle = <span className={mytitle?.lang}>{mytitle?.title}</span>;
    return (
        <li key={dtocid} id={dtocid} className={`toc-text${selected}`}>
            <Link to={`/catalog/${tdoc.coll}/${tdoc.edsig}/${tdoc.text_num}`}>
                {displayId}: {mainTitle}{' '}
            </Link>
        </li>
    );
}
