import React from 'react';
import { getFieldsByString, getLangFromString } from '../common/catalogUtils';

export function BiblAgents({ bibl }) {
    const agents = getBiblAgents(bibl);
    return (
        <>
            {agents.map((ag, agind) => {
                return (
                    <li key={`agent-${agind}`}>
                        <label>{ag['role']}</label>
                        <span className={ag['lang']}>{ag['name']}</span>
                    </li>
                );
            })}
        </>
    );
}

export function getBiblAgents(bibl) {
    // Old code but may be used somehow
    const re = new RegExp(/^agent_(\w+_)+(\w{2})$/);
    const bkeys = Object.keys(bibl).filter((bk) => {
        return re.test(bk);
    });
    let agents_out = bkeys.map((bk) => {
        let mtch = re.exec(bk);
        let agnm = bibl[bk];
        if (typeof agnm === 'object' && agnm?.length) {
            if (agnm.length === 1) {
                agnm = agnm[0];
            } else {
                agnm = agnm.join(', ');
            }
        }
        let agent = {
            name: agnm,
            role: mtch[1].replace(/_/g, ' ').trim(),
            lang: mtch[2],
        };
        return agent;
    });
    // New code as of Feb. 2023 Created for LCCW
    if (agents_out.length === 0 && bibl?.agents_txt?.length > 0) {
        agents_out = bibl.agents_txt.map((agt, agtn) => {
            let nmfld = getFieldsByString(agt, 'name_', 'pref');
            if (Array.isArray(nmfld)) {
                nmfld = nmfld[0];
            }
            let agtnm = agt[nmfld];
            if (Array.isArray(agtnm)) {
                agtnm = agtnm[0];
            }
            const lng = getLangFromString(agtnm);
            return {
                name: agtnm,
                role: agt?.role_s,
                lang: lng,
            };
        });
    }
    return agents_out;
}
