import create from 'zustand';
import {
    getLangFromString,
    nextPage,
    pageInRange,
    parsePagination,
    prevPage,
    zfill,
} from '../common/catalogUtils';
import axios from 'axios';

/**
 *
 * Zustand Store that stores the current search information
 *
 */
export const EXCLUDED_FACETS = ['corresp', 'revision', 'voltoc']; // Type Facets only

export const useSearchStore = create((set, get) => ({
    isActive: false, // whether searching is active
    isNew: false, // whether the search is new and SOLR needs to be queried
    setActive: (val) => {
        val = !!val;
        set({ isActive: !!val });
    },
    setIsNew: (val) => set({ isNew: !!val }),
    showing: false, // whether results are showing
    setShowing: (val) => set({ showing: !!val }),
    query: '', // the query string
    queryLang: 'en',
    getQuery: () => {
        return get().query.replace(/"/g, '');
    },
    setQuery: (qstr) => {
        set({
            query: qstr,
            queryLang: getLangFromString(qstr),
            isActive: true,
            isNew: true,
            facetChange: false,
        });
    },
    solrQuery: {}, // the SOLR options object used to perform the search
    setSolrQuery: (obj) => set({ solrQuery: obj }),
    numFound: 0, // number of search results found
    numBuckets: 0,
    start: 0,
    setStart: (st) => {
        set({ start: st });
    },
    numPerPage: 25,
    setNumPerPage: (pp) => {
        const newTotal = Math.ceil(
            parseInt(get().buckets?.length) / parseInt(pp)
        );
        set({ numPerPage: pp, totalPages: newTotal });
    },
    totalPages: 1,
    buckets: [],
    results: null, // the JSON search results converted into a JS Object
    facetTypes: { type: 'Record Type', coll: 'Collection' }, // Dictionary whose keys are solr field names and values are labels to display for facets
    initialFacets: null, // Object that contains the initial facet counts of a search
    filters: { type: ['title', 'textpg'] }, // facet filters
    facetChange: false,
    addFilter: (fq) => {
        // console.log("in add filter: " + fq);
        const flts = get().filters;
        let [nm, value] = fq.split(':');
        if (!nm || !value) {
            console.log(
                'not able to parse filter: ' + fq + ' : ' + nm + ' - ' + value
            );
            return;
        }
        if (Object.keys(flts).includes(nm)) {
            if (!flts[nm].includes(value)) {
                flts[nm].push(value);
            }
        } else {
            flts[nm] = [value];
        }
        // console.log('adding filter', flts);
        set({ filters: flts, facetChange: true });
    },
    removeFilter: (fq) => {
        let [nm, value] = fq.split(':');
        const flts = get().filters;
        const vals = flts[nm];
        if (vals?.includes(value)) {
            vals.splice(vals.indexOf(value), 1);
            flts[nm] = vals;
            set({ filters: flts, facetChange: true });
        }
    },
    hasFilter: (fq) => {
        let [nm, value] = fq.split(':');
        const flts = get().filters;
        if (flts && flts[nm]) {
            return flts[nm].includes(value);
        }
        return false;
    },
    hasFilters: () => {
        const flts = get().filters;
        return flts && Object.keys(flts)?.length > 0;
    },
    resetFilters: () => {
        set({ filters: {} });
    },
    setFilters: (filterobj) => {
        // console.log('in set filters', filterobj);
        set({ filters: filterobj });
    },
    getFacets: () => {
        const resobj = get().results;
        let facets = resobj?.facets;
        //console.log("result factes", facets?.type);
        if (facets?.type) {
            Object.keys(facets.type).forEach((key) => {
                if (EXCLUDED_FACETS.includes(key)) {
                    delete facets.type[key];
                }
            });
        }
        return [get().facetTypes, facets, get().results?.facetsjson];
    },
    setFacetTypes: (data) => set({ facetTypes: data }),
    setInitialFacets: (facets) => set({ initialFacets: facets }),
    setResults: (res) => {
        // console.log('results in setResults', res);
        const numbuckets = res?.buckets?.length;
        const pp = get().numPerPage;
        const totalP = Math.ceil(numbuckets / pp);
        set({
            results: res,
            numFound: res?.numFound,
            buckets: res?.buckets,
            numBuckets: numbuckets,
            totalPages: totalP,
            facetChange: false,
        });
    },
    reset: () => {
        set({
            query: '',
            isActive: false,
            isNew: false,
            facetChange: false,
            filters: [],
            solrQuery: {},
            numFound: 0,
            results: null,
            facetTypes: { type: 'Record Type', coll: 'Collection' },
            initialFacets: null,
        });
    },
}));
