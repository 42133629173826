import React, { useEffect, useRef, useState } from 'react';
import { useBiblStore } from '../hooks/useBiblStore';
import { Button, Form } from 'react-bootstrap';
import { useSearchStore } from '../hooks/useSearchStore';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import jsonpAdapter from '../common/axios-jsonp';
import { useCookies } from 'react-cookie';
import { IoArrowBack } from 'react-icons/all';
import { getLangCodeForString } from '../common/utils';

export function NavBasicSearch() {
    const search = useSearchStore();
    const [disabled, setDisabled] = useState(false);
    // When searchstr state is changed, the search is initiated
    const [searchStr, setSearchStr] = useState(null);
    const [doSearch, setDoSearch] = useState(false);
    // const [doRedirect, setRedirect] = useState(false); // state to trigger redirect to search page
    // const [convertWylie, setConvertWylie] = useState(false);
    const defaultLang = search?.query?.length > 0 ? search.queryLang : 'en';
    const [searchBoxLang, setSearchBoxLang] = useState(defaultLang);
    let onSearchPage = useRouteMatch('/catalog/search');
    const history = useHistory();
    const seachboxRef = useRef(); // Reference to html text input where search string is entered

    //  console.log('on search page', onSearchPage);

    // const wyliecbRef = useRef();
    const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    const processWylie = (wy, inputbox = false) => {
        // console.log(`Wylie is [${wy}]`, searchStr);
        if (wy === searchStr?.wy?.trim() || doSearch) {
            return;
        }
        if (searchBoxLang === 'bo') {
            setSearchStr({ wy: false, bo: wy });
        } else if (wy.charAt(0) === '"') {
            setSearchStr({ wy: wy, bo: false });
        } else {
            // Make call for wylie
            let request = {
                url: process.env.REACT_APP_WYLIE_URL,
                adapter: jsonpAdapter,
                dataType: 'jsonp',
                callbackParamName: 'callback',
                params: { wy: wy, callback: 'wylie' },
            };

            axios
                .request(request)
                .then((res) => {
                    const wyret = res.data.wy;
                    let bo = res.data.bo;
                    // console.log('result from converting wylie', res, doSearch);
                    setSearchStr({ wy: res?.data.wy, bo: res?.data?.bo });
                })
                .catch((err) => {
                    console.log('error fetching tibetan from wylie', err);
                });
        }
    };

    // If query already defined and searchbox exists, fill in search box with current query
    /* Too  many rerenders but need to set cookie...
    useEffect(() => {
        if (search?.query && seachboxRef?.current) {
            const sval = search?.query.replace(/"/g, '');
            //setSearchBoxLang(getLangCodeForString(sval));
            // seachboxRef.current.value = sval;
            setCookie('thlcat_searchquery', sval);
            setCookie('thlcat_searchactive', true);
        }
    }, [search]);

     */

    useEffect(() => {
        if (search?.query) {
            seachboxRef.current.value = search.query;
        }
    }, []);

    /*
    useEffect(() => {
        if (cookies?.thlcat_convwyl) {
            setConvertWylie(cookies.thlcat_convwyl === 'true');
        }
    }, []);

     */

    // When the search string state is changed, initiate the search, redirect to search page if not on it
    useEffect(() => {
        if (doSearch) {
            if (searchStr?.bo) {
                search.setQuery(searchStr.bo);
                setCookie('thlcat_searchquery', searchStr.bo);
            } else if (searchStr?.wy) {
                search.setQuery(searchStr.wy);
                setCookie('thlcat_searchquery', searchStr.wy);
            } else {
                console.log('No search string', searchStr);
            }

            setCookie('thlcat_searchactive', true);
            history.push('/catalog/search');
        }
        return () => {
            setDoSearch(false);
        };
    }, [searchStr]);

    const setInputVal = (e) => {
        const sval = seachboxRef?.current?.value.trim();
        processWylie(sval, true);
    };

    const submitSearch = (e) => {
        e.preventDefault();
        setInputVal(e);
        setDoSearch(true);
    };

    const checkLang = (e) => {
        const sval = seachboxRef?.current?.value;
        setSearchBoxLang(getLangCodeForString(sval));
    };

    const clearCheck = (e) => {
        if (search.isActive) {
            const sval = seachboxRef?.current?.value;
            setSearchBoxLang('en');
            // console.log("clear check", sval, search.isActive, e);
            if (sval == '') {
                seachboxRef.current.value = '';
                search.reset();
            }
        }
    };

    const onSearchClass = onSearchPage && search?.showing ? ' search-page' : '';

    return (
        <Form className="nav-search-form d-flex p-2" onSubmit={submitSearch}>
            <div className={`search-and-back${onSearchClass}`}>
                <Form.Control
                    type="search"
                    placeholder="Search & Explore!"
                    className={`me-2 cat-search ${searchBoxLang}`}
                    aria-label="Search"
                    ref={seachboxRef}
                    onKeyUp={checkLang}
                    onChange={clearCheck}
                    disabled={disabled}
                />
                {/*  onBlur={setInputVal} */}

                <Button
                    variant="outline-success"
                    onClick={submitSearch}
                    className="submit-btn"
                    disabled={disabled}
                >
                    Search
                </Button>
            </div>
            {search.isActive && !onSearchPage && (
                <div className={`back`}>
                    <Link to="/catalog/search" title="Back to results page">
                        Back to search results
                    </Link>
                </div>
            )}
        </Form>
    );
}
