import { useLocation } from 'react-router-dom';
import { parseParams, queryID } from '../../views/common/utils';
import { useKmap } from '../../hooks/useKmap';
import useCollection from '../../hooks/useCollection';
import React, { useEffect } from 'react';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import { CollectionBreadcrumbs } from './Breadcrumbs/CollectionBreadcrumbs';
import { ContentHeaderBreadcrumbs } from './Breadcrumbs/ContentHeaderBreadcrumbs';

/**
 * AltContentHeader:
 * for cases where a kmap item is not indexed in the kmasset solr index but only in the kmterms one
 *
 * @param domain
 * @param kid
 * @param siteClass
 * @returns {JSX.Element}
 * @constructor
 */
export function AltContentHeader({ domain, kid, siteClass, isCollection }) {
    const loc = useLocation();
    const params = loc?.search ? parseParams(loc.search) : false;
    const {
        isLoading: isItemLoading,
        data: itemData,
        isError: isItemError,
        error: itemError,
    } = useKmap(queryID(domain, kid), 'info');

    const {
        isLoading: isCollLoading,
        data: collData,
        isError: isCollError,
        error: collError,
    } = useCollection(domain, kid);

    useEffect(() => {
        const contentTitle = document.getElementById('sui-termTitle');
        const cttext = contentTitle?.innerText.toLowerCase();
        if (contentTitle && !cttext.includes('not found')) {
            contentTitle.innerText = alttitle;
        }
    });

    if (isItemLoading || isCollLoading) {
        return <MandalaSkeleton />;
    }

    let alttitle = itemData?.header ? itemData.header : false;
    let bcrumbs = itemData ? (
        <ContentHeaderBreadcrumbs
            itemData={itemData}
            itemTitle={alttitle}
            itemType={domain}
        />
    ) : (
        ''
    );
    // console.log("new coll data", collData);
    let newCollData =
        isCollection && collData?.numFound > 0 ? collData.docs[0] : collData;
    if (!alttitle && domain?.length > 1) {
        alttitle = domain[0].toUpperCase() + domain.substr(1);
        bcrumbs = '';
        if (domain == 'search' && params?.searchText) {
            const decoded = decodeURI(params.searchText);
            alttitle += ` for “${decoded}”`;
        }
    }

    const cheader = (
        <header
            id="c-content__header__main"
            className={`c-content__header__main legacy ${siteClass} ${domain}`}
        >
            <div
                id="c-content__header__main__wrap"
                className="c-content__header__main__wrap legacy"
            >
                <h1 className={'c-content__header__main__title alttitle'}>
                    <span className={`icon u-icon__${domain}`}></span>
                    {alttitle}
                </h1>

                <div className={'c-content__header__breadcrumb breadcrumb'}>
                    {bcrumbs && !isCollection && bcrumbs}
                    {isCollection && (
                        <CollectionBreadcrumbs collData={newCollData} />
                    )}
                </div>
                <h5 className={'c-content__header__main__id'}>{kid}</h5>
            </div>
        </header>
    );
    return cheader;
}
