import create from 'zustand';
import { parsePagination, zfill } from '../common/catalogUtils';

/**
 * usePagerText: Pager for a deeply structured digital text modelled on usePager
 *
 * @type {UseStore<{next: function(): void, side: string, visible: [], setLoaded: function(*): void,
 * prev: function(): void, section: string, setSection: function(*): void, updateVisible: function(*, *): void,
 * loaded: [], vol: number, lastAction: string, page: number, ed: string, setPage: function(*, *, *): void}>}
 */
export const usePagerText = create((set, get) => ({
    ed: '',
    vol: -1,
    page: -1,
    side: '',
    loaded: [],
    section: 'a1', // current chap level section being viewed
    subsection: '', // actual section clicked on if below chap level
    toc: [],
    visible: [],
    lastAction: 'initial',
    next: () => {
        const toc = get().toc;
        const sctind = toc.indexOf(get().section);
        if (sctind > toc.length - 2) {
            set((state) => ({
                section: toc[sctind + 1],
                lastAction: 'change',
            }));
        }
    },
    getNext: (sid) => {
        const toc = get().toc;
        const sctind = toc.indexOf(sid);
        if (sctind > toc.length - 2) {
            return toc[sctind + 1];
        }
    },
    prev: () => {
        const toc = get().toc;
        const sctind = toc.indexOf(get().section);
        if (sctind > 0) {
            set((state) => ({
                section: toc[sctind - 1],
                lastAction: 'change',
            }));
        }
    },
    getPrev: (sid) => {
        const toc = get().toc;
        const sctind = toc.indexOf(sid);
        if (sctind > 0) {
            return toc[sctind - 1];
        }
    },
    setLoaded: (sections) => {
        set((state) => ({
            loaded: sections,
        }));
    },
    setSection: (sect) => {
        let sid = sect?.section_num_s ? sect.section_num_s : sect;
        if (sect?.chapid_s) {
            sid = sect.chapid_s;
        }
        if (typeof sid !== 'string') {
            return;
        }
        set((state) => ({
            section: sid,
            subsection: '',
            lastAction: 'section',
        }));
    },
    setTOC: (sections) => {
        set((state) => ({
            toc: sections,
        }));
    },
    updateVisible: (vblist) => {
        vblist.sort();
        set({ visible: vblist, lastAction: 'scroll' });
        // console.log("Visible in pager", vblist);
    },
}));
