import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { useCatSolr } from './hooks/useCatSolr';
import THLSkeleton from './common/THLSkeleton';
import { HtmlCustom } from './common/HtmlCustom';
import React from 'react';
import './css/CatalogFrame.scss';
import { CollInfo } from './CollInfo';

export function CollectionSummary() {
    const params = useParams(); // Get URL Params
    const coll = params?.coll;
    const query = {
        q: `subtype:(collection edition)`,
        fq: ['type:tibbibl', `coll:${coll}`],
        facet: true,
        'json.facet':
            '{"subtype": {"type":"terms", "field":"subtype","limit":-1}}',
        fl: '*',
        rows: 100,
    };
    const {
        isLoading: isCollInfoLoading,
        data: collInfo,
        isError: isCollInfoError,
        error: collInfoError,
    } = useCatSolr(query);

    if (isCollInfoLoading) {
        return <THLSkeleton />;
    }
    if (!collInfo?.docs?.length || collInfo?.docs?.length < 1) {
        return <p>No Collection, {coll}, to display!</p>;
    }
    const colldoc = collInfo.docs.filter((d) => d.subtype === 'collection');
    const colleds = collInfo.docs.filter((d) => d.subtype === 'edition');
    // console.log("Coll info in coll summary", collInfo);
    return (
        <div className="l-catalog-wrapper">
            <div className="summary">
                <CollInfo
                    doc={colldoc[0]}
                    eds={colleds}
                    showEds={true}
                    showHomeLink={true}
                />
            </div>
        </div>
    );
}
