import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import _ from 'lodash';
import jsonpAdapter from '../logic/axios-jsonp';
import { getSolrUrls, capitalize } from './utils';
import { getProject } from '../views/common/utils';
import { GetSessionID } from '../main/MandalaSession';
import { constructTextQuery } from './useSearch';

const solr_urls = getSolrUrls();

export function useInfiniteSearch(
    searchText = '',
    start = 0,
    rows = 0,
    facetType = 'all',
    facetLimit = 0,
    facetBuckets = false,
    filters = [],
    sortField,
    sortDirection,
    facetSearch = '',
    enabled = true
) {
    /*
    if (facetType === 'asset_count' && searchText.includes('idfacet')) {
        console.log('params in use infinite for idfacet', [
            searchText,
            start,
            rows,
            facetType,
            facetLimit,
            facetBuckets,
            filters,
            sortField,
            sortDirection,
            facetSearch,
            enabled,
        ]);
    }
    */
    searchText = searchText.replace(/"/g, ''); // quotes are added as necessary in code below
    return useInfiniteQuery(
        [
            'search',
            {
                start,
                rows,
                facetType,
                facetLimit,
                facetBuckets,
                filters,
                sortField,
                sortDirection,
                facetSearch,
                searchText,
            },
        ],
        getSearchData,
        {
            enabled,
            getNextPageParam: (lastPage) => {
                // Get last Offset.
                const lastOffset = JSON.parse(
                    lastPage.responseHeader.params['json.facet']
                )[facetType].offset;

                // Get number of buckets for this facetType
                const numBuckets = lastPage.facets[facetType]?.numBuckets || 0;

                // Set next offset
                let nextOffset = lastOffset + 100;
                if (nextOffset >= numBuckets) {
                    nextOffset = false;
                }

                return nextOffset;
            },
        }
    );
}

async function getSearchData({ queryKey, pageParam = 0 }) {
    // console.log("query key", queryKey);
    const [
        // eslint-disable-next-line no-unused-vars
        _key,
        {
            start,
            rows,
            facetType,
            facetLimit,
            facetBuckets,
            filters,
            sortField,
            sortDirection,
            searchText,
            facetSearch,
        },
    ] = queryKey;

    let jsonFacet = getJsonFacet(
        facetType,
        pageParam,
        facetLimit,
        facetBuckets,
        sortField,
        sortDirection,
        facetSearch
    );

    let params = {
        fl: '*',
        wt: 'json',
        echoParams: 'explicit',
        indent: 'true',
        start: start,
        rows: rows,
        'json.facet': JSON.stringify(jsonFacet),
    };

    const sid = GetSessionID();
    if (sid) {
        params.sid = sid;
    }

    let queryParams = {};
    if (searchText.startsWith('advSearch')) {
        const newSearchText = searchText.substring(10);
        // const basic_req = constructTextQuery(newSearchText);
        // console.log("basic_reg", basic_req);
        queryParams = {
            q: newSearchText,
        };
    } else {
        queryParams = constructTextQuery(searchText);
    }
    const filterParams = constructFilters(filters);

    params = { ...params, ...queryParams, ...filterParams };

    /*
    if (facetType === 'asset_count') {
        console.log('All params', params);
    } */
    //console.log("All params", params);

    const request = {
        adapter: jsonpAdapter,
        callbackParamName: 'json.wrf',
        url: solr_urls.assets,
        params: params,
    };

    const { data } = await axios.request(request);
    return data;
}

function getJsonFacet(
    facetType,
    offset,
    limit,
    buckets,
    sortField,
    sortDirection,
    facetSearch
) {
    return {
        ...((facetType === 'all' || facetType === 'asset_count') && {
            asset_count: {
                type: 'terms',
                field: 'asset_type',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                domain: { excludeTags: 'asset_type' },
                numBuckets: buckets,
                ...(facetSearch && {
                    domain: {
                        filter: `(asset_type:*${facetSearch}*)`,
                    },
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'related_subjects') && {
            related_subjects: {
                type: 'terms',
                field: 'kmapid_subjects_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'related_places') && {
            related_places: {
                type: 'terms',
                field: 'kmapid_places_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'related_terms') && {
            related_terms: {
                type: 'terms',
                field: 'kmapid_terms_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'feature_types') && {
            feature_types: {
                type: 'terms',
                field: 'feature_types_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'languages') && {
            languages: {
                type: 'terms',
                field: 'node_lang',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'collections') && {
            collections: {
                type: 'terms',
                field: 'collection_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'node_user') && {
            node_user: {
                type: 'terms',
                field: 'node_user_full_s',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'creator') && {
            creator: {
                type: 'terms',
                field: 'creator',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'perspective') && {
            perspective: {
                type: 'terms',
                field: 'perspectives_ss',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
        ...((facetType === 'all' || facetType === 'associated_subjects') && {
            associated_subjects: {
                type: 'terms',
                field: 'associated_subject_map_idfacet',
                limit,
                offset,
                sort: `${sortField} ${sortDirection}`,
                numBuckets: buckets,
                ...(facetSearch && {
                    prefix: capitalize(facetSearch),
                }),
            },
        }),
    };
}

/*
Replaced by same function in useSearch.js
// TODO: Refactor: parameterize basic_req to select which fields to search.
function constructTextQuery(searchString) {
    // there are similar allwords variables in SearchBuilder and useSearch
    const sswords = searchString?.toLowerCase().trim().split(' ');
    const allwords = sswords.join(' AND ');
    let searchstring = escapeSearchString(searchString || '');

    // console.log (JSON.stringify(state));
    let starts = searchstring.length ? `${searchstring}*` : '*';
    let search = searchstring.length ? `*${searchstring}*` : '*';
    let slashy = `${searchstring}/`;
    if (!searchString || searchstring.length === 0) {
        searchstring = search = slashy = '*';
    }
    let xact = `${searchstring}`;
    const lastword = sswords[sswords.length - 1];
    const partial = `"${sswords
        .slice(0, sswords.length - 1)
        .join(' ')}" ${lastword}*`;
    const proj = getProject();
    const proj_filter = proj ? ` AND projects_ss:${proj}` : ''; // Added at the end of string (not used?)

    /!*
        Note: These query parts must be kept consistent in three place:
            1. UseSearch.js ~line 295
            2. UseInfinitSearch.js ~line 321 (this file)
            3. SearchBuilder.js ~line 279
    *!/
    const qparts = [
        `(names:${xact}* AND asset_type:terms)^100`,
        `(text:"${xact}")^90`,
        `(text:${partial})^85`,
        `(text:${allwords})^80`,
    ];

    const basic_req = {
        // search: tweak for scoping later
        // Search string should match search string in useSearch.js line 271
        q: searchstring !== '*' ? qparts.join(' ') : `*:*`,
        xact,
        starts,
        search,
        slashy,
    };
    // console.log("basic reg", basic_req);
    return basic_req;
}
*/

function constructFilters(filters) {
    const projid = getProject(); // used twice below

    // If no filters are passed then we return the all the assets.
    if (_.isEmpty(filters)) {
        let fqs = [
            'asset_type:(audio-video images texts visuals sources subjects places terms collections)',
            '-(asset_type:terms AND related_uid_ss:subjects-9314)',
            // 'asset_type:(audio-video images texts visuals sources subjects places collections) OR (asset_type:terms AND related_uid_ss:subjects-9315)',
        ];
        if (projid) {
            fqs.push(`projects_ss:${projid}`);
        }
        return {
            fq: fqs,
        };
    }

    // console.log('constructFilters: received filters: ', filters);
    const hashedFilters = arrayToHash(filters, 'field');
    // console.log('constructFilters: sorted filters = ', hashedFilters);

    const facets = Object.keys(hashedFilters);
    // console.log('constructFilters: keys = ', facets);

    let fq_list = [];

    function constructFQs(facetData, fieldName) {
        let fq_list = [];
        let not_list = [];
        let and_list = [];
        let or_list = [];

        facetData.forEach((f) => {
            if (f.operator === 'NOT') {
                not_list.push(
                    '(*:* AND -' + fieldName + ':("' + f.match + '"))'
                );
            } else if (f.operator === 'AND') {
                and_list.push(
                    '(*:* AND ' + fieldName + ':("' + f.match + '"))'
                );
            } else {
                /* OR default case */
                or_list.push('(*:* OR ' + fieldName + ':("' + f.match + '"))');
            }
        });

        const or_clause =
            '{!tag=' +
            fieldName +
            '}' +
            fieldName +
            ':' +
            '(' +
            or_list.join(' ') +
            ')';

        // TODO: does the order matter?
        if (or_list.length) fq_list.push(or_clause);
        if (and_list.length) fq_list.push(...and_list);
        if (not_list.length) fq_list.push(...not_list);

        // console.log('constructFQs returning: ', fq_list);
        return fq_list;
    }

    // TODO: Refactor so that facets can be added via configuration.
    facets.forEach((facet) => {
        const facetData = hashedFilters[facet];
        let fqs = [];
        // console.log('constructFilters:\tfacet ' + facet + ' = ', facetData);
        switch (facet) {
            case 'asset_type':
                fqs = constructFQs(facetData, 'asset_type');
                fq_list.push(...fqs);
                break;
            case 'subjects':
                fqs = constructFQs(facetData, 'kmapid');
                fq_list.push(...fqs);
                break;
            case 'places':
                fqs = constructFQs(facetData, 'kmapid');
                fq_list.push(...fqs);
                break;
            case 'terms':
                fqs = constructFQs(facetData, 'kmapid');
                fq_list.push(...fqs);
                break;
            case 'languages':
                fqs = constructFQs(facetData, 'language');
                fq_list.push(...fqs);
                break;
            case 'feature_types':
                fqs = constructFQs(facetData, 'kmapid');
                fq_list.push(...fqs);
                break;
            case 'users':
                fqs = constructFQs(facetData, 'node_user_full_s');
                fq_list.push(...fqs);
                break;
            case 'creator':
                fqs = constructFQs(facetData, 'creator');
                fq_list.push(...fqs);
                break;
            case 'collections':
                fqs = constructFQs(facetData, 'collection_uid_s');
                fq_list.push(...fqs);
                break;
            case 'perspective':
                fqs = constructFQs(facetData, 'perspectives_ss');
                fq_list.push(...fqs);
                break;
            case 'associated_subjects':
                fqs = constructFQs(facetData, 'associated_subject_map_idfacet');
                fq_list.push(...fqs);
                break;
            default:
                console.error('UNHANDLED FACET TYPE: ' + facet);
                break;
        }
    });

    // Added by Than for project filtering
    if (projid) {
        fq_list.push(`projects_ss:${projid}`);
    }

    return { fq: fq_list };
}

function arrayToHash(array, keyField) {
    // console.log('received: ', array);
    if (!array) {
        array = [];
    }
    return array.reduce((collector, item) => {
        const key = item[keyField] || 'unknown key';
        if (!collector[key]) {
            collector[key] = [];
        }
        collector[key].push(item);
        return collector;
    }, {});
}

function escapeSearchString(str) {
    str = str.replace(/ /g, '\\ '); // escape spaces
    str = str.replace('(', '\\(');
    str = str.replace(')', '\\)');
    str = str.replace(':', '\\:');
    str = str.replace('+', '\\+');
    str = str.replace('-', '\\-');
    str = str.replace('"', '\\"');
    str = str.replace('?', '\\?');
    return str;
}
