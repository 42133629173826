import React, { useEffect, useRef, useState } from 'react';
import { usePager } from '../hooks/usePager';
import { getPagePath, nextPage, pageStrToObj } from '../common/catalogUtils';
import ReactImageMagnify from 'react-image-magnify';
import { useBiblStore } from '../hooks/useBiblStore';

export function TextReaderScans(props) {
    const edbibl = useBiblStore((state) => state.edBibl);
    const bibl = useBiblStore((state) => state.doc);
    const pager = usePager();
    const dragged = props?.dragged;

    const buildScanName = (vol, page, side) => `v${vol}p${page}${side || ''}`;
    const [initialPage, setInitialPage] = useState(
        buildScanName(...pager.getVolPgSide())
    );
    // TODO: Use pager.visible to update the Pager UI with the currently showing page
    const [pglist, setPageList] = useState([initialPage]);

    const [lastVisible, setLastVisible] = useState();

    const updateLastVisible = (lv) => {
        setLastVisible(lv);
        pager.updateVisible('add', lv);
    };

    // TODO: set a max number of loaded pages and remove from top as scrolling down

    // TODO: implement scrolling up for pages

    // Load 5 pages initially
    useEffect(() => {
        let newpglist = [initialPage];
        for (let n = 0; n < 4; n++) {
            const lastpage = initialPage;
            const pgobj = pageStrToObj(lastpage);
            [pgobj.page, pgobj.side] = nextPage(pgobj.page, pgobj.side);
            const nwpg = `v${pgobj.vol}p${pgobj.page}${pgobj.side}`;
            if (!newpglist.includes(nwpg)) {
                newpglist.push(`v${pgobj.vol}p${pgobj.page}${pgobj.side}`);
            }
        }
        //console.log("ed bibl", edbibl);
        setPageList(newpglist);
    }, [initialPage]);

    useEffect(() => {
        const newpg = buildScanName(...pager.getVolPgSide());
        if (newpg !== initialPage && !pglist?.includes(newpg)) {
            // console.log("pager changed setting new initial page", newpg);
            setInitialPage(newpg);
        }
    }, [pager]);

    // Load another page once the last one becomes visible
    useEffect(() => {
        if (!lastVisible) {
            return;
        }
        const pgobj = pageStrToObj(lastVisible);
        [pgobj.page, pgobj.side] = nextPage(pgobj.page, pgobj.side);
        const pgstr = `v${pgobj.vol}p${pgobj.page}${pgobj.side}`;
        let newpglist = [...pglist];
        if (!newpglist.includes(pgstr)) {
            newpglist.push(pgstr);
            if (newpglist.length > 20) {
                newpglist.shift();
            }
            setPageList(newpglist);
        }
    }, [lastVisible]);

    // console.log("bibl", bibl);

    // console.log("returning pglist", pglist);

    return (
        <div id="c-text-page-scans" className="c-text-view">
            {!bibl?.hasscans && <p>There are no scans for this text</p>}
            {bibl?.hasscans &&
                pglist.map((pg, pgi) => {
                    // console.log("pg in pglist", pg);
                    return (
                        <TextScan
                            key={`page-scan-${pg}-${pgi}`}
                            page={pg}
                            setVisible={updateLastVisible}
                            dragged={dragged}
                        />
                    );
                })}
        </div>
    );
}

/**
 * An individual page scan. Uses ref with observerCallback to determine if visible and when visible,
 * calls the load next page function, which adds the next page to the list of displayed pages.
 * @param page
 * @param getNext
 * @returns {JSX.Element|null}
 * @constructor
 */
function TextScan({ page, setVisible, dragged }) {
    // const getPagePath = useCatalog((state) => state.getPageImagePath);
    const edBibl = useBiblStore((state) => state.edBibl);
    const updateVisible = usePager((state) => state.updateVisible);
    const pageRef = useRef(null);

    const [magnify, setMagnify] = useState(false);

    // TODO: Fix implementation of zoom or find another package. Currently zoom if off centered if div expanded

    const observerCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setVisible(page); // AddPage adds the page list
            } else {
                updateVisible('remove', page);
            }
        });
    };

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
    };

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, options);
        if (pageRef.current) observer.observe(pageRef.current);
        return () => {
            if (pageRef.current) observer.unobserve(pageRef.current);
        };
    }, [pageRef, options]);

    /*
  useEffect(() => {
    // TODO: Figure out how to use this to refresh the Zoom component so the zoom looks proper
    console.log("dragged = ", dragged);
  }, [dragged]);
   */

    const pgobj = pageStrToObj(page);
    if (!pgobj) {
        console.warn('No page data in Text Scan component');
        return null;
    }

    const pgurl = getPagePath(edBibl, pgobj);
    const pginfo = (
        <>
            Volume {pgobj.vol}, Page {pgobj.page}
            {pgobj.side}
            <a href={pgurl} target="_blank">
                {' '}
                <span
                    className="icon shanticon-link-external"
                    title="View in separate window"
                >
                    {' '}
                </span>
            </a>
        </>
    );
    const toggleMe = (e) => {
        e.preventDefault();
        setMagnify(!magnify);
    };
    const imgLnk = (
        <a onClick={toggleMe} title="Click image to toggle zoom">
            <img src={pgurl} alt="page image" className="scan-img" />
        </a>
    );
    const imgComponent = magnify ? (
        <ZoomScan pgurl={pgurl} toggle={setMagnify} sclass="zoom-scan-img" />
    ) : (
        imgLnk
    );
    return (
        <div className="c-text-scan" ref={pageRef}>
            <div className="img-label">{pginfo}</div>
            <div className="img-wrapper">{imgComponent}</div>
            <hr />
        </div>
    );
}

function ZoomScan({ pgurl, toggle, sclass }) {
    return (
        <div className="zoom-scan">
            <a
                onClick={(e) => {
                    e.preventDefault();
                    toggle(false);
                }}
            >
                <ReactImageMagnify
                    {...{
                        smallImage: {
                            alt: 'page image',
                            isFluidWidth: true,
                            src: pgurl,
                            width: 1000,
                            height: 200,
                        },
                        largeImage: {
                            src: pgurl,
                            isFluidWidth: true,
                            width: 2500,
                            height: 500,
                        },
                        enlargedImagePosition: 'over',
                        enlargedImageClassName: 'zoom-lg-img',
                        shouldUsePositiveSpaceLens: true,
                    }}
                    className={sclass}
                />
            </a>
        </div>
    );
}
/*
height: 500,
 */
