import React from 'react';
import './ContentHeader.scss';
import { useKmap } from '../../hooks/useKmap';
import { queryID } from '../../views/common/utils';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import { AltContentHeader } from './AltContentHeader';
import { ContentHeaderBreadcrumbs } from './Breadcrumbs/ContentHeaderBreadcrumbs';
import { SimpleContentHeader } from './SimpleContentHeader';
import { CatalogContentHeader } from '../../catalog/common/CatalogContentHeader';

export function ContentHeader({ siteClass, title, location }) {
    // console.log('location', location, process.env.REACT_APP_STANDALONE);
    const pgpath = location.pathname.substr(1);
    const [first, mid, last, fourth] = pgpath?.split('/');
    const itemType = first;
    const isCollection = mid === 'collection';
    let itemId = isCollection ? last : mid;
    // No breadcrumbs for all of type pages in standalones
    if (process.env.REACT_APP_STANDALONE) {
        if (mid === 'all') {
            return null;
        }
    }

    if (itemType === 'catalog') {
        return <CatalogContentHeader coll={mid} ed={last} text={fourth} />;
    }

    // Need to return null if no ID before making kmap query, so separated off ContentHeaderBuilder
    if (!itemId || typeof itemId === 'undefined') {
        return (
            <header
                id="c-content__header__main"
                className="c-site__header legacy mandala all"
            >
                <div
                    id="c-content__header__main__wrap"
                    className="c-content__header__main__wrap"
                >
                    <h1 className="c-content__header__main__title"></h1>
                </div>
            </header>
        );
    }

    return (
        <ContentHeaderBuilder
            itemType={itemType}
            itemId={itemId}
            siteClass={siteClass}
            title={title}
            isCollection={isCollection}
        />
    );
}

function ContentHeaderBuilder({ itemType, itemId, siteClass, isCollection }) {
    const isAsset = [
        'audio-video',
        'av',
        'images',
        'sources',
        'texts',
        'visuals',
    ].includes(itemType.toLowerCase());
    const solrqtype = isAsset ? 'asset' : 'info';
    const qid = queryID(itemType, itemId);
    const {
        isLoading: isItemLoading,
        data: itemData,
        isError: isItemError,
        error: itemError,
    } = useKmap(qid, solrqtype);

    // some comment
    if (isItemLoading) {
        return (
            <MandalaSkeleton
                height={'1rem'}
                width={'100%'}
                color={'transparent'}
                marginTop={'-4rem'}
            />
        );
    }

    let mytitle = itemData?.header
        ? itemData.header
        : itemData?.title
        ? itemData.title[0]
        : 'No Title';

    if (
        window.location.pathname === '/collections' ||
        window.location.hash === '#/collections'
    ) {
        mytitle = 'All Collections';
    }

    // console.log('item data', queryID(queryType, itemId), itemData);
    // Handle an Error
    if (isItemError) {
        console.log(qid, itemError);
        return <div>There was a problem in the Content Header for {qid}!</div>;
    }

    // What to return if the SOLR query returned a hit
    if (itemData) {
        // console.log('item data before calling alt content header', itemData);
        // If nothing found
        if (itemData?.response?.numFound === 0 && itemId) {
            if (itemId === 'all') {
                return (
                    <SimpleContentHeader
                        label={itemType}
                        crumbs={['All items']}
                    />
                );
            }
            return (
                <AltContentHeader
                    domain={itemType}
                    kid={itemId}
                    siteClass={siteClass}
                    isCollection={isCollection}
                />
            );
        }

        if (itemType === 'search') {
            let srchstr = document.getElementById('sui-search').value;
            if (srchstr.length > 0) {
                srchstr = ` for “${srchstr}”`;
            }
            mytitle = `Search${srchstr}`;
        }
        const cheader = (
            <header
                id="c-content__header__main"
                className={`c-content__header__main legacy mandala ${itemType}`}
            >
                <div
                    id="c-content__header__main__wrap"
                    className="c-content__header__main__wrap legacy"
                >
                    <div className={'c-content__header__breadcrumb breadcrumb'}>
                        {itemType !== 'search' && (
                            <ContentHeaderBreadcrumbs
                                itemData={itemData}
                                itemTitle={mytitle}
                                itemType={itemType}
                            />
                        )}
                    </div>
                </div>
            </header>
        );
        return cheader;
    }

    // No hit in kmassets index so try kmterms
    return (
        <AltContentHeader
            domain={itemType}
            kid={itemId}
            siteClass={siteClass}
        />
    );
}
