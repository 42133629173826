import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import jsonpAdapter from '../common/axios-jsonp';
import { getNextInList, getPreviousInList } from '../common/catalogUtils';
import { useBiblStore } from './useBiblStore';

/**
 * UseInfiniteSection : for sections of marked up structured text
 *
 * @param sid
 * @param byPass
 * @returns {UseInfiniteQueryResult<boolean|{next: string, prev: string, page: JSX.Element}, unknown>}
 */
export function useInfiniteSection(sid, sids, byPass = false) {
    // console.log("in use inf sect", sid, sids);
    return useInfiniteQuery(
        [sid, sids],
        ({ pageParam = sid, textToc = sids }) => pagequery(pageParam, textToc),
        {
            byPass: byPass,
            getPreviousPageParam: (firstPage, sids) => firstPage.prev,
            getNextPageParam: (lastPage, sids) => lastPage.next,
        }
    );
}

/**
 * pagequery is the SOLR query function to retrieve text section records (type:textsection)
 *
 * Returns an object with:
 *  page: the text of the page
 *  next: the id of the next page or false
 *  prev: the id of the previous page or false
 *
 * @param bibsid
 * @param textToc
 * @returns {Promise<{next: (*|boolean|boolean), prev: (*|boolean|boolean), section: JSX.Element, sid}|boolean>}
 */
const pagequery = async (bibsid, textToc) => {
    const [biblId, sid] = bibsid?.split(':');
    if (!biblId || !sid) {
        return false;
    }

    let request = {
        url: process.env.REACT_APP_SOLR_URL,
        adapter: jsonpAdapter,
        callbackParamName: 'json.wrf',
        params: {
            q: `section_num_s:${sid}`,
            fq: ['type:textsection', `bibid_s:${biblId}`],
            wt: 'json',
        },
    };
    const reply = await axios.request(request).catch(function (e) {
        console.log('error', e);
    });
    let data = reply?.data;
    let secttext = false;
    if (data && data?.response && data.response?.numFound > 0) {
        const sdoc = data.response.docs[0];
        secttext = Object.keys(sdoc).includes('content_mu')
            ? sdoc['content_mu']
            : sdoc['content'];
    }
    if (Array.isArray(secttext)) {
        secttext = secttext[0];
    }
    // secttext = <HtmlCustom markup={secttext} key={`${textbibl?.id}-${sid}`} />; moved to TextSection
    const retdata = {
        section: secttext,
        bibsid: bibsid,
        sid: sid,
        textToc: textToc,
        tid: biblId,
        next: textToc ? getNextInList(bibsid, textToc) : false,
        prev: textToc ? getPreviousInList(bibsid, textToc) : false,
    };

    return retdata;
};
