// import React from 'react';
// import ISO6391 from 'iso-639-1';
// import { iso6393 } from 'iso-639-3';
import { iso639 } from './iso639data';

/**
 *
 * Iso639M is a custom Javascript Object that provides language utilities based on the ISO 639 language codes, written
 * for use with the Mandala React site:
 *      iso-639-1 : the 2-character language codes
 *      iso-639.3 : the 3-character language codes
 * It uses a custom data set of these character, iso639data.js, and provides the following functions:
 *      convertLangCode — converts a code of one length to the other length
 *      getLangNameFromCode — retrieves either the standard or native name for a language from either type of code
 *      getLangCodeFromName — returns the desired length code from the standard name give
 */
export const Iso639M = {
    /**
     * Converts a langcode form between iso-639-1 (2 character) and iso-639-2/3 (3-character) forms
     * @param lc
     * @returns {boolean|*}
     */
    convertLangCode: (lc) => {
        if (!Iso639M.langExists(lc)) {
            return lc; // doesn't exist as either a 2-char or 3-char form, just return it unchanged
        }
        if (lc?.length === 3) {
            // If it is an iso-639-2/3 form, convert to iso-639-1 form
            const l3data = Iso639M.get3Data(lc);
            const newlc = l3data?.iso1;
            return newlc?.length > 0 ? newlc : lc;
        } else {
            // Otherwise look up the data for lc as an iso-639-1 code and return the iso-639-2/3 form
            return iso639[lc].iso3;
        }
    },
    get3Data: (lc) => {
        for (let lckey in iso639) {
            let langdata = iso639[lckey];
            if (
                langdata?.iso3 === lc ||
                langdata?.iso3b === lc ||
                langdata?.iso3t === lc
            ) {
                return langdata;
            }
        }
        return false;
    },
    getAllCodes: () => {
        const allcodes = [];
        for (let lc in iso639) {
            allcodes.push(iso639[lc].iso1);
            allcodes.push(iso639[lc].iso3);
        }
        return allcodes;
    },
    getLangNameFromCode: (lc, useNative = false) => {
        lc = lc?.length === 3 ? this.convertLangCode(lc) : lc;
        const langdata = iso639[lc];
        const native = langdata.native ? langdata.native : langdata.name; // if no native, return regular name for native
        return useNative ? native : langdata.name;
    },
    getLangCodeFromName: (lname, codelen = 2) => {
        for (const k in iso639) {
            const ldata = iso639[k];
            if (ldata.name === lname || ldata.native === lname) {
                return codelen === 3 ? ldata.iso3 : ldata.iso1; // iso3 vs. iso1 refers to iso-639-3 and iso-639-1 not the number of characters
            }
        }
    },
    langExists: (lc) => {
        if (lc?.length === 3) {
            const langdata = Iso639M.get3Data(lc);
            return langdata ? true : false;
        } else {
            return lc in iso639;
        }
    },
};
