import React, { useEffect, useState, useContext } from 'react';
import TranslationForm from './TranslationForm';
import TranslationResults from './TranslationResults';
import { EwtsConverter } from './src/EwtsConverter.mjs';
import { useParser } from './useParser';
import ReactDOM from 'react-dom';
import './translation.scss';

/*
Test searches:
    * གཞི་ཚད་མེད་པའི་ཡེ་ཤེས་ཀྱི་འཁོར་ལོ་གསལ་བ་
 */
export default function TranslationTool(props) {
    let target = document.getElementById('translationTool');
    const [showResults, setShowResults] = useState(false);
    const [tibPhrase, setTibPhrase] = useState('');
    const ewts = new EwtsConverter();

    const getResults = (e) => {
        e.preventDefault();
        const inputtext = document.getElementById(
            'translationForm.tibetan'
        ).value;
        const tibin = ewts.to_unicode(inputtext);
        setTibPhrase(tibin);
        setShowResults(true);
    };

    // const fetch_options = {};

    const {
        isLoading: isParserLoading,
        data: parsedPhrase,
        isError: isParsingError,
        error: parseError,
    } = useParser(tibPhrase);

    const transtool = (
        <div id="transtool" className="container">
            <h1>Tibetan Translation Tool</h1>
            <TranslationForm
                submit={getResults}
                resultsVisible={setShowResults}
            />
            {showResults && <TranslationResults data={parsedPhrase} />}
        </div>
    );

    if (target instanceof HTMLElement) {
        return ReactDOM.createPortal(transtool, target);
    } else {
        return transtool;
    }
}
