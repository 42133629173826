import React, { useEffect, useState } from 'react';
import { DoxTocText } from './DoxTocText';
import { loadTexts } from '../../common/solrUtils';
import { useBiblStore } from '../../hooks/useBiblStore';
import { buildSigla } from '../../common/catalogUtils';
import { useCatSolr } from '../../hooks/useCatSolr';

export function DoxTocTextList({ doc }) {
    const { addTextsToCollToc } = useBiblStore((state) => state);
    const bibid = buildSigla(doc?.coll, doc?.edsig, '-');
    const tids = doc.texts_ss.map((tnum, ti) => {
        return `${bibid}${tnum}-bib`;
    });

    // console.log("tids", tids);
    const sstr = 'id:(' + tids.join(' ') + ')';

    let docTocChildQuery = {
        q: sstr,
        rows: 300,
        fl: '*, [child limit=1000]',
        sort: 'id asc',
        wt: 'json',
    };
    const {
        isLoading: isDoxTextsLoading,
        data: doxTexts,
        isError: isDoxCatError,
        error: doxCatError,
    } = useCatSolr(docTocChildQuery);
    /*
    useEffect(() => {
        if (!doc?.textbibls) {
            const prmis = loadTexts(doc);
            prmis.then((data) => {
                const docs = data?.docs;
                if (docs && docs?.length > 0) {
                    addTextsToCollToc(doc?.id, docs);
                    setTextBibls(docs);
                }
            });
        } else {
            setTextBibls(doc.textbibls);
        }
        // console.log(prmis);
    }, [doc]);

 */

    if (isDoxTextsLoading) {
        return null;
    }

    return (
        <ul className="textlist ml-0">
            {doxTexts?.docs?.map((tbibl, tn) => {
                return <DoxTocText tdoc={tbibl} key={`${doc.id}-text-${tn}`} />;
            })}
        </ul>
    );
}
