import React, { useEffect, useState } from 'react';
import { getHeaderForView } from '../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { MandalaPopover } from '../common/MandalaPopover';
import { HtmlCustom } from '../common/MandalaMarkup';
import { useView } from '../../hooks/useView';
import { usePerspective } from '../../hooks/usePerspective';
import { LeafChildren } from './LeafChildren';
import { useSolr } from '../../hooks/useSolr';
import MandalaSkeleton from '../common/MandalaSkeleton';

/**
 * A Leaf in a Kmap Tree. The data for the leaf is passed as a tree node.
 * Nodes are loaded either in the initial tree call or in the LeafChildren component.
 * When a leaf/node is opened, it loads the LeafChildren component which makes the call for all children to
 * save from having to do individual calls for each leaf, which leads to too many calls.
 *
 * @param node
 * @param withChild
 * @param isOpen
 * @param selPath
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */

export default function TreeLeaf({
    node,
    withChild = null,
    isOpen = false,
    selPath,
    ...props
}) {
    const leafRef = React.createRef(); // Reference to the Leaf's HTML element
    //const selPath = useStatus((state) => state.selPath);
    const settings = node?.tree?.settings;

    const perspectiveSetting = usePerspective(
        (state) => state[settings?.domain]
    );
    const [isOpenState, setIsOpen] = useState(isOpen);
    const [selected, setSelected] = useState(false);
    const viewSetting = useView((state) => state[settings?.domain]);

    // const perspective = settings?.perspective || perspectiveSetting;
    const doc = node?.doc;
    const tree = node?.tree;
    const [domain, kid] = doc?.uid?.split('-');

    /*
    if (withChild) {
        node.hasChildren = true;
    }
    const childrenLoaded = node?.hasChildren && node?.children?.length > 0; // node.children is originally set to null, not an array
    // above had: node?.domain === 'terms' ||
    const rowsToDo = 3000;

    // Find Children
    let childquery = {
        index: 'terms',
        params: {
            q: `${settings.level_field}:${node.level * 1 + 1}`,
            fq: [`tree:${node.domain}`, `${node.ancestor_field}:${node.kid}`],
            rows: rowsToDo,
            fl: '*',
        },
    };

    const do_filter = 'terms' !== node?.domain; // was true, but disabling for terms for Newari

    let {
        isLoading: areChildrenLoading,
        data: children,
        isError: isChildError,
        error: childError,
    } = useSolr(
        [domain, perspective, doc.uid, 'children'],
        childquery,
        childrenLoaded,
        do_filter
    ); // bypas if children already loaded

     */

    const scrollToMe = () => {
        const tree_el = document.getElementById(tree?.settings?.elid);
        const myid = `${tree?.settings?.elid}_leaf-${domain}-${kid}`;
        const me = document.getElementById(myid);
        if (tree_el && me) {
            window.scroll(0, 0);
            tree_el.scroll(0, me.offsetTop - window.screen.height * 0.4);
            tree_el.scrolled = true;
        }
    };

    useEffect(() => {
        if (tree?.selectedNode * 1 === node.kid * 1) {
            setTimeout(scrollToMe, 1000);
        }
    }, []);

    useEffect(() => {
        if (node?.domain === node?.tree?.selectedDomain) {
            if (selPath && tree?.selectedNode) {
                if (selPath?.includes(node.kid)) {
                    setIsOpen(true);
                }
                if (tree.selectedNode * 1 === node.kid * 1) {
                    setSelected(true);
                } else {
                    setSelected(false);
                }
            }
        }
    }, [selPath]);

    // New way for MANU-7590, query for current kmap at tree level and if same domain, pass down selPath to all leaves
    // TODO: Clean up. Compare with above. Is it necessary?
    useEffect(() => {
        if (selPath?.length > 0) {
            if (selPath.includes(node.kid)) {
                setIsOpen(true);
            }
            const lastitem = selPath[selPath.length - 1] * 1;
            if (node.kid * 1 === lastitem) {
                setSelected(true);
                setTimeout(scrollToMe, 500);
            } else {
                setSelected(false);
            }
        }
    }, [selPath]);

    /*
    if (areChildrenLoading) {
        return <MandalaSkeleton />;
    }

    if (!childrenLoaded) {
        if (children?.numFound > 0) {
            tree.parseData(children.docs);
        } else {
            node.hasChildren = false;
        }
    }

     */

    /*
    if (kid === '333902') {
        console.log("node", node);
        console.log("has children: " + node?.hasChildren);
        console.log("child query", childquery);
        // console.log("children", children);
        // console.log("perspective", perspective);
        // console.log("settings", settings);
    }*/

    if (tree.settings.showNode) {
        // hmm  does this work?
        if (
            !tree?.selPath?.includes(kid * 1) &&
            node.pid * 1 !== tree.selectedNode * 1
        ) {
            return null;
        }
    }

    if (props.showPathOnly && !selPath.includes(kid * 1)) {
        return null;
    }

    // Determine Icon for open or closed
    let icon = isOpenState ? (
        <FontAwesomeIcon icon={faMinusCircle} />
    ) : (
        <FontAwesomeIcon icon={faPlusCircle} />
    );
    let toggleclass = isOpenState ? 'leafopen' : 'leafclosed';

    // with No Children, replace icon with dash

    if (node.hasChildren === false) {
        icon = '';
        toggleclass = 'leafend';
    }

    // class value for tree leaf div
    let selclass = selected ? ' selected' : '';
    let divclass = `${settings.leafClass} lvl\-${node.level} ${toggleclass}${selclass}`;

    // Leaf click handler
    const handleClick = (e) => {
        setIsOpen(!isOpenState);
    };

    // Do not display if no header to display in tree or it is explicitly excluded in env vars
    const skipit =
        process?.env?.REACT_APP_KMAP_EXCLUDES &&
        process?.env?.REACT_APP_KMAP_EXCLUDES.split(',')?.includes(doc?.uid);
    if (!doc?.header || skipit) {
        return null;
    }

    // Define the child_content based on whether it is open or not (only loads children when open)
    // console.log(kid, selPath.at(-1));
    const filterByPath =
        kid * 1 === selPath.at(-1) ? false : props.showPathOnly; // When showing only path, must show children of last node

    let child_content = isOpenState ? (
        <LeafChildren
            node={node}
            selPath={selPath}
            showPathOnly={filterByPath}
        />
    ) : (
        <div className={settings.childrenClass} data-status="closed-leaf"></div>
    );

    // Get Header based on View Settings (see hook useView)
    const kmhead = getHeaderForView(doc, viewSetting);

    const nolink = props?.nolink || (domain === 'terms' && node.hasChildren);
    const myid = tree.settings.elid + `_leaf-${domain}-${kid}`;

    const leafclick = () => {
        tree.selectedNode = node.kid;
        tree.selPath = node.ancestor_path;
        const selels = document.querySelectorAll('.selected');
        selels.forEach((el) => {
            el.classList.remove('selected');
        });
        document.getElementById(myid).classList.add('selected');
    };
    const leafhead = nolink ? (
        <HtmlCustom markup={kmhead} />
    ) : (
        <Link to={'/' + doc?.id.replace('-', '/')} onClick={leafclick}>
            <HtmlCustom markup={kmhead} />
        </Link>
    );

    // Show popup only for terms that are expressions (9315), words (9668), or phrases (9669) or any other kmap type unless nolink is false
    // words (9668) and phrases (9669) were added to make English trees work
    let showpop =
        (doc?.associated_subject_ids?.includes(9315) ||
            doc?.associated_subject_ids?.includes(9668) ||
            doc?.associated_subject_ids?.includes(9669) ||
            domain !== 'terms') &&
        !props?.nolink;

    // return the div structure for a regular tree leaf
    // console.log("leafhead", leafhead);
    /*
    if (node.uid === 'terms-283080') {
        console.log("node in tree leaf", node, kmhead, viewSetting);
    }
 */

    return (
        <div id={myid} className={divclass} ref={leafRef}>
            <span
                className={settings.spanClass}
                data-domain={domain}
                data-id={kid}
            >
                <span className={settings.iconClass} onClick={handleClick}>
                    {icon}
                </span>
                <span className={settings.headerClass}>
                    {leafhead}
                    {showpop && <MandalaPopover domain={domain} kid={kid} />}
                </span>
            </span>
            {child_content}
        </div>
    );
}
