import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
    getLinkFromId,
    solrFieldValue,
    langcode,
} from '../common/catalogUtils';
import { DynamicTextToc } from '../reader/DynamicTextToc';
import { useBibl } from '../hooks/useBibl';
import { useBiblStore } from '../hooks/useBiblStore';
import { Link, useLocation } from 'react-router-dom';
import { SectionIcon } from '../common/SectionIcon';

export function BiblSections({ bibl }) {
    const secttype = bibl?.sections ? 'chapters' : 'dynamic';
    return (
        <div className={`c-bibl_toc ${secttype}`}>
            <h2>
                <SectionIcon />
                Table of Contents
            </h2>
            {bibl?.sections?.length > 0 && (
                <>
                    <BiblTextSection
                        key={`${bibl.id}-sections-front`}
                        type="front"
                        bibl={bibl}
                    />
                    <BiblTextSection
                        key={`${bibl.id}-sections-body`}
                        type="body"
                        bibl={bibl}
                    />
                    <BiblTextSection
                        key={`${bibl.id}-sections-back`}
                        type="back"
                        bibl={bibl}
                    />
                </>
            )}
        </div>
    );
}

function BiblTextSection({ type, bibl }) {
    if (!bibl?.sections) {
        return null;
    }
    // console.log("bibl sections", bibl.sections);
    const prefs = { front: 'a', body: 'b', back: 'c' };
    const pref = prefs[type];
    const sections = bibl.sections.filter((s) => {
        return s?.location_s === type || s?.section_num_s?.startsWith(pref);
    });

    // Column sizes
    const csz = {
        nm: 6,
        id: 2,
        pg: 4,
    };
    const chaphead = type === 'body' ? 'Title' : 'Section';
    if (sections.length === 0) {
        return null;
    }
    // console.log(sections[5]);
    let lang = langcode(bibl.lang);
    return (
        <Container className={`sections ${type}`}>
            <h3 className="text-capitalize row">{type}</h3>
            <Row key="bibl-row-head" className="headrow">
                <Col key="chapcol-name" sm={csz['nm']} className="h5">
                    {chaphead}
                </Col>
                <Col key="chapcol-id" sm={csz['id']} className="h5">
                    ID
                </Col>
                <Col key="chapcol-pagination" sm={csz['pg']} className="h5">
                    Pagination
                </Col>
            </Row>
            {sections.map((s, si) => {
                return (
                    <BiblTextSectionRow
                        key={`${bibl?.id}-section-toc-row-${si}`}
                        s={s}
                        si={si}
                        type={type}
                        csz={csz}
                        lang={lang}
                        bibl={bibl}
                    />
                );
            })}
        </Container>
    );
}

function BiblTextSectionRow({ s, si, type, csz, lang, bibl }) {
    const loc = useLocation();
    let title_lang = 'en';
    let chap_title = false;
    if (s?.label_s?.length > 0) {
        chap_title = s.label_s;
    } else if (s?.section_title) {
        chap_title = s.section_title;
    } else if (Array.isArray(s?.title) && s.title.length > 0) {
        chap_title = s.title[0];
    }

    if (type === 'body') {
        title_lang = s['lang'];
        chap_title = Array.isArray(s[`title_chap_${title_lang}`])
            ? s[`title_chap_${title_lang}`][0]
            : s[`title_chap_${title_lang}`];
        chap_title = solrFieldValue(chap_title);
        lang = langcode(title_lang);
    }

    let chap_id = s?.chapid_s ? s.chapid_s : false;
    /*  Replaced by pglnks below:
  let pgcol = false;
  if (s?.pagination_s?.length > 0) {
    let pgpts = s.pagination_s.split(":");
    pgcol = <Col sm={csz['pg']}>{pgpts[0]}</Col>;
    if (pgpts.length > 1) {
      pgcol = (
          <Col key={`pagecol-${si}`} sm={csz['pg']}>
            {pgpts[0].replace("v", "Vol. ")},&nbsp;
            {pgpts[1]?.split("-")[0]}
            {pgpts.length > 2 && <span>-{pgpts[2]}</span>}
          </Col>
      );
    }
  }


  // Do Sections from structured text
  if (!chap_title && !chap_id && !pgcol) {
    chap_title = s?.section_title_s || "Untitled";
    chap_id = s?.section_num_s || "n/a";
    pgcol = getLinkFromId(s.id);
  }

 */

    if (chap_title && chap_title.trim().charCodeAt(0) < 256) {
        lang = 'en';
    }

    // Turn pagination into links to view pages
    let pglnks = s?.pagination_s?.split('-')?.map((pg, pgi) => {
        let mtch = pg.match(/(v\d+:)?(\d+[ab]?)\.(\d+)/);
        if (mtch) {
            let vol = mtch[1]?.replace(/[:v]/g, '') || bibl?.vol_start_i;
            let lnk = loc.pathname + '/pages/' + vol + '/' + mtch[2];
            let txt = mtch[1] && pgi === 0 ? `v${vol}, ` : '';
            txt += mtch[2] + '.' + mtch[3];
            return (
                <span className={`toc-pgn-${pgi + 1}`} key={`pgi-${pgi}`}>
                    <Link to={lnk}>{txt}</Link>
                </span>
            );
        }
        return (
            <span className={`toc-pgn-${pgi + 1}`} key={`pgi-${pgi}`}>
                pg
            </span>
        );
    });

    return (
        <Row key={`bibl-row-${si}`}>
            <Col key={`labelcol-${si}`} sm={csz['nm']}>
                <span className={`${lang} chaptitle`}>{chap_title}</span>
            </Col>
            <Col key={`chapidcol-${si}`} sm={csz['id']}>
                {chap_id}
            </Col>
            <Col key={`pagecol-${si}`} sm={csz['pg']}>
                {pglnks}
            </Col>
        </Row>
    );
}
