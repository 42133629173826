import React from 'react';
import { useBiblStore } from '../hooks/useBiblStore';
import { zfill } from '../common/catalogUtils';
import { useBibl } from '../hooks/useBibl';
import THLSkeleton from '../common/THLSkeleton';
import BiblRecord from '../biblrecord/BiblRecord';

/**
 * DoxVolBiblDisplay : Display a vol bibl in a dox view
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function DoxVolBiblDisplay() {
    const bibl = useBiblStore((state) => state.doc);
    const mtch = bibl.id.match(/v(\d+)-/);
    const vnum = mtch ? mtch[1] : zfill(bibl?.pos_i, 3);
    const volbibid = `${bibl?.coll}-${bibl?.edsig}-v${vnum}`;
    const {
        isLoading: isBiblLoading,
        data: volbibl,
        isError: isBiblError,
        error: biblError,
    } = useBibl(volbibid);

    if (isBiblLoading) {
        return <THLSkeleton />;
    }
    // console.log("in dox vol display", volbibl);
    if (isBiblError) {
        console.log(`Problem loading vol bibl (${volbibid})`, biblError);
        return <p>Could not load volume bibl for {volbibid}.</p>;
    }
    return <BiblRecord customBibl={volbibl} />;
}
