import React, { useEffect, useState } from 'react';
import { useSearchStore } from '../hooks/useSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import { ResultItem } from './ResultItem';
import { FeaturePager } from '../../views/common/FeaturePager/FeaturePager';
import { Link } from 'react-router-dom';
import { enterForClick } from '../../views/common/utils';

/**
 * Performs the search based on the query and other parameters in the SearchStore and returns the results
 * which is a list of records that have Bibl IDs.
 * These are generally texts, but could be volumes, doxcats, etc.
 * The hits within a particular bibid item are combined into a single hitlist using json.facets
 * combining results into unlimited buckets based on bibid_s field.
 * The hitlist is displayed as a toggle div below the bibid records title.
 *
 * Other components used to create the results page are below:
 *      ResultItem
 *      HitSummary
 *      HitList
 *
 * As well as the function getHit(hit, page, qry)
 *
 * @param facets
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchResults() {
    // facets = { type: 'Record Type', coll: 'Collection', edsig: 'Edition' },
    const search = useSearchStore();
    const { query, start, buckets, numPerPage } = search;

    //const [srchquery, setSrchQuery] = useState(query);
    const [showBuckets, setShowBuckets] = useState([]);
    const [spgnum, setSearchPageNum] = useState(0);
    const [openAll, setOpenAll] = useState(false);

    const isQuery = query && query?.length > 0;
    // const [startNum, setStartNum] = useState(1);

    const qstr = isQuery ? `text:"${query}"` : '*:*';

    //const [hitBibls, setHitBibls] = useState([]);
    const qobj = {
        q: qstr,
        fl: `*,[child limit=5000]`,
        /*fq: [`-(pg_trans_bo:${qry} AND -pg_bo:${qry})`],*/
        facet: true,
        'facet.field': Object.keys(search.facetTypes),
        'json.facet': [
            '{"texthits": {"type": "terms", "field": "bibid_s", "limit":-1 }}',
            '{"type": {"type":"terms", "field":"type","limit":-1,"mincount":0}}',
            '{"coll": {"type":"terms", "field":"coll","limit":-1,"mincount":0,"facet":{"eds":{"type":"terms","field":"edsig","limit":-1}}}}',
        ],
    };

    // Add User Chosen Facets to Query
    if (search?.hasFilters()) {
        const newfq = [];
        for (const [key, value] of Object.entries(search.filters)) {
            if (value.length > 0) {
                newfq.push(key + ':(' + value.join(' OR ') + ')');
            }
        }
        qobj.fq = newfq;
    }

    // console.log("loading search", qobj);

    const {
        isLoading: isSearchLoading,
        data: searchres,
        isError: isSearchError,
        error: searchError,
    } = useCatSolr(qobj);

    /*
    useEffect(() => {
        if (!query) {
            setSrchQuery('*');
        } else {
            setSrchQuery(query);
        }
    });

 */

    useEffect(() => {
        if (!isSearchLoading && !isSearchError) {
            search.setSolrQuery(qobj);
            // search.setFacetTypes(facets);
            search.setResults(searchres);
            if (!search?.facetChange) {
                search.setInitialFacets(searchres?.facets);
            }
        }
    }, [searchres]);

    useEffect(() => {
        if (Array.isArray(buckets)) {
            let endNum = start + numPerPage;
            if (isQuery) {
                endNum += 1;
            }
            const newbuckets = buckets.slice(start, endNum);
            setShowBuckets(newbuckets);
        }
    }, [start, buckets, numPerPage]);

    if (isSearchLoading) {
        return <THLSkeleton />;
    }

    if (isSearchError) {
        console.log('Search error', searchError);
    }
    // console.log('search results', searchres);

    const setSearchPerPage = (ppn) => {
        if (search?.setNumPerPage) {
            search.setNumPerPage(ppn);
        }
    };

    const setSearchPage = (pg) => {
        const newpg = parseInt(pg);
        const numpgs = buckets?.length / numPerPage;
        if (newpg > -1 && newpg <= numpgs) {
            const newStart = numPerPage * newpg;
            setSearchPageNum(newpg);
            search.setStart(newStart);
        }
    };

    const toggleOpen = (e) => {
        e.preventDefault();
        setOpenAll(true);
    };
    const toggleClosed = (e) => {
        e.preventDefault();
        setOpenAll(false);
    };

    const querydisplay =
        search?.query.replace(/\"/g, '')?.length > 0 ? (
            <>“{search?.query.replace(/\"/g, '')}”</>
        ) : (
            ' Everything'
        );
    return (
        <div id="srch-results">
            <div className="l-search_head">
                <h1>
                    Search on {querydisplay}{' '}
                    {/*<Link
                        to={'/catalog'}
                        onClick={search?.reset}
                        className="backlink"
                    >
                        New Search
                    </Link>{' '} */}
                </h1>
                {isQuery && (
                    <div className="toggles">
                        <a onClick={toggleOpen} onKeyUp={enterForClick}>
                            Expand All
                        </a>{' '}
                        | {` `}
                        <a onClick={toggleClosed} onKeyUp={enterForClick}>
                            Collapse All
                        </a>
                    </div>
                )}
            </div>
            {/* When there are results found */}
            {search?.numFound > 0 && (
                <>
                    <FeaturePager
                        viewMode="list"
                        assetCount={search.numFound}
                        itemCount={buckets?.length}
                        perPage={numPerPage}
                        totalPages={search?.totalPages}
                        page={spgnum}
                        setPerPage={setSearchPerPage}
                        setPage={setSearchPage}
                    />
                    <ol className="results" start={start + 1}>
                        {' '}
                        {/* list-unstyled */}
                        {showBuckets?.map((doc, di) => {
                            return (
                                <ResultItem
                                    key={['result', di]}
                                    bibid={doc?.val}
                                    count={doc?.count}
                                    open={openAll}
                                />
                            );
                        })}
                    </ol>
                </>
            )}

            {/* When there are no results */}
            {search?.numFound === 0 && <NoResults />}
        </div>
    );
}

function NoResults() {
    const query = useSearchStore((state) => state?.query);
    return (
        <div className="no-results">
            <p>No results were found for your query: {query}</p>
        </div>
    );
}
