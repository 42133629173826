import React, { useEffect, useState, useContext, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Col } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import './translation.scss';
import axios from 'axios';
import { getDictionaryOrder } from '../Terms/TermDictionaries/TermDictionaries';
import TranslationEquivNewar from './TranslationEquivNewar';
import TranslationEquivalent from './TranslationEquivalent';

export default function NewarTranslate(props) {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [dorder, setDOrder] = useState({});

    // const resultDiv = useRef();

    const portalId = 'newar-trans';
    const portalEl = document.getElementById(portalId);

    useEffect(() => {
        getDictionaryOrder().then((data) => setDOrder(data));
    }, []);

    const nsubmit = (e) => {
        console.log('nsubmit');
        e.preventDefault();
        setLoading(true);
        const inputtext = document
            .getElementById('translationForm.newar')
            .value.trim();
        console.log('INput text: ' + inputtext);

        /*
        const request = {
            url: 'http://localhost/wp-json/mandala/v1/parsenewar',
            method: 'get',
            adapter: jsonpAdapter,
            callbackParamName: 'json.wrf',
            params: {newar: inputtext},
            responseType: 'jsonp'
        };
        axios(request).then((res) => {
            console.log("result in axios", res);
            if (res?.data?.words?.length > 0) {
                setResults(res.data.words);
            }
        }).finally(() => {
            setLoading(false);
        });*/

        axios
            .get('/wp-json/mandala/v1/parsenewar?newar=' + inputtext)
            .then((res) => {
                // const jdata = JSON.stringify();
                if (res?.data?.words?.length > 0) {
                    console.log('words are: ', res.data.words);
                    setResults(res.data.words);
                }
            })
            .finally(() => {
                setLoading(false);
            });
        return false;
    };

    const ntt = (
        <div id="newar-transtool">
            <NewarTranslationForm submit={nsubmit} resultsVisible={true} />
            <div id="newar-results">
                {loading && (
                    <Spinner animation="border" role="status">
                        <span className="invisible">Loading...</span>
                    </Spinner>
                )}
                <div className="container">
                    {results?.length > 0 &&
                        results.map((rs, ri) => {
                            return <TranslationEquivNewar lookup={rs} />;
                        })}
                </div>
            </div>
        </div>
    );

    if (portalEl) {
        return createPortal(ntt, portalEl);
    }
    return null;
}

export function NewarTranslationForm({ submit, resultsVisible }) {
    const checkForEnter = (e) => {
        if (e?.which === 13 && !e.shiftKey) {
            e.preventDefault();
            submit(e);
        }
    };
    return (
        <Form id="nt-form" className="transtool-form" onSubmit={submit}>
            <Form.Row>
                <Col xs={8}>
                    <Form.Group
                        className="mb-3"
                        controlId="translationForm.newar"
                    >
                        <Form.Control
                            as="textarea"
                            className="sa sanskrit"
                            rows={5}
                            onKeyDown={checkForEnter}
                            placeholder={`Enter a Newar word or phrase in Devanagari.`}
                        />
                    </Form.Group>
                    <Form.Group className="btns">
                        <Button
                            variant="primary"
                            type="submit"
                            className="submit"
                            onClick={(e) => {
                                console.log('button clicked', e);
                            }}
                        >
                            Translate
                        </Button>
                        <Button
                            variant="primary"
                            type="reset"
                            className="reset"
                            onClick={() => {
                                resultsVisible(false);
                            }}
                        >
                            Reset
                        </Button>
                    </Form.Group>
                </Col>
                <Col>
                    <TranslationInstructions />
                </Col>
            </Form.Row>
        </Form>
    );
}

function TranslationInstructions(props) {
    return (
        <div className="tt-instruct">
            <h5>Instructions</h5>
            <p className="small muted">
                Enter a Newar phrase in Devanagari, and press "Translate".
            </p>
        </div>
    );
}

/*
 A list of individual Newars
                words, each with a list of definitions, will appear below. Click
                on the word itself to see its full entry in the dictionary.
                Click on a particular definition to choose that one and collapse
                the rest. Click on the{' '}
                <span className="icon shanticon-cancel-circle"></span> at the
                far right margin to remove the whole row from the list.
 */
