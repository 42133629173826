import {
    getFieldsByString,
    getSolrVal,
    solrFieldValue,
    capitalize,
    langcode,
    langNameFromCode,
} from '../common/catalogUtils';
import React, { useState } from 'react';
import { useBiblStore } from '../hooks/useBiblStore';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/all';
import { Link } from 'react-router-dom';
import { SectionIcon } from '../common/SectionIcon';

export function BiblIdInfo({ bibl }) {
    // console.log(bibl);

    // Return the id info div
    return (
        <div className="c-bibl_idinfo">
            <h2>
                <SectionIcon />
                Identification Information
            </h2>
            <ul className="list-unstyled">
                <BiblIdColl bibl={bibl} />
                <BiblIdEd bibl={bibl} />
                <BiblIdText bibl={bibl} />
                <BiblIdMasterId bibl={bibl} />
                <BiblIdOtherEds bibl={bibl} />
            </ul>
        </div>
    );
}

function BiblIdColl({ bibl }) {
    const edBibl = useBiblStore((state) => state.edBibl);
    const [showTitles, setShowTitles] = useState(false);
    let edLang = edBibl?.langcode;
    if (!edLang && edBibl?.lang) {
        edLang = langcode(getSolrVal(edBibl.lang));
    }
    let collsig = edBibl?.coll;
    let collnames = edBibl?.titles;
    if (collnames) {
        if (!Array.isArray(collnames)) {
            collnames = [collnames];
        }
    } else {
        const edTitles = edBibl._childDocuments_.filter((cd) => {
            return cd?.subtype_title_s === 'collection';
        });
        if (edTitles?.length > 0) {
            collnames = edTitles;
        }
    }

    let mtind = -1;
    let main_title = collnames.filter((nm, ni) => {
        if (getSolrVal(nm?.lang) === edLang) {
            mtind = ni;
            return true;
        }
        return false;
    });
    if (main_title?.length > 0) {
        main_title = main_title[0][`title_${edLang}`];
    } else {
        main_title = collnames[0]?.title[0];
    }
    if (!main_title || main_title?.length === 0) {
        main_title = 'Untitled';
    }

    const othertitles = collnames?.map((cname, cnmi) => {
        if (cname?.lang && cnmi !== mtind) {
            const langs = Array.isArray(cname.lang) ? cname.lang : [cname.lang];
            return langs.map((nlang, li) => {
                let title = cname['title_' + nlang];
                const lang_name = langNameFromCode(nlang);
                return (
                    <li key={`${collsig}-name-${nlang}-${li}`}>
                        <span className={nlang}>{title}</span> ({lang_name}){' '}
                    </li>
                );
            });
        }
    });

    const toggleTitles = () => {
        setShowTitles(!showTitles);
    };

    const icon = showTitles ? <GoTriangleDown /> : <GoTriangleRight />;

    const othertitlelink =
        collnames?.length > 1 ? (
            <span className="expand">
                <a onClick={toggleTitles} title="Show other collection titles">
                    {icon}
                </a>
            </span>
        ) : null;
    return (
        <li key="bibl-id-coll">
            <label>Collection</label>
            <span className={`value ${edLang}`}>{main_title} </span>
            <span className="en">({langNameFromCode(edLang)}) </span>
            {othertitlelink}
            {showTitles && (
                <ul>
                    {othertitles}
                    <li key={`${collsig}-name-abbr`}>{bibl['coll']} (abbr)</li>
                </ul>
            )}
        </li>
    );
}

function BiblIdEd({ bibl }) {
    const edLang = bibl.langcode;
    const [showTitles, setShowTitles] = useState(false);
    if (bibl?.edsig === 'main') {
        return null;
    }
    const edfields = getFieldsByString(bibl, 'ed', 'pref');
    let mainkey = -1;
    const ed_names = edfields?.map((efnm, efnmi) => {
        let langcode = efnm?.replace('ed_', '');
        if (efnm === 'ed') {
            langcode = 'en';
        } else if (efnm === 'edsig') {
            langcode = 'abbr';
        }
        const langnm = langNameFromCode(langcode);
        if (langcode === edLang) {
            mainkey = efnmi;
        }
        return {
            lang: langcode,
            langname: langnm,
            name: bibl[efnm],
        };
    });
    const keypref = `bibl-${bibl.id}-ednm-`;
    if (mainkey === -1) {
        mainkey = 0;
    }
    const mnm = ed_names[mainkey];
    const other_names = ed_names
        .filter((ednm, edni) => {
            return edni !== mainkey;
        })
        .map((ednm, edni) => {
            const cnm = ednm?.lang + ' text-capitalize';
            return (
                <li key={`${keypref}${edni}`}>
                    <span className={cnm}>{ednm?.name} </span>{' '}
                    <span className="en">({ednm?.langname})</span>
                </li>
            );
        });
    /*


    {ed_names[mainkey].name}

    <ul className="mb-2">
        <li key={`${keypref}engl-main`}>
          {bibl["ed"]}
          {" (English)"}
        </li>
        {ed_names}
        <li key={`${keypref}sigla`}>
          {bibl["edsig"]}
          {" (abbr)"}
        </li>
      </ul>
   */

    const toggleTitles = () => {
        setShowTitles(!showTitles);
    };

    const icon = showTitles ? <GoTriangleDown /> : <GoTriangleRight />;

    const othertitlelink =
        ed_names?.length > 1 ? (
            <span className="expand">
                <a onClick={toggleTitles} title="Show other edition titles">
                    {icon}
                </a>
            </span>
        ) : null;
    return (
        <li key="bibl-id-ed">
            <label>Edition</label>
            <span className={mnm?.lang}>{mnm?.name} </span>
            <span className="en">({mnm?.langname})</span>
            {othertitlelink}
            {showTitles && <ul>{other_names}</ul>}
        </li>
    );
}

function BiblIdText({ bibl }) {
    return (
        <li key="bibl-id-text">
            <label>{bibl.subtype} ID</label>
            <span>{bibl?.display_id}</span>
        </li>
    );
}

function BiblIdMasterId({ bibl }) {
    // Master ID info
    // In KT, masterid has valuse like fmpdb:0004 so just display 0004
    let masterid = solrFieldValue(bibl['id_master_ss']);
    let link = false;
    if (!masterid) {
        return null;
    }
    if (masterid.includes(':')) {
        masterid = masterid.split(':')[1] * 1;
    } else if (masterid.includes('-')) {
        link = `/catalog/${masterid.replace(/-/g, '/')}`;
        masterid = masterid
            .split('-')
            .map((pt, pti) => {
                return capitalize(pt);
            })
            .join('.');
    }
    return (
        <li key="bibl-id-catnum">
            <label>Master Catalog Number</label>
            {!link && <span>{masterid}</span>}
            {link && (
                <Link to={link}>
                    <span>{masterid}</span>
                </Link>
            )}
        </li>
    );
}

function BiblIdOtherEds({ bibl }) {
    const [show, setShow] = useState(false);
    // Other Ed Information
    const othercats =
        typeof bibl['alt_ed_ss'] !== 'undefined' ? bibl['alt_ed_ss'] : false;
    if (!othercats || othercats.length === 0) {
        return null;
    }

    const toggleme = () => {
        setShow(!show);
    };

    const icon = show ? <GoTriangleDown /> : <GoTriangleRight />;
    return (
        <li key="bibl-id-other-eds">
            <label>Other Editions</label> <a onClick={toggleme}>{icon}</a>
            {show && (
                <ul>
                    {othercats.map((oed, oen) => {
                        return <li key={`other-ed-${oen}`}>{oed}</li>;
                    })}
                </ul>
            )}
        </li>
    );
}
