import React, { useEffect, useState } from 'react';
import { useSearchStore } from '../hooks/useSearchStore';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import { Link } from 'react-router-dom';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/all';
import HitsSummary from './HitSummary';
import HitList from './HitList';
import { HighlightTitleHit } from './HighlightTitleHit';

/**
 * Displays a single result
 * @param bucket
 * @constructor
 */
export function ResultItem({ bibid, count, open }) {
    const search = useSearchStore();
    const setIsNew = search.setIsNew;
    const [isOpen, setOpen] = useState(open);
    const noQuery = search.getQuery() === '';
    const qobj = {
        q: `id:${bibid}`,
        fl: `*,[child limit=5000]`,
    };
    const {
        isLoading: isSearchLoading,
        data: results,
        isError: isSearchError,
        error: searchError,
    } = useCatSolr(qobj);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    if (isSearchLoading) {
        return <THLSkeleton />;
    }
    if (isSearchError) {
        return <li>Error: ${searchError}</li>;
    }
    if (!results?.docs?.length > 0) {
        console.warn(`${bibid} not found for search results!`);
        return null;
    }

    // console.log(bibid);
    // console.log(search);

    let doc = results.docs[0];
    let header = (
        <>
            {doc?.display_id} ({bibid}): <HitsSummary bibid={bibid} />
        </>
    );
    let qstr = search.getQuery();
    if (doc?.subtype === 'volume') {
        let title = doc?.titles?.title
            ? doc?.titles?.title[0]
            : doc?.titles[0]?.title[0];
        if (!title || title?.length === 0) {
            title = <span className="en">"Not found"</span>;
        } else {
            title = <span className="en">{title}</span>;
        }
        header = (
            <>
                {doc?.display_id}: {title} ({count} hits:{' '}
                <HitsSummary bibid={bibid} />)
            </>
        );
    } else if (doc?.subtype === 'text') {
        let ttitle = doc?.titles[0]?.title[0];
        if (!ttitle) {
            ttitle = doc?.titles[0]?.title[1]
                ? doc?.titles[0]?.title[1]
                : 'Untitled';
        }
        const idlink = (
            <Link
                to={`${doc?.coll}/${doc?.edsig}/${doc?.text_num}`}
                onClick={() => {
                    setIsNew(false);
                }}
            >
                {doc?.display_id}
            </Link>
        );
        const title = (
            <span className="bo">
                <HighlightTitleHit title={ttitle} sstr={qstr} />
            </span>
        );
        // console.log("count in text hit", count);
        header = (
            <>
                {idlink}: {title} ({count} hits: <HitsSummary bibid={bibid} />)
            </>
        );
    }
    const item_type = doc?.subtype === 'volume' ? 'vol' : doc?.subtype;

    const icon = isOpen ? (
        <GoTriangleDown className="toggle-icon down" />
    ) : (
        <GoTriangleRight className="toggle-icon right" />
    );

    const hits = isOpen ? <HitList bibid={bibid} /> : null;

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    return (
        <li className={item_type}>
            {!noQuery && <a onClick={toggleOpen}>{icon}</a>}
            {header} <span className="d-none">[{bibid}]</span>
            {hits}
        </li>
    );
}
