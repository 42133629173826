/**
 * KTree: JS Class for holding tree data used by the KmapTree component.
 *
 * The KmapTree component makes a solr call for the first two levels of the tree, faceting on the perspective-based
 * ancestor id. It then instantiates this KTree object, which first processes the facets and creates a list of
 * node ids that have children (i.e. with a facet count over 2, since every leaf is in its own ancestor path).
 * Then it parses the children data in order of levels and placing each child leaf node under its parent to
 * create the tree hiearchy. This list of nodes with children is used to determine if a node has children prior
 * to them being loaded to determine whether it can be "opened" or not.
 *
 * If a node is clicked on or is in the selected path (selpath), then it will be opened which calls the LeafChildren
 * component. This component makes a single call for all the children with the same faceting. That call is
 * processed in the same way as above: facets first, then child data, placing them in the tree. The LeafChildren
 * component then display the child leaves.
 *
 * All leaves have a property, selPath, which is an array of integers that represent the selected path.
 * This is updated whenever a new selection is made via clicking or route (i.e. url) change so that the tree will
 * automatically open to the new selected node, because every leaf node will reload itself and open if it is
 * in the path and the selected node will highlight itself. (This however does not close already open nodes.)
 *
 */

import jsonpAdapter from '../../logic/axios-jsonp';
import axios from 'axios';
import { getSolrUrls } from '../../hooks/utils';
import { useRouteMatch } from 'react-router-dom';

export class KTree {
    constructor(domain, perspective, data, facets, settings, parse = true) {
        this.id = `${domain}:${perspective}`;
        this.domain = domain;
        this.perspective = perspective;
        this.data = data;
        this.settings = settings;
        this.level_field = settings.level_field;
        this.sort_field = settings.sort_field;
        // console.log('sort field', this.sort_field);
        this.ancestor_field = settings.ancestor_field;
        this.selectedNode = settings.selectedNode;
        this.selectedDomain = settings.selectedDomain;
        this.selPath = [];
        this.selLoaded = false;
        getSelNode(this)
            .then((sp) => {
                this.selPath = sp;
            })
            .finally(() => {
                this.selLoaded = true;
            });
        if (!(this?.level_field && this?.sort_field && this?.ancestor_field)) {
            console.log(
                'Warning: level, sort or ancestor fields not defined in tree',
                settings
            );
        }
        this.nodes = {}; // flat associative array by kmap id to quickly find nodes
        // Data needs to be a list of first level nodes in order with arrays of childnodes
        this.trunk = []; // List of first level leaf nodes
        this.scrolled = false;
        // Make a list of all nodes that have children
        this.facets = facets; // children facets to determine if the kmap has a child, based on ancestor id
        // console.log("facets in tree", this.facets);
        this.hasChildren = [];
        this.addChildren(this.facets);
        // this.facets
        if (parse) {
            this.parseData(this.data); // Parse data into trunk nodes with children lists
        } else {
            this.trunk = data;
        }
    }

    addChildren(facets) {
        if (facets) {
            for (const [ky, val] of Object.entries(facets)) {
                if (val > 1 && !this.hasChildren.includes(ky)) {
                    this.hasChildren.push(this.getUid(ky));
                }
            }
        }
    }

    parseData(data, facets = null) {
        // console.log("paring data in kTRF", data, facets);
        // Get levels that exist in array
        this.addChildren(facets);
        let lvls = data.map((d, di) => {
            return d[this.level_field] * 1;
        });
        lvls = Array.from(new Set(lvls)); // Remove dupolicates
        lvls.sort(function (a, b) {
            return a - b;
        });
        // Iterate through levels
        for (let n in lvls) {
            let lvl = lvls[n]; // Level number as integer
            // lvldata is list of all nodes in this level
            let lvldata = data.filter((it, iti) => {
                return it[this.level_field] === lvl;
            });
            lvldata.sort(sortBy(this.sort_field)); // sort them by sort field
            // Iterate through nodes in this level
            for (let i in lvldata) {
                let doc = lvldata[i]; // get the doc with this index (i)
                if (!doc?.uid && doc?.id) {
                    doc.uid = doc.id;
                } // normalize id to uid
                // Make sure it hasn't already been processed before
                if (!this.findNode(doc.uid)) {
                    const hasChildren = this.hasChildren.includes(doc.id);
                    let nd = new TreeNode(doc, this, hasChildren); // Create a tree node for it (see below for class)
                    // console.log("new node", nd);
                    // If first level, push onto trunk list
                    if (lvl === 1) {
                        this.trunk.push(nd);
                    } else {
                        // Otherwise look for parent and add it as child
                        let parent = nd?.getParent();
                        if (parent) {
                            parent.add(nd);
                        }
                    }
                    // console.log("setting node", doc.uid, nd);
                    this.nodes[doc.uid] = nd; // In either case add to flat node list for accessing
                    nd.setHasChildren(hasChildren);
                } else {
                    // console.log(`node already exists: ${doc.uid}`, this.nodes[doc.uid]);
                }
            }
        }
    }

    isNode(id) {
        return !!this.findNode(id);
    }

    getSelectedNode() {
        const sid = `${this.domain}-${this.selectedNode}`;
        const snd = this.findNode(sid);
        if (snd) {
            return snd;
        }
        return false;
    }

    getUid(id) {
        return `${this.domain}-${id}`;
    }

    /**
     * Can take either a real uid (domain-id) or just a numeric id and it will add the tree's domain
     * @param uid
     * @returns {boolean}
     */
    findNode(uid) {
        if (!isNaN(uid)) {
            uid = this.getUid(uid);
        }
        let node = false;
        if (Object.keys(this.nodes).includes(uid)) {
            node = this.nodes[uid];
        }
        return node;
    }

    scrollToNode(uid) {
        let suid = `${uid}`;
        if (!suid.includes(this.domain)) {
            uid = `${this.domain}-${suid}`;
        }
        const tree_el = document.getElementById(this.settings?.elid);
        const myid = `leaf-${uid}`;
        const me = document.getElementById(myid);
        if (tree_el && me) {
            window.scroll(0, 0);
            tree_el.scroll(0, me.offsetTop - window.screen.height * 0.4);
        }
    }

    setChildren(childdata) {
        const ids = Object.keys(childdata);
        ids.forEach((id, idi) => {
            let cuid = `${this.domain}-${id}`;
            let parent = this.findNode(cuid);
            if (parent) {
                parent.hasChildren = true;
            }
        });
    }
}

export class TreeNode {
    constructor(doc, tree, hasChildren = false) {
        this.uid = doc?.uid;
        this.domain = tree?.domain;
        this.kid = this.uid;
        if (typeof this.uid === 'string' && this.uid.includes('-')) {
            let uidpts = this.uid.split('-');
            this.domain = uidpts[0];
            this.kid = uidpts[1];
        }
        if (!isNaN(this.kid * 1)) {
            this.kid = this.kid * 1;
        }
        this.doc = doc;
        this.tree = tree; // The Tree class object above, not it's property "tree"
        this.hasChildren = hasChildren;
        this.ancestor_field = false; // set in getAncestorPath below
        this.ancestor_path = this.getAncestorPath();
        this.pid = 0;
        if (
            Array.isArray(this.ancestor_path) &&
            this.ancestor_path.length > 1
        ) {
            this.pid = this.ancestor_path[this.ancestor_path.length - 2];
        }
        this.level = this.getLevel();
        this.hasChildren = null; // null means it hasn't been tested whether it has children yet or not (boolean)
        if (this?.domain === 'terms') {
            if (
                tree.perspective.includes('tib') &&
                this.ancestor_path?.length === 3
            ) {
                this.hasChildren = false;
            } else if (this.ancestor_path?.length === 2) {
                this.hasChildren = false;
            }
        }
        this.children = [];
        this.sorted = false;
    }

    getParent() {
        if (this.pid) {
            return this.tree.findNode(`${this.domain}-${this.pid}`);
        }
        return false;
    }

    getAncestorPath() {
        let ap = false;
        for (let n in this.tree.ancestor_field) {
            let af = this.tree.ancestor_field[n];
            if (Object.keys(this.doc).includes(af)) {
                this.ancestor_field = af;
                ap = this.doc[af];
                break;
            }
        }
        return ap;
    }

    getChildren() {
        if (!this.sorted) {
            this.children.sort(sortBy(this.tree.settings.sort_field));
            this.sorted = true;
        }
        return this.children;
    }

    getLevel() {
        const lvlfld = this.tree.settings.level_field;
        let lvl = this.doc[lvlfld];
        return lvl || !!lvl; // return lvl or convert undefined to boolean
    }

    isSelNode() {
        return this.kid * 1 === this.tree.settings.selectedNode * 1;
    }

    isSelParent() {
        return (
            this.tree.selPath.indexOf(this.kid) === this.tree.selPath.length - 2
        );
    }

    setHasChildren(hasChildren) {
        this.hasChildren = hasChildren;
    }

    add(child) {
        if (!this.children?.includes(child)) {
            this.children.push(child);
            this.children.sort(sortBy(this.tree.sort_field));
            this.hasChildren = true;
        }
    }

    sortChildren() {
        this.children.sort(sortBy(this.tree.sort_field));
    }
}

function sortBy(srtfld) {
    return function sortfunc(a, b) {
        let sa = a?.doc ? a.doc : a;
        let sb = b?.doc ? b.doc : b;
        if (sa[srtfld] > sb[srtfld]) {
            return 1;
        }
        if (sb[srtfld] > sa[srtfld]) {
            return -1;
        }
        return 0;
    };
}

async function getSelNode(tree) {
    const solrurls = getSolrUrls();
    const ancfield = tree.ancestor_field[0];
    const ancclosest = ancfield.replace(
        'ancestor_ids_',
        'ancestor_ids_closest_'
    );
    const qry = `uid:${tree.domain}-${tree?.selectedNode}`;
    // Make request
    const request = {
        adapter: jsonpAdapter,
        callbackParamName: 'json.wrf',
        url: solrurls['terms'],
        params: { q: qry, fl: ['uid', ancfield, ancclosest], wt: 'json' },
    };
    const { data } = await axios.request(request);
    let retdata =
        data && data.response?.docs?.length > 0 ? data.response.docs[0] : data;
    if (retdata[ancclosest]) {
        const anclist = retdata[ancclosest];
        tree.selectedNode = anclist[anclist.length - 2];
        return anclist;
    }
    return retdata[ancfield];
}
