import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSolr } from '../hooks/useSolr';
import MandalaSkeleton from './common/MandalaSkeleton';
import { FeatureCollection } from './common/FeatureCollection';
import './Kmaps/kmaps_shared.scss';
import { getProject } from './common/utils';
import { Link } from 'react-router-dom';
import AssetHomeCollection from './common/AssetHomeCollection';

export function PlacesHome(props) {
    return (
        <div className={'assethome places'}>
            <div className={'desc'}>
                <h1>Places</h1>
            </div>
            <div className={'c-asset-collection'}>
                <AssetHomeCollection asset_type={'places'} />
            </div>
        </div>
    );
}

export default PlacesHome;
