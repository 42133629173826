import React from 'react';
import { useCatalog } from '../hooks/useCatalog';
import { useCatSolr } from '../hooks/useCatSolr';
import THLSkeleton from '../common/THLSkeleton';
import { useProxy } from '../hooks/useProxy';
import ReactHtmlParser from 'react-html-parser';
import { getFieldsByString, langcode } from '../common/catalogUtils';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useBiblStore } from '../hooks/useBiblStore';
import { HtmlCustom } from '../common/HtmlCustom';
import DoxVolBiblDisplay from './DoxVolDisplay';

export function DoxRecord() {
    return (
        <div className="c-dox-record">
            <DoxBibl />
        </div>
    );
}

function DoxBibl() {
    const bibl = useBiblStore((state) => state.doc); // Bibl State
    if (!bibl || typeof bibl !== 'object') {
        return null;
    }
    // console.log("Bibl in dox bib", bibl);
    let titles = getFieldsByString(bibl, 'title_', 'starts');
    titles = titles.filter((t) => {
        return !t.includes('_alt');
    });
    const doxchildren =
        bibl.children_ss?.length > 0
            ? bibl.children_ss.map((chld, ci) => {
                  return <DoxLink doxId={chld} />;
              })
            : null;
    const volrange =
        bibl.st_vol === bibl?.end_vol || !bibl?.end_vol
            ? bibl.st_vol
            : `${bibl.st_vol} - ${bibl.end_vol}`;
    const total_volumes = bibl?.end_vol - bibl?.st_vol + 1;
    const textrange =
        bibl.st_text_i === bibl?.end_text_i || !bibl?.end_text_i
            ? bibl.st_text_i
            : `${bibl.st_text_i} - ${bibl.end_text_i}`;
    const total_texts = bibl?.end_text_i
        ? bibl?.end_text_i - bibl?.st_text_i + 1
        : 1;
    if (bibl?.subtype === 'volume') {
        return <DoxVolBiblDisplay />;
    }
    return (
        <Container>
            <h5 id="l-doxbib-header" className="row mt-0">
                <Col className="m-0 pt-0 pb-0 pl-3">
                    {titles.map((tfn, i) => {
                        let tlang = tfn.split('_').pop();
                        tlang = langcode(tlang);
                        if (bibl[tfn]?.length === 0 || bibl[tfn][0] === '') {
                            return null;
                        }
                        return (
                            <Row className="m-0" key={`dox-bibl-row-${i}`}>
                                {i === 0 && (
                                    <span className="en my-auto pr-1">
                                        {bibl?.path_id_s}:
                                    </span>
                                )}
                                <span className={tlang}>{bibl[tfn]}</span>{' '}
                                <span className="p my-auto pl-1">
                                    {' '}
                                    ({tlang})
                                </span>{' '}
                                {i < tfn.length - 1 && <br />}
                            </Row>
                        );
                    })}
                    <hr />
                </Col>
            </h5>

            {bibl?.url && <DoxDesc />}

            <Row>
                <h2>Bibliographic Information</h2>
            </Row>
            <Row>
                <label>ID</label> {bibl.id}
            </Row>
            <Row>
                <label>Volumes</label> {total_volumes} ({volrange})
            </Row>
            <Row>
                <label>Texts</label> {total_texts} ({textrange})
            </Row>
            {bibl?.parent_s && (
                <Row>
                    <label>Parent</label>{' '}
                    <DoxLink doxId={bibl.parent_s} lang={'eng'} />
                </Row>
            )}
            {doxchildren && (
                <>
                    <Row>
                        <label>Immediate Children</label>{' '}
                    </Row>
                    <Row>
                        <ul>
                            {doxchildren.map((chld, ci) => {
                                return (
                                    <li key={`dox-bibl-child-${ci}`}>{chld}</li>
                                );
                            })}
                        </ul>
                    </Row>
                </>
            )}
        </Container>
    );
}

/**
 * Creates a link for a doxographical category in the Dox TOC
 *
 * @param doxId
 * @param lang
 * @returns {JSX.Element|null}
 * @constructor
 */
function DoxLink({ doxId, lang }) {
    const query = {
        q: `id:${doxId}`,
        fl: `*,[child limit=1000]`,
    };
    const {
        isLoading: isDoxLoading,
        data: doxResult,
        isError: isDoxError,
        error: doxError,
    } = useCatSolr(query);
    if (!isDoxLoading && !isDoxError && doxResult?.numFound > 0) {
        const doxBibl = doxResult?.docs[0];
        lang = lang || doxBibl?.lang;
        let tlang = lang ? langcode(lang, 2) : '';
        tlang = `title_${tlang}` in doxBibl ? tlang : 'en';
        let title_fld = `title_${tlang}`;
        let dtitle =
            title_fld in doxBibl
                ? doxBibl[title_fld]
                : doxBibl?.title?.length > 0
                ? doxBibl[0]
                : 'Untitled';
        return (
            <Link
                to={`/catalog/${doxBibl.coll}/${doxBibl.edsig}/dox/${doxBibl.id}`}
            >
                {doxBibl?.path_id_s}. {dtitle}
            </Link>
        );
    }
    return null;
}

function DoxDesc() {
    const bibl = useBiblStore((state) => state.doc); // Bibl State
    const url = bibl?.url;

    const {
        isLoading: isDescLoading,
        data: doxDesc,
        isError: isDescError,
        error: descError,
    } = useProxy(url);
    if (isDescLoading) {
        return <THLSkeleton />;
    }

    // Grab just the page content between to <body> </body> tags
    let markup = false;
    let pageParts = doxDesc?.data?.split('<body>');
    if (pageParts && pageParts?.length > 1) {
        pageParts = pageParts[1].split('</body>');
        markup = pageParts[0];
    }

    // Return error if call doesn't work or no content
    if (!markup || isDescError) {
        console.error(`Unable to retrieve: ${url}`);
        return null;
    }
    let css = null;
    if (doxDesc?.css?.length > 0) {
        css = (
            <link
                media="all"
                rel="stylesheet"
                type="text/css"
                href={doxDesc.css[0]}
            />
        );
    }
    // Return markup as React Components (removing links)
    markup = markup.replace(/<\/?a[^>]*>/g, '');
    return (
        <div className="c-dox-desc">
            <HtmlCustom markup={markup} />
            {css}
        </div>
    );
}
