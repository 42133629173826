import React from 'react';

import './HistoryViewer.css';

import { Link, useParams } from 'react-router-dom';
import { useRecentSearch } from '../../hooks/useRecentSearch';
import RecentSearchItem from './RecentSearchItem';
import { stringify } from 'query-string';
import { SearchBuilder } from '../AdvancedSearch/SearchBuilder';

export function SearchViewer(props) {
    //const history = useContext(HistoryContext);
    let searches = props.searches;

    const removeSearchPage = useRecentSearch((state) => state.removeSearchPage);

    if (!searches || searches.length === 0) {
        return null;
    }

    return (
        <div className="c-HistoryViewer">
            {searches &&
                searches.map((search, index) => {
                    // console.log("Old Search", search);
                    let { searchText, filters, data } = search;
                    const srchBuilder = new SearchBuilder(data);
                    const srchDesc = srchBuilder.describeQuery();
                    return (
                        <div
                            className="c-HistoryViewer__relatedRecentItem"
                            key={`${stringify(searchText)}_${index}`}
                        >
                            <span className="c-HistoryViewer__title">
                                <Link to={`/search/recent/${index}`}>
                                    <RecentSearchItem
                                        searchText={srchDesc}
                                        filters={filters}
                                    />
                                </Link>
                            </span>
                            <span
                                className="c-HistoryViewer__removeItem u-icon__cancel-circle icon"
                                alt="Remove from list"
                                aria-label="Remove from list"
                                data-search-text={searchText}
                                onClick={(event) => {
                                    const searchID =
                                        event.target.getAttribute(
                                            'data-search-text'
                                        );
                                    removeSearchPage(searchID);
                                }}
                            >
                                {' '}
                            </span>
                        </div>
                    );
                })}
        </div>
    );
}

export default SearchViewer;
