/**
 * General implementation of ReactHTMLParser that allows one to call it with a custom options including a custom
 * transformer or no options. Does not process Mandala Popovers.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Apparatus } from './Apparatus';
import { getNow } from './catalogUtils';

export function HtmlCustom(props) {
    let htmlInput = props?.markup ? props.markup : '<div></div>';
    const options = props.options
        ? props.options
        : { transform: catalogTransform };
    if (options && !options.decodeEntities) {
        options.decodeEntities = true;
    }
    if (htmlInput.includes('xml:lang')) {
        const srchst = /xml:lang="[^"]+"/g;
        htmlInput = htmlInput.replace(srchst, '');
        htmlInput = htmlInput.trim();
    }
    if (htmlInput[0] !== '<' && !props.nop) {
        return <div>{ReactHtmlParser(htmlInput, options)}</div>;
    }
    return <>{ReactHtmlParser(htmlInput, options)}</>;
}

function catalogTransform(node) {
    if (node.type === 'text') {
        // Convert any inner html entities, e.g. &x0FAB etc., to Unicode text
        const div = document.createElement('div');
        div.innerHTML = node.data;
        node.data = div.innerText;
        div.remove();
    }
    if (node.type === 'tag' && node.name === 'var') {
        //return <span><strong>APP ITEM: {node?.children[0].data}</strong></span>;
        let dataitems = node?.children[0].data.split('$');
        if (node?.children[0].data.includes('needs')) {
            console.log(dataitems);
        }
        dataitems = dataitems.map((dtxt) => {
            let dpts = dtxt.split('|');
            return {
                type: dpts?.length > 0 ? dpts[0] : '',
                wit: dpts?.length > 1 ? dpts[1] : '',
                text: dpts?.length > 2 ? dpts[2] : '',
            };
        });
        const ntm = getNow();
        const rnd = Math.floor(Math.random() * 10000);
        // console.log("app node", node?.valueOf());
        return (
            <Apparatus key={`apparatus-${ntm}-${rnd}`} readings={dataitems} />
        );
    }
}
