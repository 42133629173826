import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { stringify } from 'query-string';
import {
    useQueryParams,
    StringParam,
    withDefault,
    encodeQueryParams,
} from 'use-query-params';
import { SearchBuilder } from './SearchBuilder';
import { ArrayOfObjectsParam } from '../../hooks/utils';
import './AdvancedSearch.scss';
import { useAdvancedSearch } from '../../hooks/useAdvancedSearch';
import { FormRowTemplate } from './FormRowTemplate';
import AdvancedSearchFormRow from './AdvancedSearchFormRow';
import { FIRST } from './SearchConstants';

const SEARCH_PATH = '/search/:view';
let searchview = 'list';

/**
 * Advanced Search Form component displays the advanced search form. It uses the useAdvancedSearch state variable "rows"
 * to keep track of the row data. Each row is an instance of the class FormRowTempate that contains the information about
 * a row including its index number (order in the list of rows). This object is passed to the AdvancedSearchFormRow component
 * which renders it. The AdvancedSearchFormRow is only defined by its index number in the list of rows.
 * The component itself accesses the state variable rows and used that index to get its data and if the
 * row is changed, it will update the state correspondingly. This allows for such actions as  preventing a
 * resource-type field from being added twice and impossible combinations of rows.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AdvancedSearchForm = () => {
    const history = useHistory();
    const rows = useAdvancedSearch((state) => state.rows);
    const updateRows = useAdvancedSearch((state) => state.updateRows);

    // This tells us whether we are viewing the search results
    // so that we can give a link to go there (or not).
    const searchView = useRouteMatch(SEARCH_PATH);
    const [query, setQuery] = useQueryParams({
        searchText: StringParam,
        filters: withDefault(ArrayOfObjectsParam, []),
    });
    let { searchText: search, filters } = query;

    const makeNewRow = (rws) => {
        return [...rws, new FormRowTemplate(rws.length)];
    };

    useEffect(() => {
        if (rows?.length === 0) {
            const newRows = makeNewRow(rows);
            updateRows(newRows);
        }
    }, [rows]);

    const addRow = (e) => {
        e.preventDefault();
        const newRows = makeNewRow(rows);
        updateRows(newRows);
    };

    const deleteRow = (rown) => {
        // remove row of index rown and readjust the n values
        const newrows = [...rows.toSpliced(rown, 1)];
        updateRows(newrows);
    };

    const submitForm = (e) => {
        e.preventDefault();
        const SB = new SearchBuilder(rows);
        const newqry = SB.buildQuery();
        document.getElementById('advanced-search-tree-toggle').click();

        // If no search view (searchView) is given in the path/route, need to set "searchview" in hash
        if (!searchView) {
            const atype = SB.getAssetType();
            if (atype) {
                searchview = getResultView(atype);
            }
            const encodedQuery = encodeQueryParams(
                {
                    searchText: StringParam,
                    filters: withDefault(ArrayOfObjectsParam, []),
                },
                {
                    searchText: `advSearch:${newqry}`,
                    filters: [...filters],
                }
            );
            if (process.env.REACT_APP_STANDALONE === 'standalone') {
                window.location.hash = `#/search/${searchview}?${stringify(
                    encodedQuery
                )}`;
            } else {
                //history.push('/search/deck');
                history.push({
                    pathname: `/search/` + searchview,
                    search: `?${stringify(encodedQuery)}`,
                });
            }
        } else {
            // When searchView is in path/route, just do the search
            // console.log('newqry is', newqry);
            setQuery(
                {
                    searchText: `advSearch:${newqry}`,
                    filters: [...filters],
                },
                'push'
            );
        }
    };

    const clearForm = (e) => {
        e.preventDefault();
        updateRows([]);
    };

    return (
        <div className="advanced-search-wrap">
            <form id="mdl-advsearch-form" className="search-form">
                <div className="mb-3">
                    <h4 className="title">Advanced Search</h4>
                    <div className="query-rows">
                        <div className="query-rows-wrapper">
                            {rows?.map((rw, rwi) => {
                                return (
                                    <AdvancedSearchFormRow
                                        key={`asfr-${rwi}`}
                                        n={rwi}
                                        row={rw}
                                        deleteMe={deleteRow}
                                        submit={submitForm}
                                    />
                                );
                            })}
                            <div className="add-btns">
                                <button
                                    className="btn btn-secondary btn-lg rounded"
                                    onClick={addRow}
                                >
                                    Add Row
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4"></hr>
                <div className="action-btns">
                    <button
                        className="btn btn-primary btn-lg m-2"
                        onClick={submitForm}
                    >
                        Submit
                    </button>
                    ||
                    <button
                        className="btn btn-outline-primary btn-lg m-2"
                        onClick={clearForm}
                    >
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
};

export const getResultView = (assettype) => {
    let searchview = false;
    switch (assettype) {
        case 'images':
            searchview = 'gallery';
            break;
        case 'collections':
        case 'audio-video':
            searchview = 'deck';
            break;
        default:
            searchview = 'list';
    }
    return searchview;
};

export default AdvancedSearchForm;
