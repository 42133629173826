import React, { useEffect, useState } from 'react';
import AssetHomeCollection from '../common/AssetHomeCollection';

export function TermsHome(props) {
    return (
        <div className={'assethome terms'}>
            <div className={'desc'}>
                <h1>Terms</h1>
            </div>
            <div className={'c-asset-collection'}>
                <AssetHomeCollection asset_type={'terms'} />
            </div>
        </div>
    );
}

export default TermsHome;
