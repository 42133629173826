import React, { useEffect, useState } from 'react';
import { EwtsConverter } from './src/EwtsConverter.mjs';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default function TibEWTSConverter(props) {
    const [convType, setConvType] = useState('wy2tib');
    const ewts = new EwtsConverter();
    const input_holder_bo = 'འདིར་སྒྱུར་རྒྱུའི་ཡིག་འབྲུ་བྲིས།';
    const input_holder_eng = 'Enter text to be converted here.';
    const results_holder_bo = 'འབྲས་བུ་འདིར་ནི་འབྱུང་བར་འགྱུར།';
    const results_holder_eng = 'Results will appear here.';

    const handleSelectChange = (e) => {
        setConvType(e.target.value);
        setFonts(e.target.value);
    };

    function setFonts(convtype) {
        const convinput = document.getElementById('conv-input');
        const convresult = document.getElementById('conv-results');
        switch (convtype) {
            case 'wy2tib':
                convinput.classList.remove('bo');
                convinput.placeholder = input_holder_eng;
                convresult.classList.add('bo');
                convresult.placeholder = results_holder_bo;
                break;
            case 'tib2wy':
            case 'tib2phon':
                convinput.classList.add('bo');
                convinput.placeholder = input_holder_bo;
                convresult.classList.remove('bo');
                convresult.placeholder = results_holder_eng;
                break;
        }
    }

    function submit(e) {
        e.preventDefault();
        const inputtext = document.getElementById('conv-input').value;
        let tibconv = '';
        switch (convType) {
            case 'wy2tib':
                tibconv = ewts.to_unicode(inputtext);
                break;
            case 'tib2wy':
                tibconv = ewts.to_ewts(inputtext);
        }
        document.getElementById('ewts-results').innerText = tibconv;
    }

    useEffect(() => {
        setFonts('wy2tib');
    }, []);

    return (
        <div id="ewts-converter" className="container tib-convert-form">
            <h2>Convert between Transliteration and Unicode</h2>
            <Form id="ewts-form" onSubmit={submit}>
                <Form.Group className="btns">
                    <Form.Control
                        as="select"
                        className="convtype"
                        aria-label="Select Conversion type"
                        onChange={handleSelectChange}
                    >
                        <option value="wy2tib">
                            Extended Wylie to Tibetan
                        </option>
                        <option value="tib2wy">
                            Tibetan to Extended Wylie
                        </option>
                    </Form.Control>
                    <Button variant="primary" type="submit" className="submit">
                        Convert
                    </Button>
                    <Button variant="primary" type="reset" className="reset">
                        Reset
                    </Button>
                </Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                id="conv-input"
                                placeholder={`Enter Extended Wylie Transliteration Here`}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
            <div className="result-wrap">
                <h3>Results</h3>
                <div id="ewts-results">
                    <Form.Control
                        as="textarea"
                        id="conv-results"
                        rows={3}
                        placeholder={`Results will appear here.`}
                    />
                </div>
            </div>
        </div>
    );
}
