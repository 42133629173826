import React, { useEffect } from 'react';
import { BiblAgents, getBiblAgents } from './BiblAgents';
import { BiblColophon } from './BiblColophon';
import { getFieldsByString } from '../common/catalogUtils';
import { SectionIcon } from '../common/SectionIcon';

export function BiblPubstmt({ bibl }) {
    const info = bibl['publication_stmts'];
    if (!info) {
        return null;
    }
    const content = Object.keys(info)
        .map((k, ki) => {
            if (k.includes('info_')) {
                return (
                    <BiblPubInfo key={`bpi-${ki}`} name={k} value={info[k]} />
                );
            }
            return null;
        })
        .filter((item, itn) => {
            return item !== null;
        });
    return (
        <div className="c-bibl_pubstmt">
            <h2>
                <SectionIcon />
                Publication Statement
            </h2>
            <ul className="list-unstyled">{content}</ul>
        </div>
    );
}

function BiblPubInfo({ name, value }) {
    let nmpts = name.split('_');
    let label = nmpts?.length > 1 ? <label>{nmpts[1]}</label> : '';
    let content =
        nmpts?.length > 2 ? (
            <span className={nmpts[2]}>{value}</span>
        ) : (
            <span>{value}</span>
        );
    return (
        <li>
            {label} {content}
        </li>
    );
}
