import React, { useEffect, useState } from 'react';
import './Kmaps/kmaps_shared.scss';
import AssetHomeCollection from './common/AssetHomeCollection';

export function SubjectsHome(props) {
    return (
        <div className={'assethome subjects'}>
            <div className={'desc'}>
                <h1>Subjects</h1>
            </div>
            <div className={'c-asset-collection'}>
                <AssetHomeCollection asset_type={'subjects'} />
            </div>
        </div>
    );
}

export default SubjectsHome;
