import jsonpAdapter from './axios-jsonp';
import axios from 'axios';
import { buildBiblId } from './catalogUtils';

export function loadDox(coll, ed) {
    // console.log("loading texts for ", doc);
    coll = coll && coll?.length > 0 ? coll : 'kt';
    ed = ed && ed?.length > 0 ? ed : 'd';
    const query = `type:doxcat AND coll:${coll} AND edsig:${ed}`;
    let toc_params = {
        q: query,
        rows: 1000,
        fl: '*,[child limit=1000]',
        sort: 'pos_i asc',
        wt: 'json',
    };
    const querySolr = async (paramsin) => {
        // console.log("params", paramsin);
        let request = {
            url: process.env.REACT_APP_SOLR_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: 'json.wrf',
            headers: {
                'Content-Type': null,
            },
            params: paramsin,
            transformResponse: function (data) {
                let newdata = data && data.response ? data.response : data;
                return newdata;
            },
        };
        // params["wt"] = "json";  // This does nothing???
        const { data } = await axios.request(request).catch(function (e) {
            console.error('error', e);
        });

        return data;
    };
    return querySolr(toc_params);
}

export function loadDoxPath(bibl, maxTexts = 1118) {
    // console.log("loading texts for ", doc);
    const coll = bibl?.coll;
    const ed = bibl?.edsig;
    const tnum = bibl?.text_num;
    const query = `st_text_i:[1 TO ${tnum}] AND end_text_i:[${tnum} TO ${maxTexts}]`;
    let toc_params = {
        q: query,
        fq: [`id:${coll}-${ed}-*`, 'type:doxcat'],
        rows: 1000,
        fl: 'id',
        sort: 'level_i asc',
        wt: 'json',
    };
    const querySolr = async (paramsin) => {
        let request = {
            url: process.env.REACT_APP_SOLR_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: 'json.wrf',
            headers: {
                'Content-Type': null,
            },
            params: paramsin,
            transformResponse: function (data) {
                return data && data.response ? data.response : data;
            },
        };
        // params["wt"] = "json";  // This does nothing???
        const { data } = await axios.request(request).catch(function (e) {
            console.error('error', e);
        });

        return data;
    };
    return querySolr(toc_params);
}

export function loadTexts(doc) {
    // console.log("loading texts for ", doc);
    const ids = doc?.texts_ss
        ?.map((tid) => {
            const tnum = tid.toString().padStart(4, '0');
            return `${doc?.coll}-${doc?.edsig}-${tnum}-bib`;
        })
        .join(' ');
    let toc_params = {
        q: `id:(${ids})`,
        rows: 1000,
        fl: '*,[child limit=1000]',
        sort: 'text_num asc',
        wt: 'json',
    };
    const querySolr = async (paramsin) => {
        let request = {
            url: process.env.REACT_APP_SOLR_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: 'json.wrf',
            headers: {
                'Content-Type': null,
            },
            params: paramsin,
            transformResponse: function (data) {
                let newdata = data && data.response ? data.response : data;
                return newdata;
            },
        };
        // params["wt"] = "json";  // This does nothing???
        const { data } = await axios.request(request).catch(function (e) {
            console.error('error', e);
        });

        if (request?.params?.start) {
            console.log('data', data);
        }

        return data;
    };
    return querySolr(toc_params);
}

export function loadBibl(coll, ed, itemid, bibtype = 'text') {
    const biblid =
        bibtype === 'dox' ? itemid : buildBiblId(coll, ed, itemid, bibtype);
    // console.log("biblid built", biblid);
    const query = `id:${biblid}`;

    let toc_params = {
        q: query,
        rows: 1000,
        fl: '*,[child limit=1000]',
        sort: 'pos_i asc',
        wt: 'json',
    };
    const querySolr = async (paramsin) => {
        // console.log("params", paramsin);
        let request = {
            url: process.env.REACT_APP_SOLR_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: 'json.wrf',
            headers: {
                'Content-Type': null,
            },
            params: paramsin,
            transformResponse: function (data) {
                let newdata = data && data.response ? data.response : data;
                return newdata;
            },
        };
        // params["wt"] = "json";  // This does nothing???
        const { data } = await axios.request(request).catch(function (e) {
            console.error('error', e);
        });

        return data;
    };
    return querySolr(toc_params);
}
