import React, { useEffect, useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Col } from 'react-bootstrap';

export default function TranslationForm({ submit, resultsVisible }) {
    const checkForEnter = (e) => {
        if (e?.which === 13 && !e.shiftKey) {
            e.preventDefault();
            submit(e);
        }
    };
    return (
        <Form id="tt-form" className="transtool-form" onSubmit={submit}>
            <Form.Row>
                <Col xs={8}>
                    <Form.Group
                        className="mb-3"
                        controlId="translationForm.tibetan"
                    >
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onKeyDown={checkForEnter}
                            placeholder={`Enter a Tibetan word or phrase in either Unicode Tibetan or Wylie transliteration.`}
                        />
                    </Form.Group>
                    <Form.Group className="btns">
                        <Button
                            variant="primary"
                            type="submit"
                            className="submit"
                        >
                            Translate
                        </Button>
                        <Button
                            variant="primary"
                            type="reset"
                            className="reset"
                            onClick={() => {
                                resultsVisible(false);
                            }}
                        >
                            Reset
                        </Button>
                    </Form.Group>
                </Col>
                <Col>
                    <TranslationInstructions />
                </Col>
            </Form.Row>
        </Form>
    );
}

function TranslationInstructions(props) {
    return (
        <div className="tt-instruct">
            <h2>Instructions</h2>
            <p>
                Enter a Tibetan word or phrase in either Unicode Tibetan or
                Wylie transliteration, and press "Translate". A list of Tibetan
                words, each with a list of definitions, will appear below. Click
                on the word itself to see its full entry in the dictionary.
                Click on a particular definition to choose that one and collapse
                the rest. Click on the{' '}
                <span className="icon shanticon-cancel-circle"></span> at the
                far right margin to remove the whole row from the list.
            </p>
        </div>
    );
}
