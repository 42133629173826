import React from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // ES6
import { useAdvancedSearch } from '../../hooks/useAdvancedSearch';
import * as SC from '../AdvancedSearch/SearchConstants';
import { v4 as uid } from 'uuid';

export function FeatureSearchFilters() {
    const history = useHistory();
    const storedRows = useAdvancedSearch((state) => state.rows);

    const entries = storedRows.map((entry) => {
        let entryValue = '';
        if (
            [SC.RESOURCE_TYPE, SC.CREATE_DATE, SC.ENTRY_DATE].includes(
                parseInt(entry.field)
            )
        ) {
            entryValue = parseInt(entry.scope)
                ? SC.scopeLookup[entry.scope]
                : entry.scope;
        } else {
            entryValue = entry?.value || entry?.text;
        }
        let eid = entry?.id || uid();
        if (typeof eid === 'function') {
            eid = eid();
        }
        return (
            <span key={eid} className="adv-srch-filter">
                {SC.fieldLookup[entry?.field]}: {entryValue}
            </span>
        );
    });

    return (
        <div className={'sui-facetList-horiz'}>
            <span>Advanced Search Filters:</span>
            <div className="adv-srch-filter-wrapper">
                <div className="adv-srch-filter-list">{entries}</div>
                <div className="adv-srch-edit">
                    <a onClick={() => history.push('/advanced-search')}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
                            <line x1="3" y1="22" x2="21" y2="22"></line>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}
