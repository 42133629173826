import { MdDeleteForever } from 'react-icons/all';
import React, { useEffect, useRef, useState } from 'react';
import * as SC from './SearchConstants';
import { openTabStore, treeStore } from '../../hooks/useCloseStore';
import { browseSearchToggle } from '../../hooks/useBrowseSearchToggle';
import { useAdvancedSearch } from '../../hooks/useAdvancedSearch';
import {
    AND,
    ANY,
    ASSET_SUBTYPES,
    CONTAINS,
    FIRST,
    LAST1YEAR,
    OR,
    RESOURCE_TYPE,
} from './SearchConstants';

const AdvancedSearchFormRow = ({ n, row, deleteMe, submit }) => {
    const rows = useAdvancedSearch((state) => state.rows);
    const updateRows = useAdvancedSearch((state) => state.updateRows);

    const [hasSearchBox, setHasSearchBox] = useState(true);
    const searchBoxRef = useRef();
    const dateSelect = useRef();

    if (!row) {
        return null;
    }

    row.setn(n);
    const id = row.id();
    if (row.isDate()) {
        row.type = 'date';
    } else if (row.isAssetType()) {
        row.type = 'assettype';
    }
    const rowType = row.type;

    const updateRow = (fld, val = '') => {
        if (typeof fld === 'object') {
            Object.keys(fld).forEach((k) => {
                row[k] = fld[k];
            });
        } else {
            row[fld] = val;
        }
        updateRows([...rows.toSpliced(n, 1, row)]);
    };

    let asset_type_used = false;
    rows.forEach((rw, rind) => {
        if (rind !== n) {
            if (rw.field === RESOURCE_TYPE && row.conn === AND) {
                asset_type_used = true;
            }
        }
    });

    // console.log('Advance Search Form Row', row);

    return (
        <div className="advsrch-form-row" id={id} data-n={n}>
            <div className="asfr-connector">
                {n > 0 && (
                    <ConnectorSelect
                        id={`advconn-${n}`}
                        className="connector"
                        connector={row.conn}
                        update={updateRow}
                    />
                )}
            </div>
            <div className="field-select">
                <FieldSelect
                    id={`advfield-${n}`}
                    className="field"
                    asset_type_used={asset_type_used}
                    initChoice={row.field}
                    update={updateRow}
                    submit={submit}
                />
            </div>
            <div className="scope-select">
                {rowType === 'normal' && (
                    <ScopeSelect
                        id={`advscope-${n}`}
                        className="scope"
                        initScope={row.scope}
                        update={updateRow}
                        submit={submit}
                    />
                )}
                {rowType === 'date' && (
                    <DateScopeSelect
                        id={`advscope-${n}`}
                        className="scope"
                        setSearchBox={setHasSearchBox}
                        selel={dateSelect}
                        initScope={row.scope}
                        update={updateRow}
                        submit={submit}
                    />
                )}
                {rowType === 'assettype' && (
                    <AssetTypeSelect
                        id={`advscope-${n}`}
                        className="scope asset"
                        setSearchBox={setHasSearchBox}
                        selel={dateSelect}
                        initScope={row.scope}
                        initSubScope={row.subscope}
                        update={updateRow}
                        submit={submit}
                    />
                )}
            </div>
            <div className="search-box">
                {rowType === 'normal' && (
                    <SearchBox
                        id={`advtext-${id}`}
                        sbel={searchBoxRef}
                        disable={!hasSearchBox}
                        className={'textbox'}
                        update={updateRow}
                        submit={submit}
                        val={row.value}
                    />
                )}
            </div>
            <div className="row-delete">
                {(n > 0 || rows.length > 1) && (
                    <MdDeleteForever
                        onClick={() => {
                            deleteMe(n);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const ConnectorSelect = ({ id, name = false, connector, update }) => {
    name = name || id;
    return (
        <select
            id={id}
            name={name}
            value={connector}
            onChange={(event) => {
                update('conn', event.target.value * 1);
            }}
        >
            <option value={SC.AND}>And</option>
            <option value={SC.ANDNOT}>And Not</option>
            <option value={SC.OR}>Or</option>
        </select>
    );
};

const FieldSelect = ({
    id,
    name = false,
    asset_type_used,
    initChoice,
    update,
    submit,
}) => {
    const setTree = treeStore((state) => state.setTree);
    const setBrowse = browseSearchToggle((state) => state.setBrowse);
    const setOpenTab = openTabStore((state) => state.changeButtonState);

    const handleBtnClick = (e) => {
        e.preventDefault();
        setOpenTab(2);
        setBrowse();
        const treeEnum = {
            13: 'places',
            14: 'subjects',
            15: 'terms',
        };
        setTree(treeEnum[initChoice]);
    };
    name = name || id;
    const selel = useRef();
    const ichanged = () => {
        const choice = selel.current.value * 1;
        update('field', choice);
        if (SC.isDate(choice)) {
            update({ type: 'date', scope: LAST1YEAR });
        } else if (choice === SC.RESOURCE_TYPE) {
            update({ type: 'assettype', scope: 'audio-video' });
        } else {
            update('type', 'normal');
        }
    };
    const fnms = SC.fieldLookup;
    let opts = [
        SC.ANY,
        SC.TITLE,
        SC.RESOURCE_TYPE,
        SC.PERSON,
        SC.REL_PLACES,
        SC.REL_SUBJECTS,
        SC.REL_TERMS,
        SC.PUB_PLACE,
        SC.PUBLISHER,
        SC.IDS,
        SC.CREATE_DATE,
        SC.ENTRY_DATE,
    ];
    if (asset_type_used) {
        opts.splice(2, 1); // Do not give Asset Type (Resource Type) as option if already used
    }
    return (
        <>
            <select
                id={id}
                name={name}
                ref={selel}
                value={initChoice}
                onChange={ichanged}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        submit(e);
                    }
                }}
            >
                {opts.map((opt, n) => {
                    return (
                        <option value={opt} key={`advsrch-field-option-${n}`}>
                            {fnms[opt]}
                        </option>
                    );
                })}
            </select>
            {initChoice && [13, 14, 15].includes(initChoice) && (
                <span>
                    <button
                        className="btn btn-warning btn-sm"
                        onClick={handleBtnClick}
                    >
                        Open Related Tree
                    </button>
                </span>
            )}
        </>
    );
};

const ScopeSelect = ({ id, name = false, initScope, update, submit }) => {
    name = name || id;
    return (
        <select
            id={id}
            name={name}
            value={initScope}
            onChange={(event) => {
                update('scope', event.target.value * 1);
            }}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    submit(e);
                }
            }}
        >
            <option value={SC.CONTAINS}>Contains</option>
            <option value={SC.EXACTLY}>Exactly</option>
            <option value={SC.STARTSWITH}>Starts With</option>
            <option value={SC.ENDSWITH}>Ends With</option>
        </select>
    );
};

const SearchBox = ({
    id,
    disable,
    name = false,
    val = '',
    update,
    submit = null,
}) => {
    name = name || id;
    const cnm = disable ? 'd-none' : 'd-block';
    return (
        <input
            type="text"
            id={id}
            name={name}
            value={val}
            disabled={disable}
            className={cnm}
            onChange={(event) => {
                update('value', event.target.value);
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && submit !== null) {
                    submit(event);
                    return false;
                }
            }}
        />
    );
};

const DateScopeSelect = ({
    id,
    setSearchBox,
    selel,
    name = false,
    initScope,
    update,
    submit,
}) => {
    const [scopeVal, setScopeVal] = useState(initScope);
    name = name || id;
    const ichanged = (event) => {
        const selectedVal = event.target.value * 1;
        update('scope', selectedVal);
        if (SC.needsDateString(selectedVal)) {
            setSearchBox(true);
        } else {
            setSearchBox(false);
        }
    };
    return (
        <select
            id={id}
            name={name}
            ref={selel}
            value={initScope}
            onChange={ichanged}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    submit(e);
                }
            }}
        >
            <option value={SC.LAST1YEAR}>in last year</option>
            <option value={SC.LAST5YEARS}>in last 5 years</option>
            <option value={SC.LAST10YEARS}>in last 10 years</option>
            <option value={SC.EXACTLY}>exactly</option>
            <option value={SC.BETWEEN}>between</option>
        </select>
    );
};

const AssetTypeSelect = ({
    id,
    setSearchBox,
    selel,
    name = false,
    initScope,
    initSubScope,
    update,
    submit,
}) => {
    name = name || id;
    let subtype_select = null;
    if (Object.keys(ASSET_SUBTYPES).includes(initScope)) {
        subtype_select = (
            <select
                id={`${id}-subtype`}
                name={`${name}-subtype`}
                className="asset-sub-select text-capitalize"
                value={initSubScope}
                onChange={(event) => {
                    update('subscope', event.target.value);
                }}
            >
                <option key={`astopt-all`} value="all">
                    All
                </option>
                {Object.keys(ASSET_SUBTYPES[initScope]).map((ak, ki) => {
                    const val = ASSET_SUBTYPES[initScope][ak];
                    return (
                        <option key={`astopt-${ki}`} value={val}>
                            {val}
                        </option>
                    );
                })}
            </select>
        );
    }
    return (
        <div className="asset-select-wrap">
            <select
                id={id}
                name={name}
                ref={selel}
                className="asset-select"
                value={initScope}
                onChange={(event) => update('scope', event.target.value)}
            >
                {Object.keys(SC?.ASSET_TYPES).map((ak, ki) => {
                    return (
                        <option key={`atopt-${ki}`} value={ak}>
                            {SC?.ASSET_TYPES[ak]}
                        </option>
                    );
                })}
            </select>
            {subtype_select}
        </div>
    );
};

export default AdvancedSearchFormRow;
