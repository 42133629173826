import { useQuery } from 'react-query';
import axios from 'axios';
import jsonpAdapter from '../common/axios-jsonp';

const callproxy = async (aurl) => {
    if (aurl === '') {
        return '';
    }

    const request = {
        url: process.env.REACT_APP_DJANGO_PROXY + aurl,
        adapter: jsonpAdapter,
        dataType: 'jsonp',
        callbackParamName: 'json.wrf',
        headers: {
            'Content-Type': null,
        },
        params: {},
    };
    const response = await axios.request(request).catch((e) => {
        console.log('error', e);
    });

    return response;
};

export function useProxy(url = '', byPass = false) {
    const qkey = ['django_proxy', url];
    const result = useQuery(qkey, () => callproxy(url), {
        enabled: !byPass,
    });
    return result;
}
