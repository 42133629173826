/**
 * This is custom ISO Language Data take from two NPM packages using:
 *          import ISO6391 from 'iso-639-1';   // 2-character language codes
 *          import { iso6393 } from 'iso-639-3';  // 3-character language codes
 * From these and with commented out code in Iso639M.js I created the data object below.
 * This data is used by Iso639M.js to provide useful utility functions to convert codes to names,
 * codes from one length to another, and retrieve names from code and vice versa.
 *
 * Written by Than Grove 2021-11-10
 *
 */

// iso3 vs. iso1 refers to iso-639-3 and iso-639-1 not the number of characters
export const iso639 = {
    aa: {
        name: 'Afar',
        native: 'Afaraf',
        iso1: 'aa',
        iso3: 'aar',
        iso3b: 'aar',
        iso3t: 'aar',
        type: 'living',
    },
    ab: {
        name: 'Abkhaz',
        native: 'аҧсуа бызшәа',
        iso1: 'ab',
        iso3: 'abk',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ae: {
        name: 'Avestan',
        native: 'avesta',
        iso1: 'ae',
        iso3: 'ave',
        iso3b: 'ave',
        iso3t: 'ave',
        type: 'ancient',
    },
    af: {
        name: 'Afrikaans',
        native: 'Afrikaans',
        iso1: 'af',
        iso3: 'afr',
        iso3b: 'afr',
        iso3t: 'afr',
        type: 'living',
    },
    ak: {
        name: 'Akan',
        native: 'Akan',
        iso1: 'ak',
        iso3: 'aka',
        iso3b: 'aka',
        iso3t: 'aka',
        type: 'living',
    },
    am: {
        name: 'Amharic',
        native: 'አማርኛ',
        iso1: 'am',
        iso3: 'amh',
        iso3b: 'amh',
        iso3t: 'amh',
        type: 'living',
    },
    an: {
        name: 'Aragonese',
        native: 'aragonés',
        iso1: 'an',
        iso3: 'arg',
        iso3b: 'arg',
        iso3t: 'arg',
        type: 'living',
    },
    ar: {
        name: 'Arabic',
        native: 'اللغة العربية',
        iso1: 'ar',
        iso3: 'ara',
        iso3b: 'ara',
        iso3t: 'ara',
        type: 'living',
    },
    as: {
        name: 'Assamese',
        native: 'অসমীয়া',
        iso1: 'as',
        iso3: 'asm',
        iso3b: 'asm',
        iso3t: 'asm',
        type: 'living',
    },
    av: {
        name: 'Avaric',
        native: 'авар мацӀ',
        iso1: 'av',
        iso3: 'ava',
        iso3b: 'ava',
        iso3t: 'ava',
        type: 'living',
    },
    ay: {
        name: 'Aymara',
        native: 'aymar aru',
        iso1: 'ay',
        iso3: 'aym',
        iso3b: 'aym',
        iso3t: 'aym',
        type: 'living',
    },
    az: {
        name: 'Azerbaijani',
        native: 'azərbaycan dili',
        iso1: 'az',
        iso3: 'aze',
        iso3b: 'aze',
        iso3t: 'aze',
        type: 'living',
    },
    ba: {
        name: 'Bashkir',
        native: 'башҡорт теле',
        iso1: 'ba',
        iso3: 'bak',
        iso3b: 'bak',
        iso3t: 'bak',
        type: 'living',
    },
    be: {
        name: 'Belarusian',
        native: 'беларуская мова',
        iso1: 'be',
        iso3: 'bel',
        iso3b: 'bel',
        iso3t: 'bel',
        type: 'living',
    },
    bg: {
        name: 'Bulgarian',
        native: 'български език',
        iso1: 'bg',
        iso3: 'bul',
        iso3b: 'bul',
        iso3t: 'bul',
        type: 'living',
    },
    bh: {
        name: 'Bihari',
        native: 'भोजपुरी',
        iso1: 'bh',
        iso3: 'bih',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    bi: {
        name: 'Bislama',
        native: 'Bislama',
        iso1: 'bi',
        iso3: 'bis',
        iso3b: 'bis',
        iso3t: 'bis',
        type: 'living',
    },
    bm: {
        name: 'Bambara',
        native: 'bamanankan',
        iso1: 'bm',
        iso3: 'bam',
        iso3b: 'bam',
        iso3t: 'bam',
        type: 'living',
    },
    bn: {
        name: 'Bengali',
        native: 'বাংলা',
        iso1: 'bn',
        iso3: 'ben',
        iso3b: 'ben',
        iso3t: 'ben',
        type: 'living',
    },
    bo: {
        name: 'Tibetan',
        native: 'བོད་ཡིག',
        iso1: 'bo',
        iso3: 'bod',
        iso3b: 'tib',
        iso3t: 'bod',
        type: 'living',
    },
    br: {
        name: 'Breton',
        native: 'brezhoneg',
        iso1: 'br',
        iso3: 'bre',
        iso3b: 'bre',
        iso3t: 'bre',
        type: 'living',
    },
    bs: {
        name: 'Bosnian',
        native: 'bosanski jezik',
        iso1: 'bs',
        iso3: 'bos',
        iso3b: 'bos',
        iso3t: 'bos',
        type: 'living',
    },
    ca: {
        name: 'Catalan',
        native: 'Català',
        iso1: 'ca',
        iso3: 'cat',
        iso3b: 'cat',
        iso3t: 'cat',
        type: 'living',
    },
    ce: {
        name: 'Chechen',
        native: 'нохчийн мотт',
        iso1: 'ce',
        iso3: 'che',
        iso3b: 'che',
        iso3t: 'che',
        type: 'living',
    },
    ch: {
        name: 'Chamorro',
        native: 'Chamoru',
        iso1: 'ch',
        iso3: 'cha',
        iso3b: 'cha',
        iso3t: 'cha',
        type: 'living',
    },
    co: {
        name: 'Corsican',
        native: 'corsu',
        iso1: 'co',
        iso3: 'cos',
        iso3b: 'cos',
        iso3t: 'cos',
        type: 'living',
    },
    cr: {
        name: 'Cree',
        native: 'ᓀᐦᐃᔭᐍᐏᐣ',
        iso1: 'cr',
        iso3: 'cre',
        iso3b: 'cre',
        iso3t: 'cre',
        type: 'living',
    },
    cs: {
        name: 'Czech',
        native: 'čeština',
        iso1: 'cs',
        iso3: 'ces',
        iso3b: 'cze',
        iso3t: 'ces',
        type: 'living',
    },
    cu: {
        name: 'Old Church Slavonic',
        native: 'ѩзыкъ словѣньскъ',
        iso1: 'cu',
        iso3: 'chu',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    cv: {
        name: 'Chuvash',
        native: 'чӑваш чӗлхи',
        iso1: 'cv',
        iso3: 'chv',
        iso3b: 'chv',
        iso3t: 'chv',
        type: 'living',
    },
    cy: {
        name: 'Welsh',
        native: 'Cymraeg',
        iso1: 'cy',
        iso3: 'cym',
        iso3b: 'wel',
        iso3t: 'cym',
        type: 'living',
    },
    da: {
        name: 'Danish',
        native: 'dansk',
        iso1: 'da',
        iso3: 'dan',
        iso3b: 'dan',
        iso3t: 'dan',
        type: 'living',
    },
    de: {
        name: 'German',
        native: 'Deutsch',
        iso1: 'de',
        iso3: 'deu',
        iso3b: 'ger',
        iso3t: 'deu',
        type: 'living',
    },
    dv: {
        name: 'Divehi',
        native: 'Dhivehi',
        iso1: 'dv',
        iso3: 'div',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    dz: {
        name: 'Dzongkha',
        native: 'རྫོང་ཁ',
        iso1: 'dz',
        iso3: 'dzo',
        iso3b: 'dzo',
        iso3t: 'dzo',
        type: 'living',
    },
    ee: {
        name: 'Ewe',
        native: 'Eʋegbe',
        iso1: 'ee',
        iso3: 'ewe',
        iso3b: 'ewe',
        iso3t: 'ewe',
        type: 'living',
    },
    el: {
        name: 'Greek',
        native: 'Ελληνικά',
        iso1: 'el',
        iso3: 'gre',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    en: {
        name: 'English',
        native: 'English',
        iso1: 'en',
        iso3: 'eng',
        iso3b: 'eng',
        iso3t: 'eng',
        type: 'living',
    },
    eo: {
        name: 'Esperanto',
        native: 'Esperanto',
        iso1: 'eo',
        iso3: 'epo',
        iso3b: 'epo',
        iso3t: 'epo',
        type: 'constructed',
    },
    es: {
        name: 'Spanish',
        native: 'Español',
        iso1: 'es',
        iso3: 'spa',
        iso3b: 'spa',
        iso3t: 'spa',
        type: 'living',
    },
    et: {
        name: 'Estonian',
        native: 'eesti',
        iso1: 'et',
        iso3: 'est',
        iso3b: 'est',
        iso3t: 'est',
        type: 'living',
    },
    eu: {
        name: 'Basque',
        native: 'euskara',
        iso1: 'eu',
        iso3: 'eus',
        iso3b: 'baq',
        iso3t: 'eus',
        type: 'living',
    },
    fa: {
        name: 'Persian',
        native: 'فارسی',
        iso1: 'fa',
        iso3: 'fas',
        iso3b: 'per',
        iso3t: 'fas',
        type: 'living',
    },
    ff: {
        name: 'Fula',
        native: 'Fulfulde',
        iso1: 'ff',
        iso3: 'ful',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    fi: {
        name: 'Finnish',
        native: 'suomi',
        iso1: 'fi',
        iso3: 'fin',
        iso3b: 'fin',
        iso3t: 'fin',
        type: 'living',
    },
    fj: {
        name: 'Fijian',
        native: 'Vakaviti',
        iso1: 'fj',
        iso3: 'fij',
        iso3b: 'fij',
        iso3t: 'fij',
        type: 'living',
    },
    fo: {
        name: 'Faroese',
        native: 'føroyskt',
        iso1: 'fo',
        iso3: 'fao',
        iso3b: 'fao',
        iso3t: 'fao',
        type: 'living',
    },
    fr: {
        name: 'French',
        native: 'Français',
        iso1: 'fr',
        iso3: 'fra',
        iso3b: 'fre',
        iso3t: 'fra',
        type: 'living',
    },
    fy: {
        name: 'Western Frisian',
        native: 'Frysk',
        iso1: 'fy',
        iso3: 'fry',
        iso3b: 'fry',
        iso3t: 'fry',
        type: 'living',
    },
    ga: {
        name: 'Irish',
        native: 'Gaeilge',
        iso1: 'ga',
        iso3: 'gle',
        iso3b: 'gle',
        iso3t: 'gle',
        type: 'living',
    },
    gd: {
        name: 'Scottish Gaelic',
        native: 'Gàidhlig',
        iso1: 'gd',
        iso3: 'gla',
        iso3b: 'gla',
        iso3t: 'gla',
        type: 'living',
    },
    gl: {
        name: 'Galician',
        native: 'galego',
        iso1: 'gl',
        iso3: 'glg',
        iso3b: 'glg',
        iso3t: 'glg',
        type: 'living',
    },
    gn: {
        name: 'Guaraní',
        native: "Avañe'ẽ",
        iso1: 'gn',
        iso3: 'grn',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    gu: {
        name: 'Gujarati',
        native: 'ગુજરાતી',
        iso1: 'gu',
        iso3: 'guj',
        iso3b: 'guj',
        iso3t: 'guj',
        type: 'living',
    },
    gv: {
        name: 'Manx',
        native: 'Gaelg',
        iso1: 'gv',
        iso3: 'glv',
        iso3b: 'glv',
        iso3t: 'glv',
        type: 'living',
    },
    ha: {
        name: 'Hausa',
        native: 'هَوُسَ',
        iso1: 'ha',
        iso3: 'hau',
        iso3b: 'hau',
        iso3t: 'hau',
        type: 'living',
    },
    he: {
        name: 'Hebrew',
        native: 'עברית',
        iso1: 'he',
        iso3: 'heb',
        iso3b: 'heb',
        iso3t: 'heb',
        type: 'living',
    },
    hi: {
        name: 'Hindi',
        native: 'हिन्दी',
        iso1: 'hi',
        iso3: 'hin',
        iso3b: 'hin',
        iso3t: 'hin',
        type: 'living',
    },
    ho: {
        name: 'Hiri Motu',
        native: 'Hiri Motu',
        iso1: 'ho',
        iso3: 'hmo',
        iso3b: 'hmo',
        iso3t: 'hmo',
        type: 'living',
    },
    hr: {
        name: 'Croatian',
        native: 'Hrvatski',
        iso1: 'hr',
        iso3: 'hrv',
        iso3b: 'hrv',
        iso3t: 'hrv',
        type: 'living',
    },
    ht: {
        name: 'Haitian',
        native: 'Kreyòl ayisyen',
        iso1: 'ht',
        iso3: 'hat',
        iso3b: 'hat',
        iso3t: 'hat',
        type: 'living',
    },
    hu: {
        name: 'Hungarian',
        native: 'magyar',
        iso1: 'hu',
        iso3: 'hun',
        iso3b: 'hun',
        iso3t: 'hun',
        type: 'living',
    },
    hy: {
        name: 'Armenian',
        native: 'Հայերեն',
        iso1: 'hy',
        iso3: 'hye',
        iso3b: 'arm',
        iso3t: 'hye',
        type: 'living',
    },
    hz: {
        name: 'Herero',
        native: 'Otjiherero',
        iso1: 'hz',
        iso3: 'her',
        iso3b: 'her',
        iso3t: 'her',
        type: 'living',
    },
    ia: {
        name: 'Interlingua',
        native: 'Interlingua',
        iso1: 'ia',
        iso3: 'ina',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    id: {
        name: 'Indonesian',
        native: 'Bahasa Indonesia',
        iso1: 'id',
        iso3: 'ind',
        iso3b: 'ind',
        iso3t: 'ind',
        type: 'living',
    },
    ie: {
        name: 'Interlingue',
        native: 'Interlingue',
        iso1: 'ie',
        iso3: 'ile',
        iso3b: 'ile',
        iso3t: 'ile',
        type: 'constructed',
    },
    ig: {
        name: 'Igbo',
        native: 'Asụsụ Igbo',
        iso1: 'ig',
        iso3: 'ibo',
        iso3b: 'ibo',
        iso3t: 'ibo',
        type: 'living',
    },
    ii: {
        name: 'Nuosu',
        native: 'ꆈꌠ꒿ Nuosuhxop',
        iso1: 'ii',
        iso3: 'iii',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ik: {
        name: 'Inupiaq',
        native: 'Iñupiaq',
        iso1: 'ik',
        iso3: 'ipk',
        iso3b: 'ipk',
        iso3t: 'ipk',
        type: 'living',
    },
    io: {
        name: 'Ido',
        native: 'Ido',
        iso1: 'io',
        iso3: 'ido',
        iso3b: 'ido',
        iso3t: 'ido',
        type: 'constructed',
    },
    is: {
        name: 'Icelandic',
        native: 'Íslenska',
        iso1: 'is',
        iso3: 'isl',
        iso3b: 'ice',
        iso3t: 'isl',
        type: 'living',
    },
    it: {
        name: 'Italian',
        native: 'Italiano',
        iso1: 'it',
        iso3: 'ita',
        iso3b: 'ita',
        iso3t: 'ita',
        type: 'living',
    },
    iu: {
        name: 'Inuktitut',
        native: 'ᐃᓄᒃᑎᑐᑦ',
        iso1: 'iu',
        iso3: 'iku',
        iso3b: 'iku',
        iso3t: 'iku',
        type: 'living',
    },
    ja: {
        name: 'Japanese',
        native: '日本語',
        iso1: 'ja',
        iso3: 'jpn',
        iso3b: 'jpn',
        iso3t: 'jpn',
        type: 'living',
    },
    jv: {
        name: 'Javanese',
        native: 'basa Jawa',
        iso1: 'jv',
        iso3: 'jav',
        iso3b: 'jav',
        iso3t: 'jav',
        type: 'living',
    },
    ka: {
        name: 'Georgian',
        native: 'ქართული',
        iso1: 'ka',
        iso3: 'kat',
        iso3b: 'geo',
        iso3t: 'kat',
        type: 'living',
    },
    kg: {
        name: 'Kongo',
        native: 'Kikongo',
        iso1: 'kg',
        iso3: 'kon',
        iso3b: 'kon',
        iso3t: 'kon',
        type: 'living',
    },
    ki: {
        name: 'Kikuyu',
        native: 'Gĩkũyũ',
        iso1: 'ki',
        iso3: 'kik',
        iso3b: 'kik',
        iso3t: 'kik',
        type: 'living',
    },
    kj: {
        name: 'Kwanyama',
        native: 'Kuanyama',
        iso1: 'kj',
        iso3: 'kua',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    kk: {
        name: 'Kazakh',
        native: 'қазақ тілі',
        iso1: 'kk',
        iso3: 'kaz',
        iso3b: 'kaz',
        iso3t: 'kaz',
        type: 'living',
    },
    kl: {
        name: 'Kalaallisut',
        native: 'kalaallisut',
        iso1: 'kl',
        iso3: 'kal',
        iso3b: 'kal',
        iso3t: 'kal',
        type: 'living',
    },
    km: {
        name: 'Khmer',
        native: 'ខេមរភាសា',
        iso1: 'km',
        iso3: 'khm',
        iso3b: 'khm',
        iso3t: 'khm',
        type: 'living',
    },
    kn: {
        name: 'Kannada',
        native: 'ಕನ್ನಡ',
        iso1: 'kn',
        iso3: 'kan',
        iso3b: 'kan',
        iso3t: 'kan',
        type: 'living',
    },
    ko: {
        name: 'Korean',
        native: '한국어',
        iso1: 'ko',
        iso3: 'kor',
        iso3b: 'kor',
        iso3t: 'kor',
        type: 'living',
    },
    kr: {
        name: 'Kanuri',
        native: 'Kanuri',
        iso1: 'kr',
        iso3: 'kau',
        iso3b: 'kau',
        iso3t: 'kau',
        type: 'living',
    },
    ks: {
        name: 'Kashmiri',
        native: 'कश्मीरी',
        iso1: 'ks',
        iso3: 'kas',
        iso3b: 'kas',
        iso3t: 'kas',
        type: 'living',
    },
    ku: {
        name: 'Kurdish',
        native: 'Kurdî',
        iso1: 'ku',
        iso3: 'kur',
        iso3b: 'kur',
        iso3t: 'kur',
        type: 'living',
    },
    kv: {
        name: 'Komi',
        native: 'коми кыв',
        iso1: 'kv',
        iso3: 'kom',
        iso3b: 'kom',
        iso3t: 'kom',
        type: 'living',
    },
    kw: {
        name: 'Cornish',
        native: 'Kernewek',
        iso1: 'kw',
        iso3: 'cor',
        iso3b: 'cor',
        iso3t: 'cor',
        type: 'living',
    },
    ky: {
        name: 'Kyrgyz',
        native: 'Кыргызча',
        iso1: 'ky',
        iso3: 'kir',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    la: {
        name: 'Latin',
        native: 'latine',
        iso1: 'la',
        iso3: 'lat',
        iso3b: 'lat',
        iso3t: 'lat',
        type: 'ancient',
    },
    lb: {
        name: 'Luxembourgish',
        native: 'Lëtzebuergesch',
        iso1: 'lb',
        iso3: 'ltz',
        iso3b: 'ltz',
        iso3t: 'ltz',
        type: 'living',
    },
    lg: {
        name: 'Ganda',
        native: 'Luganda',
        iso1: 'lg',
        iso3: 'lug',
        iso3b: 'lug',
        iso3t: 'lug',
        type: 'living',
    },
    li: {
        name: 'Limburgish',
        native: 'Limburgs',
        iso1: 'li',
        iso3: 'lim',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ln: {
        name: 'Lingala',
        native: 'Lingála',
        iso1: 'ln',
        iso3: 'lin',
        iso3b: 'lin',
        iso3t: 'lin',
        type: 'living',
    },
    lo: {
        name: 'Lao',
        native: 'ພາສາ',
        iso1: 'lo',
        iso3: 'lao',
        iso3b: 'lao',
        iso3t: 'lao',
        type: 'living',
    },
    lt: {
        name: 'Lithuanian',
        native: 'lietuvių kalba',
        iso1: 'lt',
        iso3: 'lit',
        iso3b: 'lit',
        iso3t: 'lit',
        type: 'living',
    },
    lu: {
        name: 'Luba-Katanga',
        native: 'Tshiluba',
        iso1: 'lu',
        iso3: 'lub',
        iso3b: 'lub',
        iso3t: 'lub',
        type: 'living',
    },
    lv: {
        name: 'Latvian',
        native: 'latviešu valoda',
        iso1: 'lv',
        iso3: 'lav',
        iso3b: 'lav',
        iso3t: 'lav',
        type: 'living',
    },
    mg: {
        name: 'Malagasy',
        native: 'fiteny malagasy',
        iso1: 'mg',
        iso3: 'mlg',
        iso3b: 'mlg',
        iso3t: 'mlg',
        type: 'living',
    },
    mh: {
        name: 'Marshallese',
        native: 'Kajin M̧ajeļ',
        iso1: 'mh',
        iso3: 'mah',
        iso3b: 'mah',
        iso3t: 'mah',
        type: 'living',
    },
    mi: {
        name: 'Māori',
        native: 'te reo Māori',
        iso1: 'mi',
        iso3: 'mao',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    mk: {
        name: 'Macedonian',
        native: 'македонски јазик',
        iso1: 'mk',
        iso3: 'mkd',
        iso3b: 'mac',
        iso3t: 'mkd',
        type: 'living',
    },
    ml: {
        name: 'Malayalam',
        native: 'മലയാളം',
        iso1: 'ml',
        iso3: 'mal',
        iso3b: 'mal',
        iso3t: 'mal',
        type: 'living',
    },
    mn: {
        name: 'Mongolian',
        native: 'Монгол хэл',
        iso1: 'mn',
        iso3: 'mon',
        iso3b: 'mon',
        iso3t: 'mon',
        type: 'living',
    },
    mr: {
        name: 'Marathi',
        native: 'मराठी',
        iso1: 'mr',
        iso3: 'mar',
        iso3b: 'mar',
        iso3t: 'mar',
        type: 'living',
    },
    ms: {
        name: 'Malay',
        native: 'Bahasa Malaysia',
        iso1: 'ms',
        iso3: 'may',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    mt: {
        name: 'Maltese',
        native: 'Malti',
        iso1: 'mt',
        iso3: 'mlt',
        iso3b: 'mlt',
        iso3t: 'mlt',
        type: 'living',
    },
    my: {
        name: 'Burmese',
        native: 'ဗမာစာ',
        iso1: 'my',
        iso3: 'mya',
        iso3b: 'bur',
        iso3t: 'mya',
        type: 'living',
    },
    na: {
        name: 'Nauru',
        native: 'Ekakairũ Naoero',
        iso1: 'na',
        iso3: 'nau',
        iso3b: 'nau',
        iso3t: 'nau',
        type: 'living',
    },
    nb: {
        name: 'Norwegian Bokmål',
        native: 'Norsk bokmål',
        iso1: 'nb',
        iso3: 'nob',
        iso3b: 'nob',
        iso3t: 'nob',
        type: 'living',
    },
    nd: {
        name: 'Northern Ndebele',
        native: 'isiNdebele',
        iso1: 'nd',
        iso3: 'nde',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ne: {
        name: 'Nepali',
        native: 'नेपाली',
        iso1: 'ne',
        iso3: 'nep',
        iso3b: false,
        iso3t: false,
        type: 'living',
    },
    ng: {
        name: 'Ndonga',
        native: 'Owambo',
        iso1: 'ng',
        iso3: 'ndo',
        iso3b: 'ndo',
        iso3t: 'ndo',
        type: 'living',
    },
    nl: {
        name: 'Dutch',
        native: 'Nederlands',
        iso1: 'nl',
        iso3: 'nld',
        iso3b: 'dut',
        iso3t: 'nld',
        type: 'living',
    },
    nn: {
        name: 'Norwegian Nynorsk',
        native: 'Norsk nynorsk',
        iso1: 'nn',
        iso3: 'nno',
        iso3b: 'nno',
        iso3t: 'nno',
        type: 'living',
    },
    no: {
        name: 'Norwegian',
        native: 'Norsk',
        iso1: 'no',
        iso3: 'nor',
        iso3b: 'nor',
        iso3t: 'nor',
        type: 'living',
    },
    nr: {
        name: 'Southern Ndebele',
        native: 'isiNdebele',
        iso1: 'nr',
        iso3: 'nbl',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    nv: {
        name: 'Navajo',
        native: 'Diné bizaad',
        iso1: 'nv',
        iso3: 'nav',
        iso3b: 'nav',
        iso3t: 'nav',
        type: 'living',
    },
    ny: {
        name: 'Chichewa',
        native: 'chiCheŵa',
        iso1: 'ny',
        iso3: 'nya',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    oc: {
        name: 'Occitan',
        native: 'occitan',
        iso1: 'oc',
        iso3: 'oci',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    oj: {
        name: 'Ojibwe',
        native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
        iso1: 'oj',
        iso3: 'oji',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    om: {
        name: 'Oromo',
        native: 'Afaan Oromoo',
        iso1: 'om',
        iso3: 'orm',
        iso3b: 'orm',
        iso3t: 'orm',
        type: 'living',
    },
    or: {
        name: 'Oriya',
        native: 'ଓଡ଼ିଆ',
        iso1: 'or',
        iso3: 'ori',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    os: {
        name: 'Ossetian',
        native: 'ирон æвзаг',
        iso1: 'os',
        iso3: 'oss',
        iso3b: 'oss',
        iso3t: 'oss',
        type: 'living',
    },
    pa: {
        name: 'Panjabi',
        native: 'ਪੰਜਾਬੀ',
        iso1: 'pa',
        iso3: 'pan',
        iso3b: 'pan',
        iso3t: 'pan',
        type: 'living',
    },
    pi: {
        name: 'Pāli',
        native: 'पाऴि',
        iso1: 'pi',
        iso3: 'pli',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    pl: {
        name: 'Polish',
        native: 'Polski',
        iso1: 'pl',
        iso3: 'pol',
        iso3b: 'pol',
        iso3t: 'pol',
        type: 'living',
    },
    ps: {
        name: 'Pashto',
        native: 'پښتو',
        iso1: 'ps',
        iso3: 'pus',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    pt: {
        name: 'Portuguese',
        native: 'Português',
        iso1: 'pt',
        iso3: 'por',
        iso3b: 'por',
        iso3t: 'por',
        type: 'living',
    },
    qu: {
        name: 'Quechua',
        native: 'Runa Simi',
        iso1: 'qu',
        iso3: 'que',
        iso3b: 'que',
        iso3t: 'que',
        type: 'living',
    },
    rm: {
        name: 'Romansh',
        native: 'rumantsch grischun',
        iso1: 'rm',
        iso3: 'roh',
        iso3b: 'roh',
        iso3t: 'roh',
        type: 'living',
    },
    rn: {
        name: 'Kirundi',
        native: 'Ikirundi',
        iso1: 'rn',
        iso3: 'run',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ro: {
        name: 'Romanian',
        native: 'Română',
        iso1: 'ro',
        iso3: 'ron',
        iso3b: 'rum',
        iso3t: 'ron',
        type: 'living',
    },
    ru: {
        name: 'Russian',
        native: 'Русский',
        iso1: 'ru',
        iso3: 'rus',
        iso3b: 'rus',
        iso3t: 'rus',
        type: 'living',
    },
    rw: {
        name: 'Kinyarwanda',
        native: 'Ikinyarwanda',
        iso1: 'rw',
        iso3: 'kin',
        iso3b: 'kin',
        iso3t: 'kin',
        type: 'living',
    },
    sa: {
        name: 'Sanskrit',
        native: 'संस्कृतम्',
        iso1: 'sa',
        iso3: 'san',
        iso3b: 'san',
        iso3t: 'san',
        type: 'ancient',
    },
    sc: {
        name: 'Sardinian',
        native: 'sardu',
        iso1: 'sc',
        iso3: 'srd',
        iso3b: 'srd',
        iso3t: 'srd',
        type: 'living',
    },
    sd: {
        name: 'Sindhi',
        native: 'सिन्धी',
        iso1: 'sd',
        iso3: 'snd',
        iso3b: 'snd',
        iso3t: 'snd',
        type: 'living',
    },
    se: {
        name: 'Northern Sami',
        native: 'Davvisámegiella',
        iso1: 'se',
        iso3: 'sme',
        iso3b: 'sme',
        iso3t: 'sme',
        type: 'living',
    },
    sg: {
        name: 'Sango',
        native: 'yângâ tî sängö',
        iso1: 'sg',
        iso3: 'sag',
        iso3b: 'sag',
        iso3t: 'sag',
        type: 'living',
    },
    si: {
        name: 'Sinhala',
        native: 'සිංහල',
        iso1: 'si',
        iso3: 'sin',
        iso3b: 'sin',
        iso3t: 'sin',
        type: 'living',
    },
    sk: {
        name: 'Slovak',
        native: 'slovenčina',
        iso1: 'sk',
        iso3: 'slk',
        iso3b: 'slo',
        iso3t: 'slk',
        type: 'living',
    },
    sl: {
        name: 'Slovenian',
        native: 'slovenski jezik',
        iso1: 'sl',
        iso3: 'slv',
        iso3b: 'slv',
        iso3t: 'slv',
        type: 'living',
    },
    sm: {
        name: 'Samoan',
        native: "gagana fa'a Samoa",
        iso1: 'sm',
        iso3: 'smo',
        iso3b: 'smo',
        iso3t: 'smo',
        type: 'living',
    },
    sn: {
        name: 'Shona',
        native: 'chiShona',
        iso1: 'sn',
        iso3: 'sna',
        iso3b: 'sna',
        iso3t: 'sna',
        type: 'living',
    },
    so: {
        name: 'Somali',
        native: 'Soomaaliga',
        iso1: 'so',
        iso3: 'som',
        iso3b: 'som',
        iso3t: 'som',
        type: 'living',
    },
    sq: {
        name: 'Albanian',
        native: 'Shqip',
        iso1: 'sq',
        iso3: 'sqi',
        iso3b: 'alb',
        iso3t: 'sqi',
        type: 'living',
    },
    sr: {
        name: 'Serbian',
        native: 'српски језик',
        iso1: 'sr',
        iso3: 'srp',
        iso3b: 'srp',
        iso3t: 'srp',
        type: 'living',
    },
    ss: {
        name: 'Swati',
        native: 'SiSwati',
        iso1: 'ss',
        iso3: 'ssw',
        iso3b: 'ssw',
        iso3t: 'ssw',
        type: 'living',
    },
    st: {
        name: 'Southern Sotho',
        native: 'Sesotho',
        iso1: 'st',
        iso3: 'sot',
        iso3b: 'sot',
        iso3t: 'sot',
        type: 'living',
    },
    su: {
        name: 'Sundanese',
        native: 'Basa Sunda',
        iso1: 'su',
        iso3: 'sun',
        iso3b: 'sun',
        iso3t: 'sun',
        type: 'living',
    },
    sv: {
        name: 'Swedish',
        native: 'Svenska',
        iso1: 'sv',
        iso3: 'swe',
        iso3b: 'swe',
        iso3t: 'swe',
        type: 'living',
    },
    sw: {
        name: 'Swahili',
        native: 'Kiswahili',
        iso1: 'sw',
        iso3: 'swa',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    ta: {
        name: 'Tamil',
        native: 'தமிழ்',
        iso1: 'ta',
        iso3: 'tam',
        iso3b: 'tam',
        iso3t: 'tam',
        type: 'living',
    },
    te: {
        name: 'Telugu',
        native: 'తెలుగు',
        iso1: 'te',
        iso3: 'tel',
        iso3b: 'tel',
        iso3t: 'tel',
        type: 'living',
    },
    tg: {
        name: 'Tajik',
        native: 'тоҷикӣ',
        iso1: 'tg',
        iso3: 'tgk',
        iso3b: 'tgk',
        iso3t: 'tgk',
        type: 'living',
    },
    th: {
        name: 'Thai',
        native: 'ไทย',
        iso1: 'th',
        iso3: 'tha',
        iso3b: 'tha',
        iso3t: 'tha',
        type: 'living',
    },
    ti: {
        name: 'Tigrinya',
        native: 'ትግርኛ',
        iso1: 'ti',
        iso3: 'tir',
        iso3b: 'tir',
        iso3t: 'tir',
        type: 'living',
    },
    tk: {
        name: 'Turkmen',
        native: 'Türkmen',
        iso1: 'tk',
        iso3: 'tuk',
        iso3b: 'tuk',
        iso3t: 'tuk',
        type: 'living',
    },
    tl: {
        name: 'Tagalog',
        native: 'Wikang Tagalog',
        iso1: 'tl',
        iso3: 'tgl',
        iso3b: 'tgl',
        iso3t: 'tgl',
        type: 'living',
    },
    tn: {
        name: 'Tswana',
        native: 'Setswana',
        iso1: 'tn',
        iso3: 'tsn',
        iso3b: 'tsn',
        iso3t: 'tsn',
        type: 'living',
    },
    to: {
        name: 'Tonga',
        native: 'faka Tonga',
        iso1: 'to',
        iso3: 'ton',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    tr: {
        name: 'Turkish',
        native: 'Türkçe',
        iso1: 'tr',
        iso3: 'tur',
        iso3b: 'tur',
        iso3t: 'tur',
        type: 'living',
    },
    ts: {
        name: 'Tsonga',
        native: 'Xitsonga',
        iso1: 'ts',
        iso3: 'tso',
        iso3b: 'tso',
        iso3t: 'tso',
        type: 'living',
    },
    tt: {
        name: 'Tatar',
        native: 'татар теле',
        iso1: 'tt',
        iso3: 'tat',
        iso3b: 'tat',
        iso3t: 'tat',
        type: 'living',
    },
    tw: {
        name: 'Twi',
        native: 'Twi',
        iso1: 'tw',
        iso3: 'twi',
        iso3b: 'twi',
        iso3t: 'twi',
        type: 'living',
    },
    ty: {
        name: 'Tahitian',
        native: 'Reo Tahiti',
        iso1: 'ty',
        iso3: 'tah',
        iso3b: 'tah',
        iso3t: 'tah',
        type: 'living',
    },
    ug: {
        name: 'Uyghur',
        native: 'ئۇيغۇرچە‎',
        iso1: 'ug',
        iso3: 'uig',
        iso3b: false,
        iso3t: false,
        type: false,
    },
    uk: {
        name: 'Ukrainian',
        native: 'Українська',
        iso1: 'uk',
        iso3: 'ukr',
        iso3b: 'ukr',
        iso3t: 'ukr',
        type: 'living',
    },
    ur: {
        name: 'Urdu',
        native: 'اردو',
        iso1: 'ur',
        iso3: 'urd',
        iso3b: 'urd',
        iso3t: 'urd',
        type: 'living',
    },
    uz: {
        name: 'Uzbek',
        native: 'Ўзбек',
        iso1: 'uz',
        iso3: 'uzb',
        iso3b: 'uzb',
        iso3t: 'uzb',
        type: 'living',
    },
    ve: {
        name: 'Venda',
        native: 'Tshivenḓa',
        iso1: 've',
        iso3: 'ven',
        iso3b: 'ven',
        iso3t: 'ven',
        type: 'living',
    },
    vi: {
        name: 'Vietnamese',
        native: 'Tiếng Việt',
        iso1: 'vi',
        iso3: 'vie',
        iso3b: 'vie',
        iso3t: 'vie',
        type: 'living',
    },
    vo: {
        name: 'Volapük',
        native: 'Volapük',
        iso1: 'vo',
        iso3: 'vol',
        iso3b: 'vol',
        iso3t: 'vol',
        type: 'constructed',
    },
    wa: {
        name: 'Walloon',
        native: 'walon',
        iso1: 'wa',
        iso3: 'wln',
        iso3b: 'wln',
        iso3t: 'wln',
        type: 'living',
    },
    wo: {
        name: 'Wolof',
        native: 'Wollof',
        iso1: 'wo',
        iso3: 'wol',
        iso3b: 'wol',
        iso3t: 'wol',
        type: 'living',
    },
    xh: {
        name: 'Xhosa',
        native: 'isiXhosa',
        iso1: 'xh',
        iso3: 'xho',
        iso3b: 'xho',
        iso3t: 'xho',
        type: 'living',
    },
    yi: {
        name: 'Yiddish',
        native: 'ייִדיש',
        iso1: 'yi',
        iso3: 'yid',
        iso3b: 'yid',
        iso3t: 'yid',
        type: 'living',
    },
    yo: {
        name: 'Yoruba',
        native: 'Yorùbá',
        iso1: 'yo',
        iso3: 'yor',
        iso3b: 'yor',
        iso3t: 'yor',
        type: 'living',
    },
    za: {
        name: 'Zhuang',
        native: 'Saɯ cueŋƅ',
        iso1: 'za',
        iso3: 'zha',
        iso3b: 'zha',
        iso3t: 'zha',
        type: 'living',
    },
    zh: {
        name: 'Chinese',
        native: '中文',
        iso1: 'zh',
        iso3: 'zho',
        iso3b: 'chi',
        iso3t: 'zho',
        type: 'living',
    },
    zu: {
        name: 'Zulu',
        native: 'isiZulu',
        iso1: 'zu',
        iso3: 'zul',
        iso3b: 'zul',
        iso3t: 'zul',
        type: 'living',
    },
};

export default iso639;
