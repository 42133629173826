import React, { useEffect, useState } from 'react';
import { useKmap } from '../../hooks/useKmap';
import {
    getUniqueKeys,
    getUniquePropIds,
    markTibetan,
    queryID,
} from '../common/utils';
import MandalaSkeleton from '../common/MandalaSkeleton';
import ReactHtmlParser from 'react-html-parser';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import { HtmlCustom } from '../common/MandalaMarkup';

export default function TranslationEquivNewar({ lookup, dictmeta = null }) {
    const [isOpen, setOpen] = useState(true);
    const [hidden, setHidden] = useState(false);
    const [selDef, setSelDef] = useState(''); // Defintion selected by user
    const setSelectedDef = (val) => {
        let newval = val;
        if (val === selDef) {
            newval = '';
            setOpen(true);
        }
        setSelDef(newval);
    };
    let [wd, id] = lookup?.split(':');
    // console.log('id split', wd, id);
    const term_id = queryID('terms', id);
    const {
        isLoading: isTermLoading,
        data: termdata,
        isError: isTermError,
        error: termError,
    } = useKmap(term_id, 'info', false, false);

    useEffect(() => {
        if (selDef?.length > 0) {
            setOpen(false);
        }
    }, [selDef]);

    if (isTermLoading) {
        return <MandalaSkeleton />;
    }

    const defchild = termdata?._childDocuments_?.filter((cd, i) => {
        return cd.block_child_type === 'related_definitions';
    });

    const icon = isOpen ? (
        <span className="icon shanticon-minus"></span>
    ) : (
        <span className="icon shanticon-plus"></span>
    );

    let mainclass = isOpen ? 'open' : 'closed';
    if (hidden) {
        mainclass += ' hidden';
    }

    const hideMe = () => {
        setHidden(true);
    };

    return (
        <div className={`tt-equiv ${mainclass}`}>
            <h5 className="entry-head">
                <Link
                    className="sa"
                    to={`/terms/${id}`}
                    target="_blank"
                    title="View term’s full entry"
                >
                    {wd}
                </Link>{' '}
            </h5>
            {termdata?.response?.numFound === 0 && (
                <div className={`not-found`}>Not Found</div>
            )}
            <div className={`newar-defs`}>
                {defchild.map((def) => {
                    return <Definition data={def} />;
                })}
            </div>
        </div>
    );
}

function Definition({ data }) {
    console.log(
        data.related_definitions_source_code_s,
        data.related_definitions_content_latinu
    );
    return (
        <div className="newtrans container">
            <div className="row">
                <div className="col strong">
                    {data.related_definitions_source_code_s}
                </div>
                <div className="col">
                    {data.related_definitions_content_latinu}
                </div>
            </div>
        </div>
    );
}
