import * as SC from './SearchConstants';
import { isLat, isTib } from '../common/utils';
import { constructTextQuery } from '../../hooks/useSearch';
import { RESOURCE_TYPE } from './SearchConstants';

export class SearchBuilder {
    constructor(rows = []) {
        this.rows = rows;
        this.queryItems = this.rows.map((rw, ri) => {
            return new QueryItem(rw);
        });
    }

    buildQuery() {
        const queryStrings = this.queryItems?.map((qi) => qi.getQuery());
        return queryStrings.join('');
    }

    describeQuery() {
        const descStrings = this.queryItems.map((qi) => qi.getDesc());
        return descStrings.join(' ');
    }

    getAssetType() {
        const atrow = this.queryItems.filter((rw) => {
            return rw.field === RESOURCE_TYPE;
        });
        if (atrow?.length === 1) {
            return atrow[0].scope;
        }
        return false;
    }
}

class QueryItem {
    /**
     * Builds a single query item (equivalent to one line from the form)
     * Values for conn, field, and scope must be the integer values defined in SearchConstants.js
     * And must have the corresponding key defined in SearchConstants.SOLRFIELDS object
     *
     * @param row (FormRowTemplate)
     */
    constructor(row) {
        this.conn = row.conn;
        this.connstr = this.getConnector(this.conn);
        this.field = row.field;
        this.fieldlist = this.getFields(this.field, row.scope, row.value);
        this.scope = row.scope;
        this.subscope = row.subscope;
        this.orig_qstr = row.value;
        this.qstr = row.value?.toLowerCase();
        this.value2 = row.value2;
        this.query = ';';
    }

    // Get a descriptive string of query row
    getDesc() {
        let lndesc = '';
        switch (this.conn) {
            case SC.AND:
                lndesc += ' + ';
                break;
            case SC.OR:
                lndesc += ' | ';
                break;
            case SC.ANDNOT:
                lndesc += ' - ';
                break;
            default:
            // add nothing for first row
        }
        if (this.field === SC.RESOURCE_TYPE) {
            lndesc += SC.ASSET_TYPES[this.scope];
        } else {
            lndesc += this.orig_qstr;
        }

        return lndesc;
    }

    getQuery() {
        // builds the query for this item
        // If an ANY field has no text, return nothing to add to query so it doesn't break (See MANU-7452)
        if (this.field === SC.ANY && this.qstr.length === 0) {
            return '';
        }
        this.query = `${this.connstr}(${this.buildQueryString()})`; // Saves in class for later use
        return this.query; // But also returns it for use outside class
    }

    getConnector(conn) {
        switch (conn) {
            case SC.AND:
                return ' AND ';
            case SC.OR:
                return ' OR ';
            case SC.ANDNOT:
                return ' AND -';
            default:
                return '';
        }
    }

    getFields(field, scp, qry) {
        if (field) {
            field = isNaN(field) ? field : field.toString();
            if (
                field * 1 === SC.ANY &&
                [SC.STARTSWITH, SC.ENDSWITH].includes(scp)
            ) {
                field = SC.ANYSPECIAL.toString(); // Cannot use text general for begins or ends with (MANU-7665)
            }
            if (Object.keys(SC.SOLRFIELDS).includes(field)) {
                return SC.SOLRFIELDS[field];
            }
        }
        return ['text']; // default to 'text' = all fields (the "any" option)
    }

    // TODO: Need to deal with "and not". To make the minus work the item's string needs to be in parentheses.
    buildQueryString() {
        let query = '';
        const tibstops = '་།༑༔༏'; // Tibetan stop characters TODO: use codes here, make more comprehensive

        this.fieldlist.map((fld, fn) => {
            // When "Any" option is chosen, the fld = "text"
            const myqstr = this?.qstr.trim();
            let escqs = myqstr.replace(/\s+/g, '\\ ').replace(/"/g, ''); // Slash escape spaces to search for whole phrase
            // The use of sswords and allwords has been replaced by calling constructTextQuery() from useSearch below.
            // const sswords = myqstr?.toLowerCase().trim().split(' ');
            // const allwords = sswords.join(' AND ');
            const slashy = escqs + '/';
            if (tibstops.includes(escqs.at(-1))) {
                escqs = escqs.substring(0, escqs.length - 1);
            }
            if (fn > 0 && fn < this.fieldlist.length) {
                query += ' '; // was ' OR ' but to do weights we just need spaces
            }
            if (fld === 'name_roman.scholar' || fld.includes('bo_latn')) {
                if (isLat(escqs) && escqs.charAt(escqs.length - 1) !== '/') {
                    escqs = slashy;
                }
            } else if (SC.TIB_FIELDS.includes(fld)) {
                if (isTib(escqs)) {
                    // console.log(escqs, "is Tibetan for ", fld);
                    const lastchar = escqs.charCodeAt(escqs.length - 1);
                    // Remove final tsek if Tibetan to match exact searches
                    if (3850 < lastchar < 3853) {
                        escqs = escqs.substring(0, escqs.length - 1);
                    }
                }
            }
            switch (this?.scope) {
                case SC.EXACTLY:
                    // here fld is the name of the solr field so have to match on that. If "any" exactly,
                    if (fld === SC.SOLRFIELDS[SC.ANY][0]) {
                        // then build list of field names to iterate through (all possible fields to exact match on)
                        // See https://uvaissues.atlassian.net/browse/MANU-7637?focusedCommentId=86272
                        // SC.TITLE includes 'titles' and 'names'
                        const fldnms = SC.SOLRFIELDS[SC.TITLE].concat(
                            SC.SOLRFIELDS[SC.PERSON],
                            SC.SOLRFIELDS[SC.PUB_PLACE],
                            SC.SOLRFIELDS[SC.PUBLISHER]
                        );
                        // if any, use title (which includes names) for now, since "text" won't work.
                        const qitems = fldnms.map(
                            (item) => `${item}:"${escqs}"`
                        );
                        query += qitems.join(' '); // was ' OR ' but can just do space
                    } else {
                        query += `${fld}:"${escqs}"`;
                    }
                    break;
                case SC.STARTSWITH:
                    query += `${fld}:${escqs}*`;
                    break;
                case SC.ENDSWITH:
                    query += `${fld}:*${escqs}`;
                    break;
                case SC.LAST1YEAR:
                    query += `${fld}:[NOW-1YEAR TO NOW]`;
                    break;
                case SC.LAST5YEARS:
                    query += `${fld}:[NOW-5YEAR TO NOW]`;
                    break;
                case SC.LAST10YEARS:
                    query += `${fld}:[NOW-10YEAR TO NOW]`;
                    break;
                case SC.BETWEEN:
                    if (escqs.includes('-')) {
                        const [stdt, enddt] = escqs.split('-');
                        query += `${fld}:[${stdt} TO ${enddt}]`;
                    } else {
                        console.log(
                            'Query string needs to be two SOLR dates separated by a dash, using default query'
                        );
                        query += '*:*';
                    }
                    break;
                default:
                    // "Contains"
                    if (this.field === SC.RESOURCE_TYPE) {
                        let scope = this.scope;
                        // Terms scope here include lang code, such as terms-bo or terms-ne (newar), but solr asset type is just "terms".
                        if (scope.includes('terms-')) {
                            const termslang = scope.replace('terms-', '');
                            scope = `${fld}:terms`;
                            switch (termslang) {
                                case 'ne':
                                    // For newar add conditions that name_deva exists in record
                                    scope += ' AND name_deva:*';
                                    break;
                                default:
                                    // defaulting to Tibetan for now
                                    scope += ' AND name_tibt:*';
                            }
                            scope = `(${scope})`;
                        } else {
                            scope = `${fld}:${this.scope}`;
                        }
                        query += scope; // when search on asset type, scope is what you are looking for and fld is "asset_type"
                        if (this?.subscope?.length > 0) {
                            query += ` AND asset_subtype:${this.subscope}`;
                        }
                    } else if (fld === 'text') {
                        const basic_req = constructTextQuery(myqstr);
                        query = basic_req.q;
                        // console.log('query in new search builder', query);
                    } else {
                        query += `(${fld}:${escqs})^100 (${fld}:*${escqs}*)^85`;
                        // console.log('query', query);
                    }
            }
        });
        return query;
    }
}
