import React, { useEffect, useState } from 'react';
import { useBiblStore } from '../hooks/useBiblStore';
import {
    getBiblLang,
    convertToSectionId,
    convertToSectionLabel,
} from '../common/catalogUtils';
import {
    GoTriangleDown,
    GoTriangleRight,
    GoPrimitiveSquare,
} from 'react-icons/all';
import { usePager } from '../hooks/usePager';
import { usePagerText } from '../hooks/usePagerText';
import $ from 'jquery';
import { enterForClick } from '../../views/common/utils';

export default function TextToc({ view }) {
    const { doc: bibl, dynamicSections } = useBiblStore();
    const [tocItems, setTocItems] = useState(null);

    useEffect(() => {
        let tocitems = <p>No TOC available for this text.</p>;
        if (bibl?.sections?.length > 0) {
            tocitems = ['a', 'b', 'c'].map((x, i) => {
                return <TextTocSection key={`txts-${i}`} sid={x} view={view} />;
            });
            setTocItems(tocitems);
        } else if (dynamicSections) {
            tocitems = <DynamicTextToc />;
            setTocItems(tocitems);
        }
    }, [bibl?.sections, dynamicSections]);

    return <div className="text-toc">{tocItems}</div>;
}

function TextTocSection({ sid, view }) {
    const { doc: bibl } = useBiblStore();
    const sections = bibl?.sections;
    if (!sections) {
        return null;
    }
    let label = 'Body';
    if (sid == 'a') {
        label = 'Front';
    }
    if (sid == 'c') {
        label = 'Back';
    }
    const myid = `${bibl.id}-${sid}`;
    let chapters = sections?.filter((s) => {
        return s.parent_s === myid;
    });
    chapters = chapters.map((ch, i) => {
        return (
            <TextTocChapter
                key={`ttoc-${myid}-${i}`}
                chapter={ch}
                sections={sections}
                view={view}
            />
        );
    });
    return (
        <div id={`toc-${myid}`}>
            <h4>{label}</h4>
            <ul className="list-unstyled">{chapters}</ul>
        </div>
    );
}

function TextTocChapter({ chapter, sections, view }) {
    const pg_pager = usePager();
    const text_pager = usePagerText();
    const pager = view === 'pages' ? pg_pager : text_pager;
    const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(false);
    const lang = getBiblLang(chapter);

    const children = sections.filter((s) => {
        return s?.parent_s === chapter?.id;
    });

    let title = chapter[`title_chap_${lang}`]
        ? chapter[`title_chap_${lang}`][0]
        : chapter['title'][0];
    if (!title || title === '') {
        title = chapter?.label_s;
    }

    useEffect(() => {
        // console.log("in use effect", pager?.subsection);
        if (
            pager?.section === chapter?.chapid_s ||
            pager?.subsection === chapter?.chapid_s
        ) {
            setOpen(true);
        }
        // Set to selected if visible
        if (chapter?.chapid_s && pager.visible.includes(chapter?.chapid_s)) {
            setSelected(true);
        } else {
            setSelected(false);
        }
        // Set to selected if child is visible (i.e. this id is a substring of some id in the list)
        pager.visible.forEach((s, i) => {
            if (s.indexOf(chapter?.chapid_s + '-') > -1) {
                setSelected(true);
            }
        });
    }, [pager]);

    let icon = !children?.length ? (
        <span className="icon-square">
            <GoPrimitiveSquare />
        </span>
    ) : open ? (
        <GoTriangleDown />
    ) : (
        <GoTriangleRight />
    );

    const togglediv = () => {
        setOpen(!open);
    };
    const chaplabel = convertToSectionLabel(chapter?.chapid_s);

    const sectionUpdate = (chaplabel) => {
        const chpid = convertToSectionId(chaplabel);
        pager.updateVisible([]);
        // console.log("chapid in set section", chpid);
        pager.setSection(chapter); // give the whole chapter
        $('.text-toc .selected').removeClass('selected');
        const elid = `s-${chpid}`;
        setTimeout(function () {
            const el = document.getElementById(elid);
            if (el) {
                el.scrollIntoView();
            }
        }, 300);
        setTimeout(function () {
            pager.updateVisible([chpid]);
        }, 800);
    };

    let selclass = selected ? ' selected' : '';
    return (
        <li
            id={`toc-${chapter?.chapid_s}`}
            className={`list-unstyled${selclass}`}
        >
            <a className="toc-icon" onClick={togglediv}>
                {icon}
            </a>
            <a
                className="link chapter"
                onClick={() => sectionUpdate(chaplabel)}
            >
                <span className="chapid text-capitalize">{chaplabel}.</span>
                <span className={lang}>{title}</span>
                {/* Pagination removed for now */}
                {false && chapter?.pagination_s && (
                    <span className="chappage parens">
                        {chapter?.pagination_s}
                    </span>
                )}
            </a>
            <> </>
            <span className="toc-pgn">({chapter?.pagination_s})</span>
            <div className={open ? 'open' : 'closed'}>
                {children?.length > 0 && open && (
                    <ul>
                        {children.map((ch, i) => {
                            return (
                                <TextTocChapter
                                    key={`ttoc-${chapter?.id}-${i}`}
                                    chapter={ch}
                                    sections={sections}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
        </li>
    );
}

function DynamicTextToc(props) {
    const { doc: bibl, dynamicSections } = useBiblStore();
    let label = 'Text Sections';
    const parts = 'abc'.split('').map((ltr) => {
        const ptlabel = ltr === 'a' ? 'Front' : ltr === 'b' ? 'Body' : 'Back';
        const children = dynamicSections.filter(
            (ds) => ds.section_num_s.includes(ltr) && ds.section_level_i === 1
        );
        return (
            <DynamicTextPart
                key={`text-section-${ltr}`}
                label={ptlabel}
                id={ltr}
                level={0}
                children={children}
                sections={dynamicSections}
                bibl={bibl}
            />
        );
    });
    return (
        <div id={`toc-${bibl.id}`} className="dynamic">
            <h2>{label}</h2>
            <ul className="lvl l0 list-unstyled">{parts}</ul>
        </div>
    );
}

function DynamicTextPart({ label, id, level, children, sections, bibl }) {
    const textpager = usePagerText();
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState(null);
    const isFBB = 'abc'.includes(id);

    useEffect(() => {
        if (isFBB) {
            setOpen(true);
            setIcon(null);
        } else {
            setOpen(false);
            setIcon(<GoPrimitiveSquare />);
        }
    }, []);

    useEffect(() => {
        if (children?.length > 0 && !isFBB) {
            if (open) {
                setIcon(<GoTriangleDown />);
            } else {
                setIcon(<GoTriangleRight />);
            }
        }
    }, [open]);

    let childlist = null;
    let listclass = `lvl l${level + 1}`;
    if (children?.length > 0) {
        childlist = children.map((child) => {
            const csid = child.section_num_s;
            const clevel = child.section_level_i;
            const grandchildren = sections.filter((s) => {
                const ssid = s.section_num_s;
                const sslv = s.section_level_i;
                return (
                    ssid !== csid && ssid.includes(csid) && sslv === clevel + 1
                );
            });
            return (
                <DynamicTextPart
                    key={child.id}
                    id={child.id}
                    level={child.section_level_i}
                    label={child.section_title_s}
                    children={grandchildren}
                    sections={sections}
                    bibl={bibl}
                />
            );
        });
        childlist = (
            <ul className={`${listclass} list-unstyled`}>{childlist}</ul>
        );
    }

    const toggle = (e) => {
        setOpen(!open);
        e.preventDefault();
    };
    let iconclass = 'has-children';
    let iconmarkup = (
        <a
            href="#"
            title="toggle open"
            onClick={toggle}
            onKeyUp={enterForClick}
        >
            <span className={`toggle-icon ${iconclass}`}>{icon}</span>
        </a>
    );
    if (children?.length === 0) {
        iconclass = 'no-children';
        iconmarkup = <span className={`toggle-icon ${iconclass}`}>{icon}</span>;
    }

    const goToSection = (e) => {
        const choice = sections.filter((s) => s.id === id);
        if (choice.length > 0) {
            const sect = choice[0];
            sect.texttype = bibl?.texttype;
            textpager.setSection(sect);
            const mtchsid = id.match(/text-([^\-]+)-text/);
            if (mtchsid) {
                let sid = `s-${mtchsid[1]}`;
                setTimeout(() => {
                    const el = document.getElementById(sid);
                    el.scrollIntoView();
                }, 1500);
            }
        }
        e.preventDefault();
    };
    return (
        <li>
            <div id={`toc-${id}`}>
                <h3>
                    {iconmarkup}{' '}
                    <a href="#" onClick={goToSection} onKeyUp={enterForClick}>
                        {label}
                    </a>
                </h3>
                {open && <div className="dtoc-children">{childlist}</div>}
            </div>
        </li>
    );
}
