import { useCatSolr } from '../../hooks/useCatSolr';
import React from 'react';
import { DoxTocLink } from './DoxTocLink';
import { useBiblStore } from '../../hooks/useBiblStore';

export function DoxTocChild({ cid }) {
    // console.log(cid);
    const { getTocItem } = useBiblStore((state) => state);
    const doxdoc = getTocItem(cid);
    if (!doxdoc) {
        return null;
    }
    return <DoxTocLink doc={doxdoc} />;
}
