import { getMainTitle, solrFieldValue, langcode } from '../common/catalogUtils';
import React from 'react';
import { HtmlCustom } from '../common/HtmlCustom';

export function BiblHead({ bibl }) {
    if (!bibl || typeof bibl === 'string') {
        return null;
    }
    // console.log("bibl", bibl);
    const did = bibl?.display_id ? bibl.display_id : 'No ID';
    const titleobj = getMainTitle(bibl);
    const lang = langcode(solrFieldValue(titleobj.lang));
    // console.log("title obj", titleobj);
    const title_text =
        titleobj.title[0] === '<' ? (
            <HtmlCustom markup={titleobj.title} />
        ) : (
            titleobj.title
        );
    return (
        <h1 className={`c-bibl_head`}>
            {!titleobj.title.includes(titleobj.idpref.replace(':', '')) && (
                <span className={`head_id`}>{titleobj.idpref}</span>
            )}
            <span className={`title ${lang}`}>{title_text}</span>{' '}
            <span className="biblid">{did}</span>
        </h1>
    );
}
