import React from 'react';

export function SimpleContentHeader({ label, crumbs }) {
    return (
        <header
            id="c-content__header__main"
            className={`c-content__header__main legacy mandala ${label}`}
        >
            <div
                id="c-content__header__main__wrap"
                className="c-content__header__main__wrap legacy"
            >
                <div className="c-content__header__breadcrumb breadcrumb">
                    <a className="breadcrumb-item" href="#">
                        {label}
                    </a>
                    {crumbs.map((item, n) => {
                        return (
                            <a
                                key={`sch-bc-${n}`}
                                className="breadcrumb-item"
                                href="#"
                            >
                                {item}
                            </a>
                        );
                    })}
                </div>
            </div>
        </header>
    );
}
