import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useView } from '../../hooks/useView';
import { useKmap } from '../../hooks/useKmap';
import MandalaSkeleton from '../../views/common/MandalaSkeleton';
import {
    capitalize,
    getHeaderForView,
    queryID,
} from '../../views/common/utils';
import { HtmlCustom } from '../../views/common/MandalaMarkup';
import { usePerspective } from '../../hooks/usePerspective';
import { useQuery } from 'react-query';
import { getPerspectiveData } from '../../views/KmapTree/KmapPerspectives';
import axios from 'axios';
import { AiOutlineEllipsis, FaEllipsisH } from 'react-icons/all';

/**
 * Grouping element for kmaps breadcrumbs.
 * Creates breadcrumbs based on current Kmap's itemData (Solr record in kmassets)
 * Uses the usePerspective hook that keeps track of when the perspective is changed
 * Uses state for perspName and ancetor_ids that are used to generate the breadcrumbs
 *
 * TODO: Determine ancestor_id list based on perspective (may have to use _closets_ancetsors_
 *
 * @param itemData
 * @param itemTitle
 * @param itemType
 * @returns {JSX.Element}
 * @constructor
 */
export function KmapsBreadcrumbs({ kmapData, itemTitle, itemType }) {
    const tree = kmapData?.tree ? kmapData.tree : 'places';
    const myid = kmapData?.uid?.split('-')[1] * 1;
    const [perspName, setPerspName] = useState('National Administrative Units');
    const [ancestor_ids, setAncestorIds] = useState(
        kmapData?.ancestor_ids_generic
    );
    let perspCode = usePerspective((state) => state[tree]);
    let perspData = {};
    const {
        isLoading: isPerspDataLoading,
        data: perspDataRaw,
        isError: isPerspDataError,
        error: perspDataError,
    } = useQuery(['perspective', 'data', tree], () => getPerspectiveData(tree));

    useEffect(() => {
        const newAncestors = kmapData[`ancestor_ids_${perspCode}`]
            ? kmapData[`ancestor_ids_${perspCode}`]
            : kmapData[`ancestor_ids_closest_${perspCode}`];
        setAncestorIds(newAncestors);
        if (Object.keys(perspData).includes(perspCode)) {
            setPerspName(perspData[perspCode]);
        }
    }, [kmapData, perspCode, perspData]);

    if (isPerspDataLoading || !kmapData) {
        return null;
    } else if (perspDataRaw) {
        perspDataRaw.forEach((p) => {
            perspData[p.code] = p.name;
        });
    }

    const anclen = ancestor_ids?.length || 1;
    // console.log(kmapData);

    return (
        <div key="bc-wrapper" className={`bc-wrapper bc-${anclen}`}>
            <Link key={`bc-domain-${tree}`} to="#" className="breadcrumb-item">
                {capitalize(tree)}
            </Link>
            <KmapsBreadcrumb tree={tree} kid={myid} nolink={true} />
            {/* Code to show full breadcrumb path
                {ancestor_ids?.map((aid, idn) => {
                    // if(idn === ancestor_ids.length - 2) { return "Boo!"; }
                    let nolink = aid === myid;
                    if (idn === 1) {
                        return (
                            <>
                                <BcEllipsis kmapData={kmapData} />
                                <KmapsBreadcrumb
                                    key={`kmap-bcrumb-wellip-${idn}`}
                                    tree={tree}
                                    kid={aid}
                                    nolink={nolink}
                                />
                            </>
                        );
                    }
                    return (
                        <KmapsBreadcrumb
                            key={`kmap-bcrumb-${idn}`}
                            tree={tree}
                            kid={aid}
                            nolink={nolink}
                        />
                    );
                })}
            */}

            <div
                key="bc-perspective"
                className="c-content__header__perspective"
            >
                (Perspective: {perspName})
            </div>
        </div>
    );
}

export function KmapsAncestors({ kmapData }) {
    const tree = kmapData?.tree;
    const anc_ids = kmapData['ancestor_ids_generic'];
    if (!anc_ids?.length) {
        return null;
    }
    return (
        <div className="kmap-ancestors">
            {anc_ids.map((aid, idx) => {
                const nolink = idx === anc_ids?.length - 1 ? true : false;
                return (
                    <span className="ancestor" key={`anc-${idx}`}>
                        <KmapsBreadcrumb
                            tree={tree}
                            kid={aid}
                            nolink={nolink}
                        />
                    </span>
                );
            })}
        </div>
    );
}

// A kmap breadcrumb
function KmapsBreadcrumb({ tree, kid, root, nolink }) {
    const view = useView((state) => state[tree]);
    const uid = queryID(tree, kid);
    const {
        isLoading: isKmapLoading,
        data: kmapData,
        isError: isKmapError,
        error: kmapError,
    } = useKmap(uid, 'info', false, false);
    if (isKmapLoading) {
        return <MandalaSkeleton />;
    }
    const kmurl = `/${tree}/${kid}`;
    const label = getHeaderForView(kmapData, view);

    if (nolink) {
        return (
            <a className="breadcrumb-item self">
                <HtmlCustom markup={label} options={{ decodeEntities: true }} />
            </a>
        );
    }

    return (
        <Link to={kmurl} className="breadcrumb-item">
            <HtmlCustom markup={label} options={{ decodeEntities: true }} />
        </Link>
    );
}

function BcEllipsis({ kmapData }) {
    const ancestor_path = kmapData?.ancestors_gen
        ? kmapData.ancestors_gen.join(' / ')
        : 'path too long to show';
    let parent_link = '';
    if (kmapData?.ancestor_uids_generic?.length > 1) {
        parent_link =
            '/' +
            kmapData.ancestor_uids_generic[
                kmapData.ancestor_uids_generic.length - 2
            ].replace('-', '/');
    }
    return (
        <Link
            key="bc-ellipsis"
            id="bc-ellipsis"
            className="breadcrumb-item"
            title={ancestor_path}
            to={parent_link}
        >
            <AiOutlineEllipsis />
        </Link>
    );
}
