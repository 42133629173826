import { useBiblStore } from '../../hooks/useBiblStore';
import { useCatSolr } from '../../hooks/useCatSolr';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import THLSkeleton from '../../common/THLSkeleton';
import { DoxTocLink } from './DoxTocLink';
import { useTraceUpdate } from '../../../hooks/useTraceUpdates';
import { loadDox, loadDoxPath } from '../../common/solrUtils';
import { scrollToItemInToc } from '../../common/catalogUtils';

/**
 * DoxToc : initializes the TOC by getting the first level doxcats and displaying as an toggle-able list
 *
 * @param biblp : object Unused. Replaced by useBiblStore
 * @param selId : string The id of the selected node
 * @param openFirst : bool Whether to open the first category in the TOC
 * @param noneCallback : func Callback called after .6 seconds in Timeout
 * @returns {JSX.Element}
 * @constructor
 **/

export default function DoxToc({ hideDox }) {
    const {
        coll,
        ed,
        doc: bibl,
        getTocLevel,
        setCollToc,
        setNoDoxToc,
        setSelectPath,
    } = useBiblStore((state) => state);
    // console.log('in new dox toc', bibl, collToc, lvldox);
    const [levelOne, setLevelOne] = useState(getTocLevel(1));

    useEffect(() => {
        if (
            bibl &&
            bibl?.text_num &&
            bibl?.toc_id &&
            !document.getElementById(bibl.toc_id)
        ) {
            loadDoxPath(bibl).then((data) => {
                if (data?.docs) {
                    setNoDoxToc(false);
                    setSelectPath(data.docs);
                    scrollToItemInToc(bibl?.toc_id);
                }
            });
        }
    }, [coll, ed, bibl]);

    useEffect(() => {
        if (coll && ed) {
            loadDox(coll, ed).then((data) => {
                const docs = data?.docs;
                if (docs && docs?.length > 0) {
                    setNoDoxToc(false);
                    setCollToc(docs);
                    setLevelOne(getTocLevel(1));
                } else {
                    setNoDoxToc(true);
                }
            });
        }
    }, [coll, ed]);

    return (
        <ul>
            {typeof levelOne === 'object' &&
                Object.keys(levelOne).map((dcatid, n) => {
                    // console.log(dcatid, levelOne, levelOne[dcatid]);
                    return (
                        <li key={`cattoc-dox-${n}`}>
                            <DoxTocLink doc={levelOne[dcatid]} />
                        </li>
                    );
                })}
        </ul>
    );
}
