import React, { useEffect, useRef, useState } from 'react';
// import { useBiblStore } from '../hooks/useBiblStore';
import { Button, Form } from 'react-bootstrap';
import { useSearchStore } from '../hooks/useSearchStore';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import jsonpAdapter from '../common/axios-jsonp';
import { useCookies } from 'react-cookie';
import {BsPlusCircleFill} from 'react-icons/all';
import { getLangCodeForString } from '../common/utils';
import {NavBasicSearch} from "./NavBasicSearch";

export function NavSearch() {
    const search = useSearchStore();
    const [disabled, setDisabled] = useState(false);
    // When searchstr state is changed, the search is initiated. See useEffect below.
    const [searchStr, setSearchStr] = useState(null);
    // The rows in the form
    const [formRows, setFormRows] = useState([]);
    const [doSearch, setDoSearch] = useState(false);
    // const [doRedirect, setRedirect] = useState(false); // state to trigger redirect to search page
    // const [convertWylie, setConvertWylie] = useState(false);
    const defaultLang = search?.query?.length > 0 ? search.queryLang : 'en';
    const [searchBoxLang, setSearchBoxLang] = useState(defaultLang);
    let onSearchPage = useRouteMatch('/catalog/search');
    const history = useHistory();
    const seachboxRef = useRef(); // Reference to html text input where search string is entered
    const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    // Function to convert Wylie into Unicode Tibetan for searching
    const processWylie = (wy, inputbox = false) => {
        // console.log(`Wylie is [${wy}]`, searchStr);
        if (wy === searchStr?.wy?.trim() || doSearch) {
            return;
        }
        if (searchBoxLang === 'bo') {
            // If already in Tibetan, set that as searching and don't convert
            setSearchStr({ wy: false, bo: wy });
        } else if (wy.charAt(0) === '"') {
            // Don't convert Wylie if in quotes
            setSearchStr({ wy: wy, bo: false });
        } else {
            // Otherwise, make call to convert Wylie to Tibetan
            let request = {
                url: process.env.REACT_APP_WYLIE_URL,
                adapter: jsonpAdapter,
                dataType: 'jsonp',
                callbackParamName: 'callback',
                params: { wy: wy, callback: 'wylie' },
            };

            axios
                .request(request)
                .then((res) => {
                    setSearchStr({ wy: res?.data.wy, bo: res?.data?.bo });
                })
                .catch((err) => {
                    console.log('error fetching tibetan from wylie', err);
                });
        }
    };

    const setInputVal = (e) => {
        const sval = seachboxRef?.current?.value.trim();
        processWylie(sval, true);
    };

    const submitSearch = (e) => {
        e.preventDefault();
        setInputVal(e);
        setDoSearch(true);
    };

    const checkLang = (e) => {
        const sval = seachboxRef?.current?.value;
        setSearchBoxLang(getLangCodeForString(sval));
    };

    const clearCheck = (e) => {
        if (search.isActive) {
            const sval = seachboxRef?.current?.value;
            setSearchBoxLang('en');
            // console.log("clear check", sval, search.isActive, e);
            if (sval == '') {
                seachboxRef.current.value = '';
                search.reset();
            }
        }
    };
    const newRow = () => {
        return {
            op: 'and',
            query: '',
            field: 'all',
            docs: 'all',
            scope: 'ed',
        };
    };

    const rowChanged = (i, f, v) => {
        console.log(`the field ${f} was changed to "${v}" for row ${i}`);
    };

    const addRow = () => {
        const newFormRows = formRows.concat([newRow()]);
        setFormRows(newFormRows);
    };

    // OnLoad useEffect
    useEffect(() => {
        if (search?.query) {
            seachboxRef.current.value = search.query;
        }
        // Add first empty row to row list
        addRow();
    }, []);

    /*

    useEffect(() => {
        if (cookies?.thlcat_convwyl) {
            setConvertWylie(cookies.thlcat_convwyl === 'true');
        }
    }, []);

     */

    // When the search string state is changed, initiate the search, redirect to search page if not on it
    useEffect(() => {
        if (doSearch) {
            if (searchStr?.bo) {
                search.setQuery(searchStr.bo);
                setCookie('thlcat_searchquery', searchStr.bo);
            } else if (searchStr?.wy) {
                search.setQuery(searchStr.wy);
                setCookie('thlcat_searchquery', searchStr.wy);
            } else {
                console.log('No search string', searchStr);
            }

            setCookie('thlcat_searchactive', true);
            history.push('/catalog/search');
        }
        return () => {
            setDoSearch(false);
        };
    }, [searchStr]);

    const onSearchClass = onSearchPage && search?.showing ? ' search-page' : '';
    return (
        <>
            <h4>Basic Search</h4>
            <NavBasicSearch />
            <hr />
            <h4>Advanced Search</h4>
            <Form className="nav-search-form d-flex p-2" onSubmit={submitSearch}>
                {formRows.map((rw, ri) => {
                    return (
                        <SearchFormRow
                            key={['sfr', ri]}
                            ind={ri}
                            datain={rw}
                            change={rowChanged}
                            addrow={addRow}
                        />
                    );
                })}

                <div className="mb-2">
                    <Button
                        variant="outline-primary"
                        onClick={submitSearch}
                        className="submit-btn d-inline"
                        disabled={disabled}
                    >
                        Search
                    </Button>
                </div>

                {search.isActive && !onSearchPage && (
                    <div className={`back`}>
                        <Link to="/catalog/search" title="Back to results page">
                            Back to search results
                        </Link>
                    </div>
                )}
            </Form>
        </>
    );
}

/**
 * SearchFormRow: A component that represents a row in the search form
 * A row consists of a text field for the query string, a select for the field(s) to query, a select for the type
 * of documents to query, and a select for the scope of the query (all, collection, edition). When created, it
 * takes and integer prop that represents the row of the data array (starting with 0) which is contained in the
 * form component above. In that array, each row is represented by an object as follows:
 *    {
 *        query: (the string to search for),
 *        field: (the option value for field select),
 *        docs: (the option value for the document type select),
 *        scope: (the option value for the collection/edition select)
 *    }
 * @param rind - the row index in the forms data array
 * @param datain - the initial values for each control item
 * @param onchange - a function that takes row index, the param name, and the new value for that param
 * @returns {JSX.Element}
 * @constructor
 */
function SearchFormRow({ ind, datain, change, addrow }) {
    // console.log("in search form row component", datain);
    const { op, query, field, docs, scope } = datain;
    const operator =
        ind > 0 ? (
            <Form.Control
                as="select"
                className="d-inline-block col-md-1 p-1"
                defaultValue={op}
                onChange={(e) => {
                    change(ind, 'op', e.target.value);
                }}
            >
                <option value="and">and </option>
                <option value="or">or</option>
            </Form.Control>
        ) : null;
    return (
        <div className={`mb-2 row-${ind}`}>
            {' '}
            {/* had  className={`search-and-back${onSearchClass}`} */}
            {operator}
            <span className="d-inline-block p-2">Search for </span>
            <Form.Control
                type="search"
                placeholder="...enter search phrase here..."
                className={`mr-2 cat-search d-inline col-md-6 pr-2`}
                defaultValue={query}
                onChange={(e) => {
                    change(ind, 'query', e.target.value);
                }}
                /* aria-label="Search"
                ref={seachboxRef}
                onKeyUp={checkLang}
                onChange={clearCheck}
                disabled={disabled} */
            />
            <span className="d-inline-block pr-2"> in </span>
            {/* <Form.Label className='row-form-label pr-1'>Scope</Form.Label> */}
            <Form.Control
                as="select"
                className="d-inline-block col-md-2 pr-2"
                defaultValue={field}
            >
                <option value="all">
                    All Fields
                </option>
                <option value="agent">People’s Names</option>
                <option value="title">Titles</option>
                <option value="chaptitle">Chapter Titles</option>
                <option value="doxcat">Categories</option>
                <option value="colophon">Colophons</option>
                <option value="id">IDs</option>
            </Form.Control>
            <span className="d-inline-block pr-2"> within </span>
            <Form.Control
                as="select"
                className="d-inline-block col-md-2 pr-3"
                defaultValue={docs}
                onChange={(e) => {
                    change(ind, 'docs', e.target.value);
                }}
            >
                <option name="all">All</option>
                <option name="records">Bibl</option>
                <option name="texts">Text</option>
            </Form.Control>
            <span className="d-inline-block px-2"> documents in </span>
            <Form.Control
                as="select"
                className="d-inline-block col-md-3 pr-2"
                defaultValue={scope}
                onChange={(e) => {
                    change(ind, 'scope', e.target.value);
                }}
            >
                <option value="ed">This Edition</option>
                <option value="coll">This Collection</option>
                <option value="all">All Collections</option>
            </Form.Control>
            <div className="add-more d-inline-block">
                <a
                    onClick={addrow}
                    className="d-inline-block ml-2"
                    title="Add another criterion"
                >
                    <BsPlusCircleFill color="green" />
                </a>
            </div>
        </div>
    );
}
