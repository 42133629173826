import React, { useEffect, useRef, useState } from 'react';
import './TextReader.scss';
import { TextReaderHeader } from './TextReaderHeader';
import { TextReaderPages } from './TextReaderPages';
import { TextReaderScans } from './TextReaderScans';
import { usePager } from '../hooks/usePager';
import { useBiblStore } from '../hooks/useBiblStore';
import { TextReaderText } from './TextReaderText';
import { useCatSolr } from '../hooks/useCatSolr';
import { useParams } from 'react-router-dom';

export default function TextReader(props) {
    const bibl = useBiblStore((state) => state.doc);
    const edBibl = useBiblStore((state) => state.edBibl);
    const setPage = usePager((state) => state.setPage);
    const setSection = usePager((state) => state.setSection);
    const setRange = usePager((state) => state.setRange);
    const { coll, ed, page, txtnum, view, vol, sid } = useParams();
    // console.log("view", view);

    // const [view, setView] = useState('text'); // text = view digital text, scans = view page scans
    /*
    const query = {
        q: `bibid_s:${bibl?.id} AND type:textpg`,
        fl: 'id,pnum_i',
        stats: true,
        'stats.field': 'pnum_i',
    };
    console.log("bibl id", bibl?.id);
    const {
        isLoading: areStatsLoading,
        data: sdata,
        isError: isStatError,
        error: statError,
    } = useCatSolr(query);

     */

    useEffect(() => {
        /*if (viewMode?.length > 0) {
            setView(viewMode);
        }*/

        // Initialize
        if (['pages', 'text'].includes(view) && vol && page) {
            const mtch = /(\d+)([ab])/.exec(page);
            const pg = mtch ? mtch[1] : page;
            const side = mtch ? mtch[2] : false;
            // console.log('setting page', vol, pg, side);
            setPage(vol, pg, side);
        } else if (bibl?.vol_start_i) {
            if (edBibl?.pagination_type === 'digpage') {
                setPage(bibl?.vol_start_i, 1, bibl?.pg_start_side_s);
            } else {
                // console.log('setting page in Text Reader', bibl, vol, page);
                setPage(
                    bibl?.vol_start_i,
                    bibl?.pg_start_i,
                    bibl?.pg_start_side_s
                );
            }
        }
    }, [view, vol, page, bibl]);

    useEffect(() => {
        if (sid) {
            const newsid = sid
                .replace(/^1-/, 'a')
                .replace(/^2-/, 'b')
                .replace(/^3-/, 'c');
            setSection(newsid);
        }
    }, [sid]);
    // console.log("text reader data", sdata);

    return (
        <div id="c-text-reader" className={view}>
            <TextReaderHeader view={view} />
            {view === 'scans' && <TextReaderScans />}
            {view === 'pages' && <TextReaderPages />}
            {view === 'text' && <TextReaderText />}
        </div>
    );
}
