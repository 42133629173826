import React from 'react';

import './HistoryViewer.css';

import { Link } from 'react-router-dom';
import { capitalAsset } from '../common/utils';
import { useHistory } from '../../hooks/useHistory';

export function HistoryViewer(props) {
    let statePages = useHistory((state) => state.pages);
    const removePage = useHistory((state) => state.removePage);
    const resetPages = useHistory((state) => state.resetPages);
    if (!statePages || statePages.length === 0) {
        return null;
    }

    //console.log("state pages", statePages);

    return (
        <>
            <div className="c-HistoryViewer">
                {statePages &&
                    statePages.map((pgdata, pdi) => {
                        let [pgicon, pgtitle, pgpath] = pgdata.split('::');
                        let asset_type = '';
                        const isCollection = pgicon.includes('collections-');
                        if (isCollection) {
                            asset_type = pgicon.split('-')[1];
                            pgicon = 'collections';
                        } else {
                            asset_type = pgicon;
                        }
                        if (typeof pgtitle === 'undefined') {
                            return;
                        }
                        const linkTitle = isCollection
                            ? capitalAsset(asset_type) + ' Collection'
                            : capitalAsset(asset_type);

                        // console.log("page path", pgpath);
                        return (
                            <div
                                className="c-HistoryViewer__relatedRecentItem"
                                key={pdi + pgpath.replace(/\//g, '-')}
                            >
                                <span className="c-HistoryViewer__title">
                                    <span
                                        className={
                                            'facetItem icon u-icon__' + pgicon
                                        }
                                        title={linkTitle}
                                    >
                                        {' '}
                                    </span>
                                    <Link
                                        href="#"
                                        to={pgpath}
                                        title={linkTitle}
                                    >
                                        {pgtitle}
                                    </Link>
                                </span>
                                <span
                                    className="c-HistoryViewer__removeItem u-icon__cancel-circle icon"
                                    alt="Remove from list"
                                    aria-label="Remove from list"
                                    data-path={pgpath}
                                    onClick={(event) => {
                                        const pageId =
                                            event.target.getAttribute(
                                                'data-path'
                                            );
                                        removePage(pageId);
                                    }}
                                >
                                    {' '}
                                </span>
                            </div>
                        );
                    })}
            </div>
            <div className="float-right mr-4">
                <a onClick={resetPages}>Clear All</a>
            </div>
        </>
    );
}

export default HistoryViewer;
