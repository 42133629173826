import React from 'react';
import axios from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import jsonpAdapter from '../common/axios-jsonp';
import { getSolrVal, langcode } from '../common/catalogUtils';
import { HtmlCustom } from '../common/HtmlCustom';
import { useBiblStore } from './useBiblStore';
import { useSearchStore } from './useSearchStore';

/**
 * usePage: a hook implementing React Query's useInfiniteQuery
 *
 * @param pgid
 *    the solr page ID
 * @param byPass
 *    whether or not this query can be bypassed
 * @returns {UseInfiniteQueryResult<boolean|{next: boolean, page: JSX.Element}, unknown>}
 */
export function useInfinitePage(pgid, byPass = false) {
    const edbibl = useBiblStore((state) => state.edBibl);
    const search = useSearchStore();
    const srchqry = search?.isActive ? search.getQuery() : false;
    // console.log("edbibl", edbibl, pgid);
    return useInfiniteQuery(
        ['digpage', pgid],
        ({ pageParam = pgid, eb = edbibl }) =>
            pagequery(pageParam, eb, srchqry),
        {
            getPreviousPageParam: (lastPage, allPages) => lastPage.prev,
            getNextPageParam: (lastPage, allPages) => lastPage.next,
        }
    );
}

const pagequery = async (pgid, edbibl, srchqry) => {
    /**
     * pagequery is the SOLR query function to retrieve page text records (type:textpg)
     *
     * Returns an object with:
     *  page: the text of the page
     *  next: the id of the next page or false
     *  prev: the id of the previous page or false
     */

    // console.log("in pagequery:", pgid);
    if (!pgid || pgid === '') {
        return false;
    }
    const coll = edbibl?.coll;
    const ed = edbibl?.edsig;

    let request = {
        url: process.env.REACT_APP_SOLR_URL,
        adapter: jsonpAdapter,
        callbackParamName: 'json.wrf',
        params: {
            q: `id:*${pgid}`,
            fq: ['type:textpg', `coll:${coll}`, `edsig:${ed}`],
            wt: 'json',
        },
    };
    // console.log("request", request);
    const reply = await axios.request(request).catch(function (e) {
        console.log('error', e);
    });
    let data = reply?.data;
    let pgtext = false,
        nxtpg = '',
        prevpg = '';
    if (data && data?.response && data.response?.numFound > 0) {
        //console.log("data", data);
        const sdoc = data.response.docs[0];
        const langval = sdoc?.lang_s || sdoc?.lang; // switched to lang_s but keeping this in case there are old .lang fields
        const pglang = langcode(getSolrVal(langval));
        pgtext = sdoc[`pg_mu_${pglang}`];
        if (!pgtext || pgtext?.length === 0) {
            pgtext = sdoc[`pg_${pglang}`];
        }
        if (sdoc?.nextpage_s || sdoc?.prevpage_s) {
            if (sdoc?.vnum_i) {
                nxtpg = `v${sdoc.vnum_i}p${sdoc.nextpage_s}`;
                prevpg = `v${sdoc.vnum_i}p${sdoc.prevpage_s}`;
            } else {
                // For digital pages where there are no volumes. Pagination for each text begins at 1.
                nxtpg = `p${sdoc.nextpage_s}`;
                prevpg = `p${sdoc.prevpage_s}`;
            }
            if (sdoc?.prevpage_s?.length === 0) {
                prevpg = '';
            }
            if (sdoc?.nextpage_s?.length === 0) {
                nxtpg = '';
            }
        }
    }
    if (Array.isArray(pgtext)) {
        pgtext = pgtext[0];
    }

    // Check if Search is active and there is a query string, if so, highlight it in page
    let hlpg = pgtext;
    if (srchqry?.length > 0) {
        const qrymkup = '<span class="hl">' + srchqry + '</span>';
        const re = new RegExp(srchqry, 'g');
        hlpg = pgtext?.replace(re, qrymkup);
    }
    pgtext = <HtmlCustom markup={hlpg} key={pgid} />;
    const retdata = {
        page: pgtext,
        next: nxtpg,
        prev: prevpg,
    };
    return retdata;
};
